.top-section{
    height: 141px;
    width: 100%;
    background: url(/../../assets/img/top-section.png) no-repeat;
    @include vendor(background-size,cover);
    /*@include vendor(border-top-left-radius,4px);*/
    /*@include vendor(border-top-right-radius,4px);*/
    display: table;
    position: relative;
}
.profile-section{
    @include vendor(box-shadow, 0px 1px 0px 0px rgba(0, 0, 0, 0.1));
    background: none;
    float: left;
    width: 100%;
    border: 1px solid $brand-primary;
}

.user-account{
    position: absolute;
    max-width: 400px;
    left: 50%;
    margin-left: -200px;
    margin-top: 57px;
    text-align: center;
    @media(max-width:767px){
        max-width:260px;
        max-width: 260px;
        margin-left: -130px;
        margin-top: 104px;
    }
    .list-inline{
        @include OswaldLight();
        margin: 10px 0px;
        @media(max-width:767px){
            margin: 2px 0px;
        }
        > li{
            color: #E5E5E5;
            text-transform: capitalize;
            font-size:14px;
            padding: 5px 8px;
            @media(max-width:767px){
                padding: 0px 8px;
            }
            span{
                color:#B5B5B5;
            }
            &:nth-of-type(1):after{
                display: inline-block;
                content: '';
                width: 5px;
                height: 5px;
                background: #E5E5E5;
                margin-left: 20px;
                vertical-align: middle;
                @media(max-width:767px){
                    width: 0px;
                    height: 0px;
                    margin-left: 0px;
                }
            }
        }
    }
}
.padding-left-5{
    padding-left: 5px;
    @media(max-width:767px){
        padding-left: 15px;
    }
}
.padding-right-5{
    padding-right: 5px;
    @media(max-width:767px){
        padding-right: 15px;
    }
}
.user-pic{
    width: 175px;
    height: 175px;
    @media(max-width:767px){
        width: 77px;
        height: 77px;
    }
    background: $brand-primary;
    border: 4px solid $brand-primary;
    display: block;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    overflow: hidden;
    z-index: 1;
    @include vendor (border-radius,50%);
    img{
        min-width: 100%;
        position: absolute;
        top: 50%;
        min-height: 100%;
        left: 50%;
        border-radius: 100%;
        @include translate(-50%, -50%);
        height:100%;
    }
}
.user-name{
    font-size: 22px;
    color:#E5E5E5;
    margin-top: 10px;
    @include Bold();
    line-height: 23px;
    font{
        max-width: 90%;
        @include vendor(text-overflow,ellipsis);
        white-space: nowrap;
        display: inline-block;
    }
    i{
        color:#78B647;
        width:17px;
        height: 17px;
        vertical-align: sup;
        display: inline-block;
    }
}
.change-password-btn{
    margin:25px 10px;
    @media(max-width:767px){
        margin-top: 145px;
        margin-bottom: 0px;
        text-align: center;
    }
    & .btn:nth-of-type(1){
        margin-right:10px;
    }
}
.user-account-bal{
    vertical-align: middle;
    padding: 20px 25px;
    @media(max-width: 768px){
        padding: 20px 10px;
    }
    text-align: right;
    span{
        @include OswaldLight();
        color:#E5E5E5;
        font-size: 14px;
        display: block;
    }
    h1{
        font-size: 30px;
        color: #32CD32;
        margin: 4px 0px 9px;
        padding: 0px;
        @include Bold();
    }
}
.user-data{
    margin-top: 110px;
    @media(max-width:767px){
        margin-top:20px;
    }
    float: left;
    display: block;
    width: 100%;
    .help-box {
        text-align: right;
        color: #fff;
        padding-bottom: 5px;
    }
}
.pLR{padding:0px 30px;}

.profile-title-heading{
    @include OswaldMedium();
    @include vendor(box-shadow, 0px 2px 1px 0px rgba(0, 0, 0, 0.1));
    background: $body-transparent-bg;
    border: 1px solid $brand-primary;
    height: 40px;
    line-height: 40px;
    margin-bottom: 6px;
    text-align: center;
    color: #fff;
}

.profile-bg {
    background: $body-transparent-bg;
    width: 100%;
    border: 1px solid $brand-primary;
}

.pTL-20{
    padding: 20px 0px
}
.user-data{
    [type="text"],
    [type="email"],textarea{
        font-size: 16px;
        resize: none; 
    }
    form{
        @include vendor(box-shadow, 0px 1px 0px 0px rgba(0, 0, 0, 0.1));
    }
}
.user-detail-box{
    border-bottom: 1px solid #E5E5E5;
    padding: 15px 10px;
    label{
        color: #B5B5B5;
        font-size: 14px;
        display: block;
    }
    span{
        @include OswaldRegular();
        font-size: 16px;
        color: #E5E5E5;
    }
    &:last-child{
        border-bottom: 0px;
        min-height: 98px;
        margin-bottom: 15px;
    }
}
.bottom-add-banner{
    margin:22px 0px;
    display: inline-block;
    img{
        max-width: 100%;
    }
}
