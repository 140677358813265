//
// Navbars
// --------------------------------------------------

// Wrapper and base class
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
    position: relative;
    min-height: $navbar-height; // Ensure a navbar always shows (e.g., without a .navbar-brand in collapsed mode)
    margin-bottom: $navbar-margin-bottom;
    border: 1px solid transparent;

    // Prevent floats from breaking the navbar
    @include clearfix;

    @media (min-width: $grid-float-breakpoint) {
        border-radius: $navbar-border-radius;
    }
}

// Navbar heading
//
// Groups `.navbar-brand` and `.navbar-toggle` into a single component for easy
// styling of responsive aspects.

.navbar-header {
    @include clearfix;

    @media (min-width: $grid-float-breakpoint) {
        float: left;
    }
}

// Navbar collapse (body)
//
// Group your navbar content into this for easy collapsing and expanding across
// various device sizes. By default, this content is collapsed when <768px, but
// will expand past that for a horizontal display.
//
// To start (on mobile devices) the navbar links, forms, and buttons are stacked
// vertically and include a `max-height` to overflow in case you have too much
// content for the user's viewport.

.navbar-collapse {
    overflow-x: visible;
    // padding-right: $navbar-padding-horizontal;
    // padding-left: $navbar-padding-horizontal;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    @include clearfix;
    -webkit-overflow-scrolling: touch;

    &.in {
        overflow-y: auto;
    }

    @media (min-width: $grid-float-breakpoint) {
        width: auto;
        border-top: 0;
        box-shadow: none;

        &.collapse {
            display: block !important;
            height: auto !important;
            padding-bottom: 0; // Override default setting
            overflow: visible !important;
        }

        &.in {
            overflow-y: visible;
        }

        // Undo the collapse side padding for navbars with containers to ensure
        // alignment of right-aligned contents.
        .navbar-fixed-top &,
        .navbar-static-top &,
        .navbar-fixed-bottom & {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
    .navbar-collapse {
        max-height: $navbar-collapse-max-height;

        @media (max-device-width: $screen-xs-min) and (orientation: landscape) {
            max-height: 200px;
        }
    }
}

// Both navbar header and collapse
//
// When a container is present, change the behavior of the header and collapse.

.container,
.container-fluid {
    > .navbar-header,
    > .navbar-collapse {
        // margin-right: -$navbar-padding-horizontal;
        margin-left: -$navbar-padding-horizontal;
        @media screen and (max-width: 767px) {
            margin-right: 0;
            margin-left: 0;
        }
        @media (min-width: $grid-float-breakpoint) {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

//
// Navbar alignment options
//
// Display the navbar across the entirety of the page or fixed it to the top or
// bottom of the page.

// Static top (unfixed, but 100% wide) navbar
.navbar-static-top {
    z-index: $zindex-navbar;
    border-width: 0 0 1px;

    @media (min-width: $grid-float-breakpoint) {
        border-radius: 0;
    }
}

// Fix the top/bottom navbars when screen real estate supports it
.navbar-fixed-top,
.navbar-fixed-bottom {
    position: fixed;
    right: 0;
    left: 0;
    z-index: $zindex-navbar-fixed;

    // Undo the rounded corners
    @media (min-width: $grid-float-breakpoint) {
        border-radius: 0;
    }
}
.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
}
.navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0; // override .navbar defaults
    border-width: 1px 0 0;
}

// Brand/project name
.navbar-brand {
    float: left;
    padding: $navbar-padding-vertical - 18 $navbar-padding-horizontal - 4;
    line-height: $line-height-computed;
    height: $navbar-height;
    font-size: 12px;
    color: #000;
    @media screen and (min-width: 1200px) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @media screen and (min-width: 1400px) {
        padding-left: 10px;
        padding-right: 10px;
    }

    &:hover,
        &:focus {
        text-decoration: none;
    }

    > img {
        display: inline-block;
        width: 80%;

        @media screen and (max-width: 768px) {
            width: 60%;
        }
    }
    @media (max-width: $screen-md-max) {
        margin-left: 15px;
    }
    @media (min-width: $grid-float-breakpoint) {
        .navbar > .container &,
        .navbar > .container-fluid & {
            // margin-left: -$navbar-padding-horizontal;
            // text-align: center;
        }
    }
}
.after-login-brand img {
    display: inline-block;
    width: 80px;

    @media screen and (min-width: 1200px) {
        margin-left: 35px;
        margin-top: 5px;
    }
    // @media screen and (max-width: 767px) {
    //     width: 40% !important;
    //     margin-top: 5px;
    // }
    // @media screen and (min-width: 769px) and (max-width: 992px) {
    //     height: 98% !important;
    // }
    // @media screen and (min-width: 1200px) {
    //     width: 60%;
    //     padding-left: 20px;
    // }
    // @media screen and (max-width: 576px) {
    //   width: 40%;
    //   margin-top: 5px;
    // }
    // @media screen and (min-width: 768px) {
    //     margin-top: 5px;
    // }
}

// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

.navbar-toggle {
    position: relative;
    float: left;
    margin-left: $navbar-padding-horizontal;
    padding: 0;
    margin-top: calc((60px - 25px) / 2);
    margin-bottom: calc((60px - 25px) / 2);
    background-color: transparent;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: none;
    font-size: 17px;
    width: 25px;
    height: 25px;
    //border-radius: $border-radius-base;

    // We remove the `outline` here, but later compensate by attaching `:hover`
    // styles to `:focus`.
    &:focus {
        outline: 0;
    }

    // Bars
    .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
    }
    .icon-bar + .icon-bar {
        margin-top: 4px;
    }

    @media (min-width: $grid-float-breakpoint) {
        display: none;
    }
}

// Navbar nav links
//
// Builds on top of the `.nav` components with its own modifier class to make
// the nav the full height of the horizontal nav (above 768px).

.navbar-nav {
    // margin: 0 (-$navbar-padding-horizontal);
    // margin-top: 5px !important;
    // @media screen and (max-width: 767px) {
    //     padding-left: 12px;
    //     padding-right: 12px;
    // }
    > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: $line-height-computed;
        @include OswaldRegular();
    }

    @media (max-width: $grid-float-breakpoint-max) {
        // Dropdowns get custom display when collapsed
        .open .dropdown-menu {
            position: static;
            float: none;
            width: auto;
            margin-top: 0;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            > li > a,
            .dropdown-header {
                padding: 10px;
            }
            > li > a {
                line-height: $line-height-computed;
                &:hover,
                    &:focus {
                    background-image: none;
                }
            }
        }
    }

    // Uncollapse the nav
    @media (min-width: $grid-float-breakpoint) {
        float: left;
        margin: 0;

        > li {
            float: left;
            > a {
                padding-top: $navbar-padding-vertical;
                padding-bottom: $navbar-padding-vertical;
            }
        }
    }
    > .dropdown {
        > a {
            display: block;
            position: relative;
            padding-right: 30px;
            padding-left: 12px;
            padding-top: 18px;
            padding-bottom: 9px;
            // min-width: 150px;
            &:after {
                content: "\e94b";
                color: #fff;
                position: absolute;
                right: 8px;
                font-size: 16px;
                top: 18px;
                font-family: "goldentech" !important;
            }
            .dropdown-heading {
                color: $after-login-active-color;
                font-size: 20px;
                display: block;
            }
            .dropdown-value {
                color: #3fafef;
                i {
                    color: $brand-primary;
                    font-size: 15px;
                    margin-right: 4px;
                }
            }
        }
        &.open {
            > a {
                background: #ffffff; /* Old browsers */
            }
        }
        > .filter-dropdown-menu {
            width: 100%;
            background: #fff;
            border-radius: 0 0 4px 4px;
            @include vendor(box-shadow, 0px 0px 4px rgba(0, 0, 0, 0.25));
        }
        .dropdown-menu {
            min-width: 205px;
            margin-top: 0;
            li {
                a {
                    i {
                        width: 25px;
                        font-size: 22px;
                        line-height: 20px;
                        margin-right: 5px;
                        display: inline-block;
                        vertical-align: middle;
                        &.icon-wrestling {
                            font-size: 25px;
                        }
                        &.icon-fielder-right {
                            font-size: 24px;
                        }
                    }
                    >span {
                        display: block;
                        font-size: 12px;
                        color: #F15D3F;
                        margin-top: -5px;
                        text-align: center;
                        &:before {
                            content: "( "
                        }
                        &:after {
                            content: " )"
                        }
                    }
                }
            }
        }
    }
}

// Navbar form
//
// Extension of the `.form-inline` with some extra flavor for optimum display in
// our navbars.

.navbar-form {
    margin-left: -$navbar-padding-horizontal;
    margin-right: -$navbar-padding-horizontal;
    padding: 0 $navbar-padding-horizontal;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    // $shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
    //   0 1px 0 rgba(255, 255, 255, 0.1);
    // @include box-shadow($shadow);

    // Mixin behavior for optimum display
    @include form-inline;

    .form-group {
        @media (max-width: $grid-float-breakpoint-max) {
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // Vertically center in expanded, horizontal navbar
    @include navbar-vertical-align(30px);

    // Undo 100% width for pull classes
    @media (min-width: $grid-float-breakpoint) {
        width: auto;
        border: 0;
        margin-left: 0;
        margin-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        @include box-shadow(none);
    }
    @media screen and (max-width: 1300px) {
        width: 202px;
        padding-left: 0;
    }
    @media screen and (min-width: $screen-xs-min) and (max-width: $screen-md-max) {
        position: absolute;
        top: -5px;
        right: calc($grid-gutter-width / 2) + 44;
        border: none;
    }
    @media screen and (max-width: $screen-xs-min) {
        width: 45%;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding: 10px;
    }
    &.navbar-right {
        .input-group {
            .form-control {
                @include vendor(box-shadow, 0px 0px 1px rgba(0, 0, 0, 0.5));
                @media screen and (min-width: 1281px) {
                    max-width: 125px;
                    width: 125px;
                }
            }
        }
    }
}

.login-signup-link {
    @include OswaldSemiBold;
    padding: 50px 20px 0 0;
    font-size: 24px;
    text-align: right;

    @media screen and (max-width: 768px) {
        padding: 20px 30px 0 0;
        font-size: 14px;
    }
    @media screen and (max-width: 576px) {
        padding: 20px 20px 0 0;
        font-size: 12px;
    }
    @media screen and (max-width: 394px) {
        padding: 20px 8px 0 0;
        font-size: 10px;
    }    
    @media screen and (min-width: 768px) {
        padding-top: 22px;
    }

    .header-link {
        color: $body-bg;
    }
    .header-link-login {
        margin-right: 15px;
    }
    .header-link-signup {
        margin-left: 15px;
    }
    span {
        border-right: 3px solid $body-bg;
    }
}

.pane-user-login {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.pane-user-login-signup {
    display: none;
    @media screen and (max-width: 767px) {
        display: inline;
    }
}

.pane-user-login-league {
    display: none;
    @media screen and (max-width: 767px) {
        display: inline-flex;
    }
}

.static-nav {
    display: flex;
    @media screen and (max-width: 1201px) {
        margin-top: 20px;
    }
}

.static-nav {
    display: flex;
    @media screen and (max-width: 1201px) {
        margin-top: 20px;
    }
}

.static-nav-a {
    height: 40px;
    margin-top: 10px;
    margin-right: -10px;
    line-height: 0 !important;
    @media screen and (max-width: 1201px) {
        margin-top: auto;
        line-height: 20px !important;
    }
}

// Dropdown menus

// Menu position and menu carets
.navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    @include border-top-radius(0);
}
// Menu position and menu caret support for dropups via extra dropup class
.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
    margin-bottom: 0;
    @include border-top-radius($navbar-border-radius);
    @include border-bottom-radius(0);
}

// Buttons in navbars
//
// Vertically center a button within a navbar (when *not* in a form).

.navbar-btn {
    @include navbar-vertical-align($input-height-base);

    &.btn-sm {
        @include navbar-vertical-align($input-height-small);
    }
    &.btn-xs {
        @include navbar-vertical-align(22px);
    }
}

// .refer-a-friend-btn {
//     background: $after-login-primary-color;
//     color: $after-login-active-color;
//     height: 30px;
//     width: 120px;
//     border-radius: 25px !important;
//     margin-right: 10px;
//     &:hover,
//         &:focus {
//         color: $after-login-active-color !important;
//         background-color: $after-login-secondary-color !important;
//     }
//     @media screen and (max-width: $screen-lg) {
//         border-radius: 5px;
//     }
//     @media screen and (min-width: 1200px) and (max-width: 1349px) {
//         width: 110px;
//         margin-right: 0;
//     }
// }

.nav-achievement {
    background: #30bebc;
}
.nav-btn {
    color: $after-login-active-color;
    background: $after-login-primary-color;
    height: 30px;
    width: 100px;
    margin-top: 5px;
    border-radius: 25px !important;

    &:hover,
        &:focus,
        &:active {
        background-color: $after-login-secondary-color;
    }
}

@media screen and (max-width: 470px) {
    .nav-btn {
        height: 20px;
        width: 60px;
        font-size: 10px;
        padding-top: 3px;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1349px) {
    .nav-btn {
        width: 55px;
    }
}
.border-blue {
    border: 2px solid $after-login-primary-color !important;
    margin-top: 5px;
}

// Text in navbars
//
// Add a class to make any element properly align itself vertically within the navbars.

.navbar-text {
    @include navbar-vertical-align($line-height-computed);

    @media (min-width: $grid-float-breakpoint) {
        float: left;
        margin-left: $navbar-padding-horizontal;
        margin-right: $navbar-padding-horizontal;
    }
}

// Component alignment
//
// Repurpose the pull utilities as their own navbar utilities to avoid specificity
// issues with parents and chaining. Only do this when the navbar is uncollapsed
// though so that navbar contents properly stack and align in mobile.
//
// Declared after the navbar components to ensure more specificity on the margins.

@media (min-width: $grid-float-breakpoint) {
    .navbar-left {
        float: left !important;
    }
    .navbar-right {
        float: right !important;
        margin-right: -$navbar-padding-horizontal;

        ~ .navbar-right {
            margin-right: 14px;
        }
    }
}
@media screen and (min-width: 1200px) and (max-width: 1349px) {
    .navbar-right {
        margin-right: 30px;
    }
}
@media screen and (min-width: 1301px) and (max-width: 1349px) {
    .navbar-right {
        margin-right: 10px !important;
    }
}
// Alternate navbars
// --------------------------------------------------
.main {
    background: url('/../../assets/img/game-bg.jpg') no-repeat fixed;
    .navbar-default {
        background: none;
        margin: 0;
    }
}

// Default navbar
.navbar-default {
    background: none;
    // border-color: $navbar-default-border;
    /*height: $navbar-default-height;*/
    // @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.19));
    border: none;
    border-radius: 0;
    .navbar-brand {
        font-size: 12px;
        color: #000;
        &:hover,
            &:focus {
            color: $navbar-default-brand-hover-color;
            background-color: $navbar-default-brand-hover-bg;
        }
    }

    .navbar-text {
        color: $navbar-default-color;
    }

    .navbar-nav {
        > li > a {
            color: $after-login-primary-color;
            font-size: 16px !important;
            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                background: transparent;
                width: 100%;
                height: 42px;
                left: 0;
            }
            &:hover,
                &:focus {
                color: $navbar-default-link-hover-color;
                background-color: transparent;
            }
            &.badge-section {
                @include vendor(box-shadow, none);
                background: transparent;
                border: none;
                min-width: auto;
                &:before,
                    &:after {
                    display: none;
                }
            }
        }
        > .active > a {
            &,
            &:hover,
                &:focus {
                color: #fff;
                background-color: transparent;
                @media screen and (max-width: 1199px) {
                    background: $brand-primary;
                    color: #fff;
                }
            }
            &:before {
                @media screen and (max-width: 1199px) {
                    background: transparent;
                }
            }
        }
        > .disabled > a {
            &,
            &:hover,
                &:focus {
                color: $navbar-default-link-disabled-color;
                background-color: $navbar-default-link-disabled-bg;
            }
        }
        .dropdown-toggle {
            @media screen and (min-width: $screen-sm-min) {
            }
            &:hover,
                &:focus {
                &:before {
                    background: transparent;
                }
            }
        }
    }

    .navbar-toggle {
        border-color: $navbar-default-toggle-border-color;
        &:hover,
            &:focus {
            background-color: transparent;
        }
        .icon-bar {
            background-color: $navbar-default-toggle-icon-bar-bg;
        }
    }

    // .navbar-collapse,
    .navbar-form {
        border-color: $navbar-default-border;
    }

    // Dropdown menu items
    .navbar-nav {
        // Remove background color from open dropdown
        > .open > a {
            &,
            &:hover,
                &:focus {
                background-color: $navbar-default-link-active-bg;
                color: $navbar-default-link-active-color;
            }
        }

        @media (max-width: $grid-float-breakpoint-max) {
            // Dropdowns get custom display when collapsed
            .open .dropdown-menu {
                > li > a {
                    color: $navbar-default-link-color;
                    &:hover,
                        &:focus {
                        color: $navbar-default-link-hover-color;
                        background-color: $navbar-default-link-hover-bg;
                    }
                }
                > .active > a {
                    &,
                    &:hover,
                        &:focus {
                        color: $navbar-default-link-active-color;
                        background-color: $navbar-default-link-active-bg;
                    }
                }
                > .disabled > a {
                    &,
                    &:hover,
                        &:focus {
                        color: $navbar-default-link-disabled-color;
                        background-color: $navbar-default-link-disabled-bg;
                    }
                }
            }
        }
    }

    // Links in navbars
    //
    // Add a class to ensure links outside the navbar nav are colored correctly.

    .navbar-link {
        //color: $navbar-default-link-color;
        color: $gray-base;
        &:hover {
            color: $navbar-default-link-hover-color;
        }
    }

    .btn-link {
        //color: $navbar-default-link-color;
        color: $gray-base;
        &:hover,
            &:focus {
            color: $navbar-default-link-hover-color;
        }
        &[disabled],
        fieldset[disabled] & {
            &:hover,
                &:focus {
                color: $navbar-default-link-disabled-color;
            }
        }
    }
}
.navbar-background {
    background: none !important;
}
// Inverse navbar

.navbar-inverse {
    background-color: $navbar-inverse-bg;
    border-color: $navbar-inverse-border;

    .navbar-brand {
        color: $navbar-inverse-brand-color;
        &:hover,
            &:focus {
            color: $navbar-inverse-brand-hover-color;
            background-color: $navbar-inverse-brand-hover-bg;
        }
    }

    .navbar-text {
        color: $navbar-inverse-color;
    }

    .navbar-nav {
        > li > a {
            color: $navbar-inverse-link-color;

            &:hover,
                &:focus {
                color: $navbar-inverse-link-hover-color;
                background-color: $navbar-inverse-link-hover-bg;
            }
        }
        > .active > a {
            &,
            &:hover,
                &:focus {
                color: $navbar-inverse-link-active-color;
                background-color: $navbar-inverse-link-active-bg;
            }
        }
        > .disabled > a {
            &,
            &:hover,
                &:focus {
                color: $navbar-inverse-link-disabled-color;
                background-color: $navbar-inverse-link-disabled-bg;
            }
        }
    }

    // Darken the responsive nav toggle
    .navbar-toggle {
        border-color: $navbar-inverse-toggle-border-color;
        &:hover,
            &:focus {
            background-color: $navbar-inverse-toggle-hover-bg;
        }
        .icon-bar {
            background-color: $navbar-inverse-toggle-icon-bar-bg;
        }
    }

    .navbar-collapse,
    .navbar-form {
        border-color: darken($navbar-inverse-bg, 7%);
    }

    // Dropdowns
    .navbar-nav {
        > .open > a {
            &,
            &:hover,
                &:focus {
                background-color: $navbar-inverse-link-active-bg;
                color: $navbar-inverse-link-active-color;
            }
        }

        @media (max-width: $grid-float-breakpoint-max) {
            // Dropdowns get custom display
            .open .dropdown-menu {
                > .dropdown-header {
                    border-color: $navbar-inverse-border;
                }
                .divider {
                    background-color: $navbar-inverse-border;
                }
                > li > a {
                    color: $navbar-inverse-link-color;
                    &:hover,
                        &:focus {
                        color: $navbar-inverse-link-hover-color;
                        background-color: $navbar-inverse-link-hover-bg;
                    }
                }
                > .active > a {
                    &,
                    &:hover,
                        &:focus {
                        color: $navbar-inverse-link-active-color;
                        background-color: $navbar-inverse-link-active-bg;
                    }
                }
                > .disabled > a {
                    &,
                    &:hover,
                        &:focus {
                        color: $navbar-inverse-link-disabled-color;
                        background-color: $navbar-inverse-link-disabled-bg;
                    }
                }
            }
        }
    }

    .navbar-link {
        color: $navbar-inverse-link-color;
        &:hover {
            color: $navbar-inverse-link-hover-color;
        }
    }

    .btn-link {
        color: $navbar-inverse-link-color;
        &:hover,
            &:focus {
            color: $navbar-inverse-link-hover-color;
        }
        &[disabled],
        fieldset[disabled] & {
            &:hover,
                &:focus {
                color: $navbar-inverse-link-disabled-color;
            }
        }
    }
}

.navbar-left {
    .navbar-nav {
        &:first-child {
            // border-top:1px solid #e2e2e2;
            // border-bottom:1px solid #e2e2e2;
            margin-top: 2px;
        }
    }
}
.xs-menubtn-right {
    margin-right: calc($grid-gutter-width / 2);

    // @media screen and (min-width: 768px) and (max-width: 1199px) {
    //   padding: 5px 10px 0 0;
    // }
}
@media screen and (max-width: $screen-md-max) {
    .navbar-collapse.navbar-left {
        width: 100%;
        max-width: 250px;
        background-color: $dropdown-background-color;
        position: absolute;
        left: 0px;
        top: 60px;
        margin: 0;
        box-shadow: 0px 3px 4px rgba(0,0,0,0.4);
        z-index: 3;
        color: red;
        .navbar-nav {
            li {
                a {
                    color: #e5e5e5;
                    &:hover,
                        &:focus,
                        &:active {
                        color: #000;
                        background-color: $after-login-secondary-color;
                    }
                }
            }
        }
    }
    .navbar-collapse.navbar-right {
        width: 100%;
        max-width: 250px;
        background-color: $dropdown-background-color;
        position: absolute;
        right: 0px;
        top: 60px;
        margin: 0;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.4);
        z-index: 3;
        .navbar-nav {
            > li {
                &:first-child {
                    border-bottom: 1px solid #e6e4e4;
                    margin-bottom: 10px;
                }
            }
            .dropdown-menu {
                display: block !important;
                position: static;
                float: none;
                width: auto;
                margin-top: 0;
                background-color: transparent;
                border: 0;
                box-shadow: none;
                visibility: visible;
                opacity: 1;
            }
        }
        .navbar-btn {
            width: 95%;
            margin: 8px auto;
            padding: 10px;
            display: block;
        }
    }
}

@media screen and (max-width: $screen-sm-max) {
    .profile-nav {
        border-top: 1px solid $brand-primary;
        text-transform: uppercase;
        li > a > i {
            padding-right: 15px;
        }
        > form {
            border-bottom: 1px solid $brand-primary;
            padding-bottom: 5px;
        }
        input {
            border: 2px solid $brand-primary !important;
            margin-top: 5px;
            margin-left: 15px;
            max-width: 125px;
            border-radius: 5px !important;
        }
        button {
            color: #fff;
            background: $brand-primary;
            height: 30px;
            width: 80px;
            margin-top: 5px;
            margin-left: 10px;
            border-radius: 25px !important;
        }
    }
    .navbar-add-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        .header-btn {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: $after-login-primary-color;
            border: solid 2px $brand-primary;
            color: #fff;
            border-radius: 23px;
            outline: none;
            padding: 0px;
            overflow: hidden;
            font-size: 1.5rem;
            box-shadow: 0px 3px 2px 0 rgba(0,0,0,0.2);
            > span {
                padding: 2.5% 8px;
            }
            > i {
                background-color: $brand-primary;
                padding: 2px 8px;
                font-weight: bold;
                line-height: 1.8;
                font-style: normal;
            }
        }
    }
}
