.parent-view>div main {
  padding-bottom: 0;
  padding-top: 0;
}

.home main {
  padding-top: 0;
}

// .logo {
//   padding: 8px;
//   width: 87.6%;
//   margin: auto;
// }

.full-width-section {
  background: url("/../../assets/img/download/WebsiteBanner.jpg") center top no-repeat;
  background-size: contain;
  background-color: white;
  display: block;
  overflow: auto;
  margin-top: 20px;
}

.banner-text {
  color: #000;
  color: #e9c204;
  font-size: 17px;
  font-weight: 600;
}

.banner-image {
  text-align: right;
}

// .mobile-banner {
//   margin-top: 20px;
// }

#main-banner-image {
  width: 55%;
  position: relative;
}

#main-slider {
  margin-top: 165px;
}

.tinyInt {
  font-size: 60%;
}

.largeInt {
  font-size: 34px;
  font-weight: 600;
}

#app-submit-btn {
  color: #223f96;
  background: #e9c204;
  border-radius: 5px;
  padding: 5px;
}

#mobile-number-input {
  background: white;
  border-radius: 5px;
  width: 100%;
  float: left;
  padding: 6px;
  margin: 2px;
  color: $dropdown-semi-highlight-text;
}

.full-width-section2 {
  background: #eee;
  display: inline-grid;
}

.section2-head {
  background: #fdd402;
}

.section2-head h1 {
  font-weight: 600;
  padding: 10px;

  @media screen and (max-width: 768px) {
    color: $app-install-text-color;
  }
}

.img-responsive {
  margin: 0 auto;
}

.bold-span {
  font-size: 16px;
  font-weight: 600;
}

.navbar {
  position: relative;
  margin: 0;
}

.installGuideImg {
  padding: 50px;
}

#app-submit-btn {
  margin-top: 3px;
  height: 32px;
}

.full-width-section3 {
  float: right;
  background: #3a57a6;
  padding: 15px;
  color: #e9c204;
  width: 100%;
}

.full-width-section3 span {
  width: 80%;
  margin: auto;
  display: block;
}

@media (max-width: 1366px) {
  #main-slider {
    margin-top: 120px;
  }

  .get-app-btn-div {
    margin-top: 2px;
  }

  .banner-text {
    font-size: 14px;
    font-weight: 600;
  }

  #appDwnldBtn {
    width: 65%;
  }
}

@media (max-width: 768px) {
  .mobile-banner {
    margin-top: 30px;
  }

  .banner-image {
    width: 100%;
  }

  .mobile-banner {
    width: 100%;
  }

  #main-slider {
    background: #3a57a7;
  }

  .banner-text {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    // padding: 36px;
  }

  .banner-text-small {
    font-size: 20px;
  }

  .banner-image {
    text-align: center;
  }

  #appDwnldBtn {
    width: 40%;
  }

  #app-submit-btn {
    font-size: 12px;
    padding: 4px;
  }

  .get-app-btn-div {
    margin-top: -8px;
  }

  .installGuideImg {
    width: 100%;
    padding: 25px;
    color: $app-install-text-color;
  }

  #main-slider {
    margin-top: 0px;
  }

  @media (max-width: 500px) {
    .banner-text-small {
      font-size: 16px;
    }

    #appDwnldBtn {
      width: 60%;
    }

    // #main-slider {
    //   margin-top: 49px;
    // }
    .banner-text {
      font-size: 24px;
    }

    .get-app-btn-div {
      margin-top: 0px;
    }
  }
}

.qrcode {
  width: 25%;
}

.mobile-banner {
  @media screen and (min-width: 1280px) {
    margin-top: 20px;
  }
}
