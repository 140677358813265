.topbar{
    /*background:url(../img/top-bar-bg.jpg) no-repeat;*/
    position: sticky;

    background-size:cover;
    padding: 10px 0;
    margin-bottom:20px;
    //margin-top:-20px;
    margin-top:15px;

    .top-bar-wrap{
        //margin:0 -1px;
        overflow: hidden;
        border-radius:4px;
        position: relative;
        z-index: 2;
        .top-bar-wrap-inner{
            .top-bar-block{
                display:inline-block;
                min-width:100%;
                padding:0;
                height:140px;
                vertical-align: top;
                @media screen and (min-width:$screen-xs-min) {
                    padding:0 1px;
                }
                .inner-content{
                    position:relative;
                    font-size: 0;
                    display:block;
                    padding:10px 30px;
                    overflow: hidden;
                    height: 140px;
                    width:100%;
                    backface-visibility: hidden;
                    img{
                        min-width: 100%;
                        position: absolute;
                        top: 50%;
                        min-height: 100%;
                        left: 50%;
                        @include translate(-50%, -50%);
                        @include vendor(transform-style, preserve-3d);
                        @include vendor(transition, all 0ms ease);
                        height:100%;
                        backface-visibility: hidden;
                    }
                    .h4{
                        position:relative;
                        z-index: 1;
                        margin:0;
                        font-family:"HelveticaBlack";
                        font-size:24px;
                        color:#eac100;
                        text-shadow:0px 0px 0 #000, 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000;
                        margin-bottom:6px;
                        font-weight: 700;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        a{
                            font-family:"HelveticaBlack";
                            font-size:24px;
                            color:#eac100;
                            text-shadow:0px 0px 0 #000, 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000;
                        }
                    }
                    .h3{
                        position:relative;
                        z-index: 1;
                        margin:0;
                        font-family:"GothamBold";
                        font-size:22px;
                        color:#eac100;
                        text-shadow:0px 0px 0 #000, 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000;
                        margin-bottom:5px;
                        font-weight: 700;
                    }
                    .entry-fee-bar{
                        position:relative;
                        z-index: 1;
                        .entry-fee{
                            display:inline-block;
                            vertical-align: middle;
                            padding: 5px;
                            font-family: "GothamBold";
                            text-shadow:0px 0px 0 #000, 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000;
                            span{
                                @include Medium();
                                font-size:14px;
                                display:block;
                                text-align:right;
                                line-height: 12px;
                                &:first-child{
                                    color:#eac100;
                                    /*text-shadow:0px 0px 0 #000, 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000;*/
                                }
                                &:last-child{
                                    color:$brand-warning;
                                    color:#eac100;
                                    /*text-shadow:0px 0px 0 #000, 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000;*/
                                }
                            }
                        }
                        .entry-btn{
                            display:inline-block;
                            vertical-align: middle;
                            margin-left:5px;
                            .btn{
                                min-width:64px;
                            }
                        }
                    }
                    .remaning-progress{
                        .remaning-amount{
                            font-size:14px;
                            /*@include Medium();*/
                            font-family:"GothamBold";
                            color:#eac100;
                            text-shadow:0px 0px 0 #000, 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000;
                            position:relative;
                            z-index: 1;
                            display: block;
                            width: 100%;
                            margin-top: 5px;
                            margin-bottom: 1px;
                        }
                        .progress{
                            position:relative;
                            z-index: 1;
                            max-width:110px;
                            height: 2px;
                            border-radius:0;
                            margin: 0;
                            display: inline-block;
                            width: 100%;
                            .progress-bar{
                                box-shadow:none;
                            }
                        }
                    }
                    &.text-type-1{
                        text-align:right;
                        @media screen and (min-width:$screen-xs-min) {
                            &.inner-content{
                                padding:10px 20px;
                            }
                        }
                    }
                    &.text-type-2{
                        text-align:left;
                        &.inner-content{
                            padding: 20px 30px;
                            .h4{
                                /*font-size:32px;*/
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                        }
                        .entry-fee-bar{
                            float:right;
                        }
                        .remaning-progress{
                            float:left;
                            .remaning-amount{
                                margin-top:0;
                            }
                        }
                        @media screen and (max-width:479px) {
                            &.inner-content{
                                padding:20px 20px;
                            }
                        }
                    }
                    &.text-type-3{
                        text-align:right;
                        &.inner-content{
                            padding: 30px 30px;
                            .h4{
                                float: none;
                                width: 100%;
                                /*max-width: 190px;*/
                                width: 100%;
                                text-align: left;
                                overflow: visible;
                                clear: both;
                                text-align: center;
                                font-size: 24px;
                                margin-bottom: 0px;
                            }
                            .h3{
                                width:100%;
                                clear: both;
                                text-align: center;
                                font-size: 34px;
                                font-family:"GothamBold";
                            }
                        }
                        .entry-fee-bar{
                            float:right;
                        }
                        .remaning-progress{
                            float:left;
                            .remaning-amount{
                                margin-top:0;
                            }
                        }
                        @media screen and (max-width:479px) {
                            &.inner-content{
                                padding:30px 20px;
                            }
                        }
                    }
                }
            }
            .top-bar-block, .inner-content{
                max-width:100%;
                width:100%;
            }
        }
        .top-bar-slider-nav{
            display:inline-block;
            width:24px;
            height:24px;
            background:rgba(255,255,255, 0.5);
            color:#333;
            border-radius:100%;
            text-align:center;
            line-height:27px;
            position:absolute;
            z-index: 10;
            top:50%;
            @include vendor(transition, all 300ms ease);
            margin-top:-12px;
            @media screen and (min-width:992px){
                opacity:0;
                visibility: hidden;
            }
            &.left{}
            &.right{
                right:0;
            }
        }
        &:hover{
            .top-bar-slider-nav{
                opacity:1;
                visibility: visible;
            }
        }
    }
}
