.loader{    
    position: absolute;
    z-index: 10;
    height: 100%;
    background: transparent;
    width: 100%;
    top: 0;
    left: 0;
    text-align:center;
    @include vendor(user-select, none);
    > img{
        position: absolute;
        margin:auto;
        left:50%;
        @include vendor(transform, translate(-50%, -50%));
        top:50%;
        width:50px;
        height:50px;
    }
}
.loader-td{
    &:hover{
        background: $table-bg-hover;
    }
    img{
        width:50px;
        height:50px;
    }
}