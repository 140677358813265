.account-section {
    @media (min-width: 768px) {
        padding-top: 48px;
        padding-bottom: 48px;
    }
    h4 {
        margin-top: 0;
        margin-bottom: 5px;
        font-size: 22px;
        @include OswaldMedium();
        color: #E5E5E5;
    }
    .tab-steps {
        .tab-pane {
            padding-top: 50px;
            padding-bottom: 50px;
            p > b {
                @include OswaldRegular();
                color: #BABABA;
            }
        }
    }
    .text-center {
        > p {
            @include OswaldExtraLight();
            color: #BABABA;
        }
    }
    .info-block {
        >i {
            @include OswaldRegular();
        }
    }
}
