$match-slider-height: 90px !default;
$match-slider-bg: rgba(28, 93, 87, 0.3) !default;
$match-slider-border-radius: 5px !default;
$match-type-height: 20px !default;
$match-slider-default-color: #48bcd1 !default;
$match-countdown-color: #ffb6c1 !default;
//***********variable ends***********//
.match-slider-wrapper {
  height: $match-slider-height;
  margin-bottom: 10px;
  background-color: $match-slider-bg;
  border-radius: $match-slider-border-radius;
  @include vendor(box-shadow, 0px 1px 0px rgba(0, 0, 0, 0.1));
  .dropdown-menu {
    width: 250px;
    margin-top: 0;
    @media screen and (max-width: 767px) {
      width: 300px;
    }
    > li {
      line-height: 19px;
      > a {
        padding: 5px 10px;
        @media screen and (max-width: 360px) {
          white-space: normal;
          word-wrap: break-word;
        }
        .small {
          &.countdown {
            font-size: 10px;
            line-height: 19px;
            &.pull-left {
              font-size: 10px;
              line-height: 16px;
            }
          }
        }
      }
    }
    .countdown {
      @media screen and (max-width: 767px) {
        float: none;
      }
     @media screen and (min-width: 768px) {
       float: right;
     }
    }
  }
  .small {
    color: $match-slider-default-color;
    font-size: 12px;
    &.timer {
      padding: 2px;
    }
  }
  .new-color {
    color: $dropdown-semi-highlight-text !important;
  }
}
.all-match-btn {
  float: left;
  > a {
    @include OswaldMedium();
    display: block;
    height: $match-slider-height;
    width: 158px;
    padding: 25px 15px 25px 25px;
    color: $after-login-active-color;
    &.all-active {
      position: relative;
      > .nowrap {
        &:before {
          content: "";
          height: 2px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          background: $brand-warning;
          border-radius: 3px 0 0 0;
        }
      }
      &:after {
        content: "";
        height: 20px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $brand-warning;
        border-radius: 0 0 0 5px;
      }
    }

    &.static-link {
      line-height: 1px;
      position: relative;

      .bs {
        color: #40afef;
        position: absolute;
        bottom: 25px;
        left: 0;
        right: 0;
        display: table;
        float: none;
        width: 100%;
        text-align: center;
        font-size: 12px;
        @include Medium();
      }
    }
    @media (max-width: $screen-xs-max) {
      padding: 10px;
      width: 116px;
    }
    @media screen and (max-width: 414px) {
      padding: 5px;
      font-size: 12px;
      width: 95px;
    }
    .icon-select-arrow {
      font-size: 12px;
    }
    .nowrap {
      display: inline-block;
      width: 78%;
      vertical-align: middle;
    }
    &:before {
      content: "";
      display: inline-block;
      height: 100%;
      position: relative;
      vertical-align: middle;
    }
    &:hover,
    &:focus {
      color: $match-slider-default-color;
      .icon-select-arrow {
        color: inherit;
      }
    }
  }
  &.open > a {
    color: #fff;
    background-color: inherit;
    border-radius: 5px 0 0 5px;
    .icon-select-arrow {
      color: #fff;
    }
  }
}

.match-slider {
  overflow: hidden;
  display: block;
}

.all-match-btn > a,
.match-slide,
.match-slider .owl-item {
  border-right: 1px solid $match-slider-default-color;
}

.match-slide {
  @include OswaldRegular();
  position: relative;
  width: 100%;
  /*padding-bottom: $match-type-height;*/
  text-align: center;
  &:last-child {
    border-right: none;
  }
  .match-type {
    @include OswaldMedium();
    left: 0;
    right: 0;
    /*background-color: #F4F4F4;*/
    font-size: 14px;
    color: $after-login-active-color;
    height: $match-type-height;
    line-height: 25px;
    /*border-bottom: 1px solid $match-slider-default-color;*/
  }
  &.selected {
    .match-type {
      background-color: $match-slider-default-color;
      color: $after-login-active-color;
    }
    .match-slide-wrapper {
      box-shadow: inset 0 2px $match-slider-default-color;
    }
  }
}

.match-slider,
.match-slide {
  height: $match-slider-height;
}

.match-slide-wrapper,
.match-slider-navigation {
  position: relative;
  height: 100%;
}

.match-slide-wrapper {
  padding: 10px 8px 0;
  .countdown {
    color: $match-countdown-color;
    float: none;
  }
}

.participant,
.participant > span,
.participant > img,
.participants-names > .vs {
  display: inline-block !important;
  vertical-align: middle;
  line-height: 1;
}

.participant {
  font-size: 14px;
  @media screen and (max-width: 414px) {
    font-size: 12px;
  }
  > span,
  > img {
    vertical-align: top;
  }
  > img {
    width: auto !important;
  }
  &-one {
    > span:not(.flag-img) {
      padding-left: 3px;
    }
  }
  &-two {
    > span:not(.flag-img) {
      padding-right: 3px;
    }
  }
  .flag-img {
    max-height: 13px;
    height: 13px;
    max-width: 22px;
    > img {
      max-height: 100%;
      width: auto;
      max-width: 100%;
    }
  }
}

.participants-names {
  @include OswaldMedium();
  color: #fff;
  .vs {
    color: $match-slider-default-color;
    margin-left: 2px;
    margin-right: 2px;
    @media screen and (max-width: 414px) {
      font-size: 10px;
    }
  }
}

.match-status {
  color: $match-slider-default-color;
  font-size: 10px;
}

.match-slider-navigation {
  float: right;
  width: 36px;
  border-left: 1px solid $match-slider-default-color;
  text-align: center;
  // @media (max-width: $screen-xs-max) {
  //     display: none;
  // }
  > a {
    height: 50%;
    line-height: 45px;
    display: block;
    color: #fff;
    font-size: 14px;
    > i {
      font-size: inherit;
    }
    &:first-child {
      border-bottom: 1px solid $match-slider-default-color;
    }
  }
}
.match-slide.item.pointer.selected {
  @include vendor(box-shadow, 0 5px 10px rgba(0, 0, 0, 0.2));
}

.custom-part-slider {
  border-radius: 0;
  padding: 0;
  border: 0;
  margin: 3px 0 0 0 !important;
  max-width: initial;
  text-align: center;
  .arrow {
    display: none;
  }
  .popover-content {
    padding: 0;
  }
  .match-part-block {
    border-top: 1px solid #f6f6f6;
    padding: 7px 5px 5px 5px;
    .participants-names {
      line-height: 12px;
      .participant {
        font-size: 12px;
        font-family: "RobotoMedium";
      }
      .vs {
        font-size: 10px;
      }
    }
    .cmnt {
      font-size: 10px;
      margin-top: 2px;
    }
  }
  .match-type {
    background: $brand-warning;
    font-size: 12px;
    line-height: 12px;
    padding: 5px 5px 4px 5px;
  }
}

.league-name-position {
  overflow: hidden;
  white-space: normal;
}