.home {
    position: relative;
    background: none;
    height: auto;
    min-height: 100%;

    .bg-layer1,
    .bg-layer2,
    .bg-layer3 {
        position: absolute;
        pointer-events: none;
        > img {
            max-width: 100%;
        }
    }
    .bg-layer1 {
        left: 0;
        z-index: 1;
        top: 60px;
    }
    .bg-layer2 {
        right: 0;
        top: 60px;
    }
    .bg-layer3 {
        right: 0;
        bottom: 186px;
    }
    .navbar {
        .navbar-header {
            .navbar-brand {
                padding-left: 0;
            }
        }
        .pull-right {
            margin-top: 15px;
        }
    }
    .dashboard-alert-container {
        z-index: $zindex-alert-before;
    }
    main {
        overflow: hidden;
        padding-top: 160px;
        .upper {
            @include clearfix();
            .left-part {
                position: relative;
                vertical-align: top;
                display: inline-block;
                width: 49%;
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
                float: left;
                pointer-events: none;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    float: none;
                    display: table;
                    min-height: inherit;
                }
                .prim-logo {
                    margin-top: -64px;
                }
                .section-bg {
                    position: absolute;
                    width: 200%;
                    top: 0;
                    left: 0;
                    mix-blend-mode: multiply;
                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                    svg {
                        height: initial;
                        margin-top: 20px;
                        #triangle {
                            fill: rgba(83, 184, 239, 0.9);

                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                        max-width: 100%;
                        @media screen and (max-width: 767px) {
                            max-width: 120%;
                        }
                    }
                }
                .img-1 {
                    position: absolute;
                    left: 30%;
                    right: 0;
                    top: 0;
                    @media screen and (max-width: 768px) {
                        left: 15%;
                        top: 23%;
                    }
                    width: 72px;
                    height: 72px;
                    z-index: 3;
                    > img {
                        max-width: 100%;
                    }
                    @media screen and (max-width: 991px) {
                        width: 50px;
                        height: 50px;
                    }
                }
                .img-2 {
                    /*					position:absolute;
                                                                  left: -120px;
                                                                  right: 0;
                                                                  top: -27px;*/
                    > img {
                        max-width: 100%;
                        margin: 0 auto;
                        display: table;
                        @media screen and (max-width: 768px) {
                            min-height: 410px;
                            max-width: 50%;
                            margin-top: 255px;
                            margin-left: 0px;
                        }
                    }
                    @media screen and (max-width: 767px) {
                        left: 0;
                    }
                }
                .txt-content {
                    @include Prequel();
                    color: #fff;
                    font-size: 56px;
                    max-width: 340px;
                    margin-top: 130px;
                    position: relative;
                    z-index: 1;
                    padding-left: 56px;
                    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
                    pointer-events: all;
                    @media screen and (max-width: 991px) {
                        margin-top: 80px;
                        padding-left: 36px;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: 56px;
                        max-width: 430px;
                        margin-top: 102px;
                        position: relative;
                        z-index: 1;
                        padding-left: 38px;
                        text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
                    }
                    @media screen and (max-width: 600px) {
                        font-size: 42px;
                        margin-top: 82px;
                    }
                }
                min-height: 1083px;
            }
            .right-part {
                float: right;
                display: inline-block;
                vertical-align: top;
                width: 45%;
                position: relative;
                z-index: 100;
                @media screen and (max-width: 767px) {
                    margin-top: 50px;
                    width: 100%;
                    max-width: 420px;
                    margin-left: auto;
                    margin-right: auto;
                    float: none;
                    display: table;
                }
                .modal-dialog {
                    margin: 0;
                    max-width: 100%;
                    .modal-content {
                        .modal-header {
                            background: transparent;
                            box-shadow: none;
                            border-bottom: 1px solid #d3d3d3;
                            padding: 30px 35px;
                            @media screen and (max-width: 1091px) {
                                padding: 10px 15px 20px;
                                .social-login {
                                    text-align: center;
                                    .connect-width {
                                        width: 100%;
                                        text-align: center;
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                        }
                        .form-container {
                            max-width: initial;
                            margin-left: auto;
                            margin-right: auto;
                            padding-left: 20px;
                            padding-right: 20px;
                            .h3 {
                                margin: 21px 0 53px;
                                @include clearfix();
                                @media screen and (max-width: 991px) {
                                    font-size: 22px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .parallex-text {
            @include clearfix();
            position: relative;
            span {
                position: absolute;
                @include Prequel();
                font-size: 220px;
                text-align: right;
                color: #e5e5e5;
                opacity: 0.3;
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
                line-height: 275px;
                top: -540px;
                right: -170px;
                z-index: -1;
            }
        }
        .how-to-play {
            display: block;
            width: 100%;
            float: left;
            position: relative;
            z-index: 1;
            padding-bottom: 0px;
            //margin-top: -405px;
            @media screen and (max-width: 1201px) {
                //margin-top: -465px;
            }
            @media screen and (max-width: 1024px) {
                //margin-top: -540px;
            }
            @media screen and (max-width: 768px) {
                //margin-top: -542px;
            }
            @media screen and (max-width: 667px) {
                //margin-top: 10px;
            }
            h1 {
                @include Prequel();
                font-size: 70px;
                text-align: center;
                line-height: 88px;
                color: #4a4a4a;
                margin-bottom: 35px;
            }
            .feature-img {
                margin-top: 20px;
                svg {
                    margin: 0 auto;
                    display: block;
                }
                @media screen and (min-width: 992px) {
                    margin-top: 0;
                }
                @media screen and (min-width: 768px) {
                    min-height: 214px;
                }
            }
            .feature-number {
                @include Bold();
                font-size: 60px;
                font-weight: bold;
                line-height: 71px;
                color: #d3d3d3;
                text-align: center;
                margin-top: 20px;
            }
            .feature-name {
                @include Bold();
                font-size: 22px;
                font-weight: bold;
                text-align: center;
                line-height: 25px;
                color: #333333;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
    .footer-section {
        z-index: 10;

        // @media screen and (max-width: 320px) {
        //   position: absolute;
        //   bottom: -210px;
        // }
        // @media screen and (min-width: 321px) and (max-width: 360px) {
        //   position: absolute;
        //   bottom: -160px;
        // }
        // @media screen and (min-width: 361px) and (max-width: 375px) {
        //   position: absolute;
        //   bottom: -140px;
        // }
        // @media screen and (min-width: 376px) and (max-width: 414px) {
        //   position: absolute;
        //   bottom: -100px;
        // }
        // @media screen and (height: 812px) {
        //   position: absolute;
        //   bottom: 0;
        // }
        // @media screen and (height: 823px) {
        //   position: absolute;
        //   bottom: -20px;
        // }
        // @media screen and (min-width: 768px) and (max-width: 1023px) {
        //   position: absolute;
        //   bottom: -175px;
        // }
        // @media screen and (min-width: 1024px) and (max-width: 1199px) {
        //   position: absolute;
        //   bottom: -460px;
        // }
    }
}
