//include all fonts here
@mixin font-face($name, $path, $weight: null, $style: null, $exts: ttf) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + $name
	);
	
	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
		/* Better Font Rendering =========== */
		  -webkit-font-smoothing: antialiased;
		  -moz-osx-font-smoothing: grayscale;
	}
}

@include font-face("goldentech", "/../../assets/fonts/goldentech", normal, normal);
@include font-face("Bebas", "/../../assets/fonts/BebasNeueRegular", normal, normal);
@include font-face("RobotoBold", "/../../assets/fonts/RobotoBold", normal, normal);
@include font-face("RobotoLight", "/../../assets/fonts/RobotoLight", normal, normal);
@include font-face("RobotoMedium", "/../../assets/fonts/RobotoMedium", normal, normal);
@include font-face("RobotoRegular", "/../../assets/fonts/RobotoRegular", normal, normal);
@include font-face("RobotoThin", "/../../assets/fonts/RobotoThin", normal, normal);
@include font-face("Prequel", "/../../assets/fonts/Prequel", normal, normal);
@include font-face("GothamBold", "/../../assets/fonts/bgothm", normal, normal);
@include font-face("HelveticaBlack", "/../../assets/fonts/Helvetica-Black-Oblique", normal, normal);
@include font-face("OswaldBold", "/../../assets/fonts/Oswald-Bold", normal, normal);
@include font-face("OswaldSemiBold", "/../../assets/fonts/Oswald-SemiBold", normal, normal);
@include font-face("OswaldLight", "/../../assets/fonts/Oswald-Light", normal, normal);
@include font-face("OswaldExtraLight", "/../../assets/fonts/Oswald-ExtraLight", normal, normal);
@include font-face("OswaldMedium", "/../../assets/fonts/Oswald-Medium", normal, normal);
@include font-face("OswaldRegular", "/../../assets/fonts/Oswald-Regular", normal, normal);

@mixin Prequel(){
	font-family: "Prequel";
	font-weight: normal;
}
@mixin Bold(){
	font-family: "OswaldBold";
	font-weight: normal;
}
@mixin Medium(){
	font-family: "OswaldMedium";
	font-weight: normal;
}
@mixin Regular(){
	font-family: "OswaldRegular";
	font-weight: normal;
}
@mixin Light(){
	font-family: "OswaldLight";
	font-weight: normal;
}
@mixin Thin(){
	font-family: "RobotoThin";
	font-weight: normal;
}
@mixin OswaldBold(){
	font-family: "OswaldBold";
	font-weight: normal;
}
@mixin OswaldSemiBold(){
	font-family: "OswaldSemiBold";
	font-weight: normal;
}
@mixin OswaldLight(){
	font-family: "OswaldLight";
	font-weight: normal;
}
@mixin OswaldExtraLight(){
	font-family: "OswaldExtraLight";
	font-weight: normal;
}
@mixin OswaldMedium(){
	font-family: "OswaldMedium";
	font-weight: normal;
}
@mixin OswaldRegular(){
	font-family: "OswaldRegular";
	font-weight: normal;
}
