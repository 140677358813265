//
// Dropdown menus
// --------------------------------------------------
// Dropdown arrow/caret
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: $caret-width-base dashed;
  border-top: $caret-width-base solid \9; // IE8
  border-right: $caret-width-base solid transparent;
  border-left: $caret-width-base solid transparent;
}

// The dropdown wrapper (div)
.dropup,
.dropdown {
  position: relative;
}

.translator-dropdown {
  position: relative;

  @media (min-width: 1200px) and (max-width: 1400px) {
    bottom: 40px;
    left: 18px;
  }

  @media (min-width: 1401px) and (max-width: 1440px) {
    bottom: 18px;
    left: 0px;
  }

  @media (min-width: 992px) and (max-width: 1023px) {
    bottom: 45px;
    left: 16px;
  }

  @media (min-width: 1024px) and (max-width: 1199px) {
    bottom: 20px;
    left: 16px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    // bottom: 90px;
    left: 12px;
  }
}

.dropdown-toggle {
  margin-left: 14px;
  // color: white;
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-toggle:hover {
  color: #48bdd1;
}

// The dropdown menu (ul)
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0; // override default ul
  list-style: none;
  font-size: $font-size-base;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  background-color: $dropdown-background-color;
  // border: 1px solid $dropdown-fallback-border; // IE8 fallback
  // border: 1px solid $dropdown-border;
  // border-radius: $border-radius-base;
  // @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.4));

  background-clip: padding-box;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 300ms ease, visibility 300ms ease;
  -moz-transition: opacity 300ms ease, visibility 300ms ease;
  -ms-transition: opacity 300ms ease, visibility 300ms ease;
  -o-transition: opacity 300ms ease, visibility 300ms ease;
  transition: opacity 300ms ease, visibility 300ms ease;

  // Aligns the dropdown menu to right
  //
  // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
  &.pull-right {
    right: 0;
    left: auto;
  }

  // Dividers (basically an hr) within the dropdown
  .divider {
    @include nav-divider($dropdown-divider-bg);
  }

  // Links within the dropdown menu
  >li>a {
    display: block;
    padding: 5px 20px;
    clear: both;
    font-weight: normal;
    line-height: $line-height-base;
    color: $dropdown-text-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    @include Medium();
    font-size: 14px;
  }

  &.with-header {
    padding-top: 0;
  }
}

// Hover/Focus state
.dropdown-menu>li>a {

  &:hover,
  &:focus {
    text-decoration: none;
    color: $dropdown-text-hover-color;
    background-color: $dropdown-background-hover-color;
  }
}

// Active state
.dropdown-menu>.active>a {

  &,
  &:hover,
  &:focus {
    color: $dropdown-link-active-color;
    text-decoration: none;
    outline: 0;
    background-color: $dropdown-link-active-bg;
  }
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray
.dropdown-menu>.disabled>a {

  &,
  &:hover,
  &:focus {
    color: $dropdown-link-disabled-color;
  }

  // Nuke hover/focus effects
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    @include reset-filter;
    cursor: $cursor-disabled;
  }
}

// Open state for the dropdown
.open {

  // Show the menu
  >.dropdown-menu {
    opacity: 1;
    visibility: visible;
    //display: block;
  }

  // Remove the outline when :focus is triggered
  >a {
    outline: 0;
    // color: #333333;
  }

  .selected-thumb {
    @include vendor(box-shadow, 0 0 0 2px $brand-primary);
  }

  >.badge-section {
    >span {
      background-color: $brand-primary;
      color: #fff !important;
      border: 1px solid $brand-primary !important;
    }

    &.notification {
      >span {
        border: 1px solid $brand-danger !important;
      }
    }
  }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  left: auto; // Reset the default from `.dropdown-menu`
  right: auto;
}

// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
  left: 0;
  right: auto;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: 12px 20px;
  font-size: $font-size-small;
  line-height: $line-height-base;
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
  background-color: $dropdown-header-bg;

  &:before {
    content: "";
    display: block;
    border: 8px solid;
    border-color: transparent transparent #000 transparent;
    width: 16px;
    height: 8px;
    position: absolute;
    top: -16px;
    right: 14px;

    @media screen and (max-width: 1199px) {
      display: none;
    }
  }

  &:after {
    content: "";
    display: block;
    border: 8px solid;
    border-color: transparent transparent #eee transparent;
    width: 16px;
    height: 8px;
    position: absolute;
    top: -16px;
    right: 14px;

    @media screen and (max-width: 1199px) {
      display: none;
    }
  }

  .addfund-btn {
    display: inline-block;
    margin-top: calc((29px - 24px) / 2);
  }

  .user-bal {
    line-height: 1;

    h5 {
      font-size: 16px;
      color: #333333;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: ($zindex-dropdown - 10);
}

// Right aligned dropdowns
.pull-right>.dropdown-menu {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set, bro.
// TODO: abstract this so that the navbar fixed styles are not placed here?
.dropup,
.navbar-fixed-bottom .dropdown {

  // Reverse the caret
  .caret {
    border-top: 0;
    border-bottom: $caret-width-base dashed;
    border-bottom: $caret-width-base solid \9; // IE8
    content: "";
  }

  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
}

// Component alignment
//
// Reiterate per navbar.less and the modified component alignment there.
@media (min-width: $grid-float-breakpoint) {
  .navbar-right {
    .dropdown-menu {
      right: 0;
      left: auto;
    }

    // Necessary for overrides of the default right aligned menu.
    // Will remove come v4 in all likelihood.
    .dropdown-menu-left {
      left: 0;
      right: auto;
    }
  }
}

.price-pool-dropdown {
  padding: 0;
  line-height: 20px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: table;
      width: 100%;

      span {
        display: table-cell;
        width: 50%;
        vertical-align: middle;
        padding: 5px 10px;
        color: #333333;
      }
    }
  }

  .ul-heading {
    // background: #f4f4f4;
    display: table;
    width: 100%;
    border-radius: 5px 5px 0 0;

    span {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      padding: 5px 10px;
      color: #fff;
    }
  }
}
