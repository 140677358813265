@mixin vendor ($property, $value...) {
    -webkit-#{$property}:$value;
    -moz-#{$property}:$value;
    -ms-#{$property}:$value;
    -o-#{$property}:$value;
    #{$property}:$value;
}


// generic transform
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
// skewX
@mixin skewX ($deg) {
  @include transform(skewX(#{$deg}deg));
}
// skewY
@mixin skewY ($deg) {
  @include transform(skewY(#{$deg}deg));}
 
// scale
@mixin scale($scale) {
	 @include transform(scale($scale));
} 

// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
@mixin translateY ($y) {
   @include transform(translateY($y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}

// skew
@mixin skew-alt ($x, $y, $z, $z2) {
   @include transform(rotate(#{$x}deg) skew(#{$y}deg) scale(#{$z}, #{$z2}));
}

// skew
@mixin skew-alt-alt ($x, $y) {
   @include transform(rotate(#{$x}deg) skew(#{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
	     -o-transform-origin: $origin;
	    -ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
          transform-origin: $origin;
          
}