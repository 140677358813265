/*
Version: 3.5.2 Timestamp: Sat Nov  1 14:43:36 EDT 2014
*/

.select2-container {
  position: relative;
  padding: 0 !important;
  &.select2-dropdown-open {
    border-color: $input-border-focus;
    //@include vendor(box-shadow, 0px 2px 0px 0px #000);
  }
}
.select2-choice {
  display: block;
  height: 100%;
  padding: 0 0 0 8px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  line-height: $input-height-base - 2px;
  color: $input-color;
  text-decoration: none;
  border-radius: $input-border-radius;
  background-clip: padding-box;
  @include user-select(none);
  background-color: $input-bg;
  > .select2-chosen {
    margin-right: 30px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    float: none;
    width: auto;
    color: #e5e5e5;
    @extend .control-label;
    @media screen and (max-width: 414px) {
      margin-right: 17px;
    }
  }
}
.select2-container {
  &.input-sm {
    .select2-choice {
      padding: 6px 7px 6px 7px;
      > .select2-chosen {
        font-size: 12px;
      }
    }
  }
}
.select2-container {
  .select2-choice {
    display: block;
    width: 100%;
    height: 34px;
    padding: $padding-base-vertical $padding-base-horizontal
      $padding-base-vertical $padding-base-horizontal;
    font-size: 14px;
    line-height: 1.42857;
    color: #e5e5e5;
    background-color: transparent;
    background-image: none;
    border-radius: 0;
    @include box-shadow(none);
    .select2-arrow {
      background: transparent;
      display: inline-block;
      width: 30px;
      height: 100%;
      position: absolute;
      right: 5px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-clip: padding-box;
      text-align: center;
      > b {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        &:before {
          content: "\e94b";
          font-family: "goldentech" !important;
          position: absolute;
          left: 50%;
          top: 50%;
          @include vendor(transform, translate(-50%, -50%));
          display: block;
        }
        // @include transition(all 300ms ease-In-Out);
      }
    }
  }
  &.select2-container-active {
    a {
      disabled: none;
    }
    & + .form-control.select2-offscreen {
      & + .control-label {
        font-size: 12px;
        // color: #858585;
        top: -12px;
      }
    }
  }
}

.no-icon > .select2-container .select2-choice {
  padding: $padding-base-vertical $padding-base-horizontal
    $padding-base-vertical $padding-base-horizontal;
  .select2-chosen {
    left: 12px;
  }
}
.icon-small-font > .select2-container .select2-choice {
  .select2-chosen {
    left: 12px;
    font-size: 14px;
    color: #333333;
  }
}
.icon-small-font.has-icon > .select2-container {
  .select2-choice {
    padding: 6px 12px 6px 50px;
    .select2-chosen {
      left: 50px;
      font-size: 14px;
      color: #333333;
    }
  }
}
.icon-small-font1.icon-small-font > .select2-container .select2-choice {
  .select2-chosen {
    left: 30px;
    font-size: 14px;
    color: #333333;
  }
}
.icon-small-font2.icon-small-font > .select2-container .select2-choice {
  .select2-chosen {
    left: 0px;
    font-size: 14px;
    color: #333333;
  }
}
.select2-dropdown-open {
  .select2-choice {
    .select2-arrow {
      > b {
        color: #e5e5e5;
      }
    }
  }
}

.select2-container.select2-drop-above .select2-choice {
  border-bottom-color: #aaa;
}

html[dir="rtl"] .select2-container .select2-choice {
  padding: 0 8px 0 0;
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px;
}

html[dir="rtl"] .select2-container .select2-choice > .select2-chosen {
  margin-left: 26px;
  margin-right: 0;
}

.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 24px;
  top: 8px;
  font-size: 1px;
  text-decoration: none;
  border: 0;
  //background: url('../../img/select2.png') right top no-repeat;
  cursor: pointer;
  outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block;
}

.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer;
}

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  /* styles required for IE to work */
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-drop {
  width: 100%;
  //margin-top: 2px;
  position: absolute;
  z-index: 9999;
  top: 100%;
  border-radius: 0;
  background: #038579;
  color: #e5e5e5;
  //   @include box-shadow(0 4px 5px rgba(0, 0, 0, 0.15));
  // @extend .animated;
  // @extend .fadeInUp;
  // -webkit-animation-duration: 0.5s;
  // animation-duration: 0.5s;
}

.select2-drop.select2-drop-above {
  margin-top: 1px;
  border-top: 1px solid #aaa;
  border-bottom: 0;
  @include box-shadow(0 -4px 5px rgba(0, 0, 0, 0.15));
}

.select2-drop-active {
}

.select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid $input-border-focus;
}

.select2-drop-auto-width {
  border-top: 1px solid #aaa;
  width: auto;
}

.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}

html[dir="rtl"] .select2-container .select2-choice .select2-arrow {
  left: 0;
  right: auto;
  border-left: none;
  border-right: 1px solid #aaa;
  border-radius: 4px 0 0 4px;
}

html[dir="rtl"] .select2-container .select2-choice .select2-arrow b {
  background-position: 2px 1px;
}

.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding: 10px;
  position: relative;
  z-index: 10000;
  white-space: nowrap;
  + .select2-results {
    li {
      &:first-child {
        border-top: none;
      }
      //border-top: 1px solid #969696;
    }
  }
}

.select2-search input {
  width: 100%;
  height: auto !important;
  min-height: 26px;
  padding: 4px 20px 4px 5px;
  margin: 0;
  outline: 0;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  color: #333;
  background-color: #f2f2f2;
}

html[dir="rtl"] .select2-search input {
  padding: 4px 5px 4px 20px;
}

.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px;
}

.select2-search input.select2-active {
  background: #fff url("/../../assets/img/select2-spinner.gif") no-repeat 100%;
  background: url("/../../assets/img/select2-spinner.gif") no-repeat 100%,
    -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
  background: url("/../../assets/img/select2-spinner.gif") no-repeat 100%,
    -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url("/../../assets/img/select2-spinner.gif") no-repeat 100%,
    -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
  background: url("/../../assets/img/select2-spinner.gif") no-repeat 100%,
    linear-gradient(to bottom, #fff 85%, #eee 99%) 0 0;
}

// .select2-dropdown-open .select2-choice .select2-arrow {
//     background: transparent;
//     border-left: none;
//     filter: none;
// }
html[dir="rtl"] .select2-dropdown-open .select2-choice .select2-arrow {
  border-right: none;
}

// .select2-dropdown-open .select2-choice .select2-arrow b {
//     background-position: -18px 1px;
// }

// html[dir="rtl"] .select2-dropdown-open .select2-choice .select2-arrow b {
//     background-position: -16px 1px;
// }

.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* results */

.select2-results {
  max-height: 220px;
  padding: 0;
  margin: 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html[dir="rtl"] .select2-results {
  padding: 0 4px 0 0;
  margin: 4px 0 4px 4px;
}

.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0;
}

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}

.select2-results li + li {
  //border-top: 1px solid #969696;
}

.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold;
}

.select2-results .select2-result-label {
  padding: 5px 10px;
  margin: 0;
  cursor: pointer;
  min-height: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select2-results-dept-1 .select2-result-label {
  padding-left: 20px;
}

.select2-results-dept-2 .select2-result-label {
  padding-left: 40px;
}

.select2-results-dept-3 .select2-result-label {
  padding-left: 60px;
}

.select2-results-dept-4 .select2-result-label {
  padding-left: 80px;
}

.select2-results-dept-5 .select2-result-label {
  padding-left: 100px;
}

.select2-results-dept-6 .select2-result-label {
  padding-left: 110px;
}

.select2-results-dept-7 .select2-result-label {
  padding-left: 120px;
}

.select2-results .select2-highlighted .select2-result-label {
  background-color: #038579;
}
.select2-result-label:hover {
  background-color: #48bdd1 !important;
  color: #333;
}
.select2-results li em {
  background: #feffde;
  font-style: normal;
}

.select2-results .select2-highlighted em {
  background: transparent;
}

.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000;
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-ajax-error,
.select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item;
  padding-left: 5px;
}

/*
disabled look for disabled choices in the results dropdown
*/

.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #909090;
  display: list-item;
  cursor: default;
}

.select2-results .select2-disabled {
  background: #909090;
  color: #666;
  display: list-item;
  cursor: default;
}

.select2-results .select2-selected {
  display: none;
}

.select2-more-results.select2-active {
  background: #f4f4f4 url("/../../assets/img/select2-spinner.gif") no-repeat 100%;
}

.select2-results .select2-ajax-error {
  background: rgba(255, 50, 50, 0.2);
}

.select2-more-results {
  background: #f4f4f4;
  display: list-item;
}

/* disabled styles */

.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  //border: 1px solid #ddd;
  cursor: not-allowed;
  height: 32px;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4;
  background-image: none;
  border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}

/* multiselect */

.select2-container-multi {
  &.form-control {
    min-height: $input-height-base;
    height: auto;
  }
  .select2-choices {
    height: auto !important;
    height: 1%;
    margin: 0;
    padding: 0 5px 0 0;
    position: relative;
    cursor: text;
    overflow: hidden;
  }
}

html[dir="rtl"] .select2-container-multi .select2-choices {
  padding: 0 0 0 5px;
}

.select2-locked {
  padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
  min-height: 26px;
}

.select2-container-multi .select2-choices li {
  float: left;
  list-style: none;
}

html[dir="rtl"] .select2-container-multi .select2-choices li {
  float: right;
}

.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 8px 0;
  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent !important;
}

.select2-container-multi
  .select2-choices
  .select2-search-field
  input.select2-active {
  background: #fff url("/../../img/assets/select2-spinner.gif") no-repeat 100% !important;
}

.select2-default {
  color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
  padding: 7px 22px 5px 12px;
  margin: 8px 9px 3px;
  position: relative;
  line-height: 13px;
  color: #333333;
  cursor: default;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  /*-webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);*/
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f4f4f4;
  border: 1px #d6d6d6 transparent;
  /*//    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#f4f4f4', GradientType=0);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), color-stop(100%, #eee));
    background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
    background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
    background-image: linear-gradient(to bottom, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);*/
}

html[dir="rtl"]
  .select2-container-multi
  .select2-choices
  .select2-search-choice {
  margin: 3px 5px 3px 0;
  padding: 3px 18px 3px 5px;
}

.select2-container-multi
  .select2-choices
  .select2-search-choice
  .select2-chosen {
  cursor: default;
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
  border: 1px #d6d6d6 solid;
}

.select2-search-choice-close {
  display: block;
  width: 12px;
  height: 13px;
  position: absolute;
  right: 3px;
  top: 8px;
  font-size: 1px;
  outline: none;
  background: url(/../../assets/img/close.png) right top no-repeat;
}

html[dir="rtl"] .select2-search-choice-close {
  right: auto;
  left: 3px;
}

.select2-container-multi .select2-search-choice-close {
  right: 3px;
}

html[dir="rtl"] .select2-container-multi .select2-search-choice-close {
  left: auto;
  right: 2px;
}

.select2-container-multi
.select2-choices
.select2-search-choice
.select2-search-choice-close:hover {
  background: url(/../../assets/img/close.png) right top no-repeat;
}

.select2-container-multi
.select2-choices
.select2-search-choice-focus
.select2-search-choice-close {
  background: url(/../../assets/img/close.png) right top no-repeat;
}

/* disabled styles */

.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container-multi.select2-container-disabled
  .select2-choices
  .select2-search-choice {
  padding: 3px 5px 3px 5px;
  border: 1px solid #ddd;
  background-image: none;
  background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled
  .select2-choices
  .select2-search-choice
  .select2-search-choice-close {
  display: none;
  background: none;
}

/* end multiselect */

.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline;
}

.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}

.form-control .select2-choice {
  height: 100%;
  border: none;
}

.icon-small-group-alt {
  .select2-container {
    .select2-choice {
      padding: 6px 12px 6px 45px;
      > .select2-chosen {
        left: 45px;
        margin-right: 20px;
      }
    }
    & ~ .input-icon {
      font-size: 24px;
      width: 45px;
      height: 50px;
      img.avatar {
        display: inline-block;
        vertical-align: sub;
        margin-top: 13px;
        float: left;
      }
    }
  }
}

.select-alt-filled {
  background: rgba(0, 0, 0, 0.5);
  border-bottom: none;
  color: #fff;
  border-radius: 4px;
  height: 30px;
  .select2-choice {
    padding: 6px 6px 6px 10px;
    color: #fff;
    .select2-chosen {
      margin-right: 15px;
    }
    .select2-arrow {
      width: 13px;
      b {
        color: #fff;
        width: 15px;
        font-size: 10px;
      }
    }
  }
}
