//
// Navs
// --------------------------------------------------
// Base class
// --------------------------------------------------
.nav {
    margin-bottom: 0;
    padding-left: 0; // Override default ul/ol
    list-style: none;
    @include clearfix;

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        /*padding-left: 10px;*/
    }
    > li {
        position: relative;
        display: block;
        > a {
            position: relative;
            display: block;
            padding: $nav-link-padding;
            &:hover,
                &:focus {
                text-decoration: none;
                background-color: $nav-link-hover-bg;
            }
        }
        // Disabled state sets text to gray and nukes hover/tab effects
        &.disabled > a {
            color: $nav-disabled-link-color;
            &:hover,
                &:focus {
                color: $nav-disabled-link-hover-color;
                text-decoration: none;
                background-color: transparent;
                cursor: $cursor-disabled;
            }
        }
    }
    // Open dropdowns
    .open > a {
        &,
        &:hover,
            &:focus {
            background-color: $nav-link-hover-bg;
            border-color: $link-color;
        }
    }
    // Nav dividers (deprecated with v3.0.1)
    //
    // This should have been removed in v3 with the dropping of `.nav-list`, but
    // we missed it. We don't currently support this anywhere, but in the interest
    // of maintaining backward compatibility in case you use it, it's deprecated.
    .nav-divider {
        @include nav-divider;
    }
    // Prevent IE8 from misplacing imgs
    //
    // See https://github.com/h5bp/html5-boilerplate/issues/984#issuecomment-3985989
    > li > a > img {
        max-width: none;
    }
}

// Tabs
// -------------------------
// Give the tabs something to sit on
.nav-tabs {
    border-bottom: 1px solid $nav-tabs-border-color;
    > li {
        float: left;
        // Make the list-items overlay the bottom border
        margin-bottom: -1px;
        // Actual tabs (as links)
        > a {
            margin-right: 2px;
            line-height: $line-height-base;
            border: 1px solid transparent;
            border-radius: $border-radius-base $border-radius-base 0 0;
            &:hover {
                border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
            }
        }
        // Active state, and its :hover to override normal :hover
        &.active > a {
            &,
            &:hover,
                &:focus {
                color: $nav-tabs-active-link-hover-color;
                background-color: $nav-tabs-active-link-hover-bg;
                border: 1px solid $nav-tabs-active-link-hover-border-color;
                border-bottom-color: transparent;
                cursor: default;
            }
        }
    }
    // pulling this in mainly for less shorthand
    &.nav-justified {
        @extend .nav-justified;
        @extend .nav-tabs-justified;
    }
}

// Pills
// -------------------------
.nav-pills {
    > li {
        float: left;
        // Links rendered as pills
        > a {
            border-radius: $nav-pills-border-radius;
        }
        + li {
            margin-left: 2px;
        }
        // Active state
        &.active > a {
            &,
            &:hover,
                &:focus {
                color: $nav-pills-active-link-hover-color;
                background-color: $nav-pills-active-link-hover-bg;
            }
        }
    }
}

// Stacked pills
.nav-stacked {
    > li {
        float: none;
        + li {
            margin-top: 2px;
            margin-left: 0; // no need for this gap between nav items
        }
    }
}

// Nav variations
// --------------------------------------------------
// Justified nav links
// -------------------------
.nav-justified {
    width: 100%;
    > li {
        float: none;
        > a {
            text-align: center;
            margin-bottom: 5px;
        }
    }
    > .dropdown .dropdown-menu {
        top: auto;
        left: auto;
    }
    @media (min-width: $screen-sm-min) {
        > li {
            display: table-cell;
            width: 1%;
            > a {
                margin-bottom: 0;
            }
        }
    }
}

// Move borders to anchors instead of bottom of list
//
// Mixin for adding on top the shared `.nav-justified` styles for our tabs
.nav-tabs-justified {
    border-bottom: 0;
    > li > a {
        // Override margin from .nav-tabs
        margin-right: 0;
        border-radius: $border-radius-base;
    }
    > .active > a,
    > .active > a:hover,
        > .active > a:focus {
        border: 1px solid $nav-tabs-justified-link-border-color;
    }
    @media (min-width: $screen-sm-min) {
        > li > a {
            border-bottom: 1px solid $nav-tabs-justified-link-border-color;
            border-radius: $border-radius-base $border-radius-base 0 0;
        }
        > .active > a,
        > .active > a:hover,
            > .active > a:focus {
            border-bottom-color: $nav-tabs-justified-active-link-border-color;
        }
    }
}

// Tabbable tabs
// -------------------------
// Hide tabbable panes to start, show them when `.active`
.tab-content {
    position: relative;
    float: left;
    width: 100%;
    > .tab-pane {
        display: none;
    }
    > .active {
        display: block;
        .panel-body {
            border: 1px solid $brand-primary;
            border-top: none;
        }
    }
}

// Dropdowns
// -------------------------
// Specific dropdowns
.nav-tabs .dropdown-menu {
    // make dropdown border overlap tab border
    margin-top: -1px;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
}

.secondary-tab {
    margin-bottom: 10px;
    .nav-tabs {
        padding-left: 2px;
        padding-right: 2px;
        background-color: rgba(28, 93, 87, 0.4);
        margin-bottom: 0;
        border: 1px solid $brand-primary;
        color: #333333;
        @include vendor(box-shadow, 0px 1px 0px rgba(0, 0, 0, 0.1));
        > li {
            @include OswaldMedium();
            margin-bottom: 0;
            > a {
                border: none;
                border-radius: 0;
                background-color: transparent;
                color: #fff;
                line-height: 1;
                padding-top: 15px;
                padding-bottom: 15px;
                &:hover {
                    border: none;
                    background-color: transparent;
                }
            }
            &.active > a {
                &,
                &:hover,
                    &:focus {
                    color: $link-hover-color;
                    @include vendor(box-shadow, inset 0 -3px $link-hover-color);
                    background-color: transparent;
                    border: none;
                    cursor: default;
                }
            }
        }
    }
}

.navbar-left {
    .navbar-nav {
        .dropdown {
            .dropdown-menu {
                margin-top: 2px;
                max-width: 150px;
                min-width: 150px;
            }
        }
    }
}

.navbar-right {
    .navbar-nav {
        .dropdown {
            .dropdown-menu {
                margin-top: 0;
                // margin-right: 10px;
            }
        }
    }
}

.custom-tab {
    .nav-tabs {
        background: rgba(28, 93, 87, 0.3);
        border: 1px solid $brand-primary;
        border-bottom: 2px solid #048579;
        position: relative;
        z-index: 1;
        @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.1));
        > li {
            > a {
                color: #fff;
                @include OswaldMedium();
                background: transparent;
                border-color: transparent;
                border-radius: 0;
                @include vendor(transition, all 300ms ease);
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    background: transparent;
                    width: 100%;
                    height: 3px;
                    left: 0;
                    @include vendor(transition, all 300ms ease);
                }
                @media screen and (max-width:767px) {
                    font-size: 12px;
                }
                uib-tab-heading {
                    @include OswaldLight();
                }
            }
            &.active,
            &:hover {
                > a {
                    background: transparent;
                    color: $brand-alt;
                    border-color: transparent;
                    &:after {
                        background: $brand-alt;
                    }
                }
            }
        }
    }
}

.tab-steps {
    .nav-tabs {
        background-color: transparent;
        /*border-top: 1px solid #DBDBDB;*/
        /*border-bottom: 1px solid #DBDBDB;*/
        @include box-shadow(none);
        border-radius: 0;
        >li {
            margin: 0;
            display: table-cell;
            width: 1%;
            float: none;
            >a {
                color: #BABABA;
                border: none !important;
                text-align: center;
                margin: 0;
                &:after {
                    height: 1px;
                }
                .small {
                    display: block;
                    color: #BABABA;
                }
            }
            &.active {
                &,
                &:hover,
                    &:focus {
                    a {
                        border: none;
                    }
                }
                .radiotab {
                    background-color: #37AEF2;
                }
            }
        }
    }
    &.nounderline {
        .nav-tabs>li>a {
            &:after {
                display: none;
            }
        }
    }
}

.radiotab {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background-color: #EEEEEE;
    box-shadow: 0 0 0 1px #DBDBDB;
    display: inline-block;
    border: 4px solid #fff;
    vertical-align: middle;
    margin-right: 5px;
}
