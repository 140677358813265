//
// Tables
// --------------------------------------------------
table {
  background-color: $table-bg;
}

caption {
  padding-top: $table-cell-padding;
  padding-bottom: $table-cell-padding;
  color: $text-muted;
  text-align: left;
}

th {
  text-align: left;
}

// Baseline styles
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $line-height-computed;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-cell-padding;
        line-height: $line-height-base;
        vertical-align: top;
        border-top: 1px solid $table-border-color;
      }
      &.current-user {
        > td {
          background-color: rgba(4, 113, 103, 0.4);
        }
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
  }
  > thead.affixed {
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.4);
    z-index: 50;
    background-color: #fff;
    > tr > th {
      background-color: #fff;
      float: left;
    }
    &,
    > tr,
    > tr > th {
      display: block;
    }
    > tr {
      display: inline-block;
      vertical-align: top;
    }
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 1px solid $table-border-color;
  }
  // Nesting
  .table {
    // background-color: $body-bg;
  }
  > tbody {
    > tr {
      &.selected {
        td {
          background: $table-bg-active;
          // color: #000;
          color: #fff;
        }
        table {
          tbody {
            tr {
              td {
                background: #d3ffdf;
              }
            }
          }
        }
      }
    }
  }
}

// Condensed table w/ half padding
.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-condensed-cell-padding;
      }
    }
  }
}

// Bordered version
//
// Add borders all around the table and between all the columns.
.table-bordered {
  border: 1px solid $table-border-color;
  > thead,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid $table-border-color;
      }
    }
  }
  > thead > tr {
    background: $body-transparent-bg;
    color: $static-page-sub-heading;
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
  > tbody {
    > tr {
      td {
        border: 1px solid $table-border-color;
        color: $static-page-highlight;
      }
    }
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: $table-bg-accent;
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping
.table-hover {
  > tbody > tr:hover {
    background-color: $table-bg-hover;
  }
}

// Table cell sizing
//
// Reset default table behavior
table col[class*="col-"] {
  position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
  float: none;
  display: table-column;
}

table {
  td,
  th {
    &[class*="col-"] {
      position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-cell;
    }
  }
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.
// Generate the contextual variants
@include table-row-variant("active", $table-bg-active);
@include table-row-variant("success", $state-success-bg);
@include table-row-variant("info", $state-info-bg);
@include table-row-variant("warning", $state-warning-bg);
@include table-row-variant("danger", $state-danger-bg);
// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.
.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    margin-bottom: ($line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid $table-border-color;
    // Tighten up spacing
    > .table {
      margin-bottom: 0;
      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }
    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;
      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }
      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.table-default {
  overflow: hidden;
  background: transparent;
  @include vendor(box-shadow, 0px 1px 0px rgba(0, 0, 0, 0.1));
  .table {
    > thead {
      > tr {
        > th {
          &.rank {
            width: 90px;
            max-width: 90px;
          }
          &.check {
            width: 45px;
            max-width: 45px;
          }
          padding: 8px 7px;
          @include Medium();
          color: #fff;
          &:first-child {
            padding-left: 15px;
          }
          &:last-child {
            padding-right: 15px;
          }
          [class^="icon-shorting"] {
            font-size: 10px;
          }
          &.status-th {
            padding-right: 25px;
          }
        }
      }
    }
    > tbody {
      > tr {
        &:last-child {
          td {
            border-bottom: 1px solid $table-border-color;
          }
        }
        > td {
          &.rank {
            width: 76px;
            max-width: 76px;
          }
          &.check {
            width: 45px;
            max-width: 45px;
          }

          &:first-child {
            padding-left: 15px;
          }
          &:last-child {
            padding-right: 15px;
          }
          &.status-td {
            font-size: 12px;
            /*border-left: 1px solid #ddd;*/
            padding-right: 25px;
            .circular-btn {
              width: 20px;
              height: 20px;
              margin-left: 5px;
              .icon-active {
                font-size: 24px;
              }
            }
          }
          &.action-td {
            width: 64px;
            max-width: 64px;
            @media screen and (min-width: $screen-lg) {
              width: 90px;
              max-width: 90px;
            }
          }
          &.add-delete-td {
            width: 60px;
            max-width: 60px;
            .btn {
              font-size: 21px;
              width: 24px;
              max-width: 24px;
              height: 24px;
              line-height: 18px;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}

:not(.lobby-table .lineup-table)[fixed-header] {
  thead {
    @media screen and (max-width: $screen-xs-max) {
      display: none !important;
    }
  }
}

.lobby-table-height,
.lineup-table-height {
  height: 582px;
  margin-bottom: 0;
}

.rowspan {
  vertical-align: middle !important;
  border-right: 1px solid $table-border-color;
}

.table-nested {
  &,
  .table {
    margin-bottom: 0;
    table-layout: fixed;
  }
  > thead {
    > tr {
      > th {
        &.view-icon {
          width: 39px;
        }
        &:first-child {
          width: 196px;
        }
      }
    }
  }
  > tbody {
    > tr {
      > td {
        padding: 0 !important;
      }
    }
  }
  .leagus-td {
    &,
    .table,
    .table td {
      height: 100%;
      td {
        vertical-align: middle;
      }
    }
    .multiChild {
      // border-right: 1px solid $table-border-color !important;
    }
  }
  .leagus-td {
    > .table {
      border-right: 1px solid $table-border-color !important;
    }
  }
  > tbody {
    tbody {
      > tr {
        > td {
          padding: 6px 10px !important;
          vertical-align: middle;
          border-top: none;
          // color: #000;
            color: #ffffff;
          &.view-icon {
            width: 39px;
          }
        }
        &:last-child {
          > td {
            border-bottom: none !important;
          }
        }
        + tr {
          > td {
            border-top: 1px solid $table-border-color;
          }
        }
      }
    }
  }
}

.my-league-tables {
  > thead {
    > tr {
      background: rgba(28, 93, 87, 0.4);
      border-top: 1px solid $table-border-color;
    }
  }
  > tbody {
    > tr {
      > td {
        @include Regular();
        color: #666666;
        vertical-align: middle;
      }
    }
  }
  .leagus-td {
    @include Bold();
    min-width: 195px;
    width: 195px;
    word-break: break-all;
    @media screen and (max-width: 767px) {
      width: 100%;
      min-width: 100%;
    }
    > .table {
      background-color: rgba(72, 188, 209, 0.4);
      > tbody {
        > tr {
          > td {
            min-width: 195px;
            width: 195px;
            @media screen and (max-width: 767px) {
              width: 100%;
              min-width: 100%;
            }
            &:hover {
              background-color: $table-bg-hover;
            }
            a {
              color: #fff;
              &:hover {
                color: #48bcd1;
              }
            }
          }
        }
      }
    }
    > .kapil-table {
        background-color: $kapil-brand-rgba;
    }
  }
  .leagus-td,
  .bold {
    // color: #333333;
    color: $after-login-active-color;
  }
}

.table-label {
  font-size: 12px;
  color: $lobby-data-color;
  display: block;
  margin-bottom: 3px;
  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

@media (max-width: $screen-xs-max) {
  .table-default-xs-transparent {
    background-color: transparent;
  }
  .table.table-nested {
    > thead,
    & .table > thead {
      display: none;
    }
    > tbody,
    > tbody > tr,
    > tbody > tr > td,
    & .table > tbody,
    & .table > tbody > tr,
    & .table > tbody > tr > td {
      display: block;
      text-align: left !important;
      border: none;
      min-height: 60px;
    }
    > tbody > tr {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }
    > tbody > tr + tr {
      margin-top: 10px;
    }
    > tbody > tr > td.leagus-td tbody > tr > td {
      width: 100%;
      // background-color: #f6f6f6;
      &.multiChild {
        border-right: none;
        border-bottom: 1px solid $table-border-color !important;
      }
    }
    > tbody tbody > tr > td {
      width: 50%;
      float: left;
    }
    > tbody tbody > tr > td.view-icon {
      width: 100%;
      text-align: center !important;
      border-bottom: 1px solid #ccc;
      background-color: #f6f6f6;
      min-height: auto;
      padding: 0 !important;
      > a {
        display: block;
        padding: 10px;
        line-height: 1px;
      }
    }
  }
}

.table-nested {
  tbody {
    tr {
      &:hover {
        > td {
          //background:#F4F4F4;
          table {
            thead {
            }
            tbody {
              tr {
                td {
                  //background:#F4F4F4;
                }
                &:hover {
                  &.selected {
                    td {
                      // background: #d3ffdf;
                    }
                  }
                  td {
                    // background: #f4f4f4;
                  }
                }
              }
            }
          }
        }
      }
      &.success {
        > td {
          background: #f0f8e5;
          table {
            thead {
            }
            tbody {
              tr {
                td {
                  background: #f0f8e5;
                }
              }
            }
          }
        }
      }
    }
  }
}

thead:not(.affixed) {
  & ~ .hiddenthead {
    display: none;
  }
}
