/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : May 24, 2019, 12:36:17 PM
    Author     : Nitesh Kumar
*/
.send-email-btn {
    border: 2px solid #48bcd1;
    background: transparent;
    color: #48bcd1;
    height: 32px;
    transition: all 1s;
    &:hover, &:focus {
        color: #fff;
        background: #48bcd1;
    }
}
