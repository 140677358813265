.about-us-section {
    @include OswaldExtraLight();
    padding: 50px 100px;
    background: $body-transparent-bg;
    border: 1px solid $brand-primary;
    text-align: justify;
    /* Set "section" to 0 */
    counter-reset: section;
    @include vendor(box-shadow, 0px 1px 0px 0px rgba(0, 0, 0, 0.1));  
    p {
        font-size: 18px;
        color: $static-page-para-color;
        margin-bottom: 10px;
    }
    b {
        color: $static-page-highlight;
    }
    strong {
        color: $static-page-highlight;
    }
    h1 {
        font-size: 16px;
        color: $static-page-sub-heading;
        margin-top: 0;
        margin-bottom: 20px;
    }
    h2,
    .h2 {
        color: $static-page-sub-heading;
    }
    .h3 {
        color: $static-page-sub-heading;
    }
    .h4 {
        @include OswaldMedium();
        color: $static-page-sub-heading;
        margin-top: 20px;
    }
    .h5 {
        color: $static-page-highlight;
    }
    @media screen and (max-width: 767px) {
        padding: 30px 15px;
    }
    ul,
    ol {
        padding-left: 15px;
        font-size: 16px;
        color: $static-page-para-color;
    }
    .list-heading {
        /* Set "subsection" to 0 */
        counter-reset: subsection;
    }
    .list-heading::before {
        counter-increment: section;
        content: counter(section) ". ";
    }
    .list-content {
        padding-left: 15px;
        /* Set "subsection" to 0 */
        counter-reset: subsubsection;
    }
    .list-content::before {
        color: $static-page-sub-heading;
        counter-increment: subsection;
        content: counter(section) "." counter(subsection) " ";
    }
    .list-last-content {
        padding-left: 30px;
    }
    .list-last-content::before {
        color: $static-page-sub-heading;
        counter-increment: subsubsection;
        content: counter(section) "." counter(subsection) "." counter(subsubsection) " ";
    }
    .list-sub-content {
        padding-left: 30px;
    }
    .tnc-mark {
        color: $static-page-sub-heading;
    }
}
.about-us-title {
    @include OswaldRegular();
    font-size: 30px;
    font-style: unset;
    text-align: center;
    text-shadow: none;
    text-transform: uppercase;
    color: $static-page-heading;
}

.scoring-rules-section {
    background: #fff;
    padding: 20px 0;
    .title {
        background: #f4f4f4;
        padding: 8px 10px;
        border-radius: 4px;
        font-size: 14px;
        margin: 0 10px;
    }
    .table-default {
        box-shadow: none;
        margin-bottom: 20px;
        &.mB0 {
            margin-bottom: 0;
        }
        .table {
            > thead {
                > tr {
                    > th {
                    }
                }
            }
            > tbody {
                > tr {
                    > td {
                        vertical-align: middle;
                        color: #666;
                        &:first-child {
                            color: #333333;
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
    .list-unstyled {
        padding: 0px 15px;
        li {
            color: #333333;
            @include Regular();
            padding-left: 10px;
            position: relative;
            &:before {
                content: "*";
                display: block;
                position: absolute;
                left: 0;
            }
        }
    }
}
.panel-404 {
    background: transparent;
    box-shadow: none;
    img {
        width: 100%;
        max-width: 294px;
        margin: 50px 0 30px;
    }
    .btn {
        margin: 20px 0 0 0;
    }
    @media screen and (min-width: $screen-sm) {
        img {
            margin: 100px 0 60px;
        }
        .btn {
            margin: 30px 0 0 0;
        }
    }
}
