*{
	padding: 0px;
    margin: 0px;
 }

.pre-lauch,html, .wrapper{height: 100%;}
.pre-lauch{
	    //background: url(../img/launch-bg.png) no-repeat fixed;

    background-size: cover;
    background-attachment: fixed
}
.table-view{
	display: table;
	table-layout: fixed;
	height: 100%;
	width: 100%;
	padding:0px;
	margin:0px;
}
.height-60{ height: 40px;}
// .logo{
// 	    margin: 30px 0px 0px 30px;
// 	  @media(max-width: 768px){
// 	  	 margin:30px 0px 0px 0px;
// 	  }

// 	}
.table-row{ display: table-row;}
.table-cell{
	display: table-cell;
}
.v-mid{
	@include verticalalign(middle);
}
.v-top{
	@include verticalalign(top);
}
.v-bottom{
	@include verticalalign(bottom);
}
.luanch-title{
    color: white;
    font-family: Roboto;
    font-size: 40px;
    @media(max-width: 374px){
			font-size: 31px;
		}
    color: #333333;
    @include vendor(text-shadow, 1px 1px 2px rgba(150, 150, 150, 1));
}
.launch-logo{
	    position: relative;
	    @media(max-width:767px){
	    	margin: 21px 0px;
		}
    }
.logo-box{
	float:left;
	@media(max-width:767px){
		float:none;
		text-align: center;
	}
}
.launch-section-top{
	img{
		max-width: 100%;
	}
	p{
	padding: 23px 10px;
	@media(max-width:767px){
		padding: 23px 0px;
		text-align: center;
	}
	font-family: Roboto;
	font-size: 16px;
	color: #333333;
	}
}
.date-detail{
	width:100%;
	float: left;
	position: relative;
	.date{
		@include vendor(text-shadow, 1px 1px 2px rgba(150, 150, 150, 1));
		float:left;
		font-size: 90px;
		@media(max-width: 374px){
			font-size: 60px;
		}
		font-family: Roboto;
		font-weight: bold;
		padding: 0px 8px;
		@media(max-width:767px){
			padding: 0px 8px 0px 0px;
		}
		color:#E14200;
    }
	.month-year{
	    float: left;
	    font-family: Roboto;
	    vertical-align: middle;
	    padding: 28px 3px;
	    @media(max-width: 374px){
			padding: 15px 3px;
		}
		h1{
			margin: 0px;
			padding: 0px;
	   }
	   span{
	   	font-size: 16px;
	   	font-family: inherit;
	   	color: #333333;
	   }
    }
    &:after{
		width: 100%;
		content: "";
		border-bottom: 1px solid #DDDDDD;
		position: absolute;
		bottom: 0px;
		left: 0px;
    }
}
.search-section{
 /*   border: 3px solid rgba(0, 0, 0, 0.2);
	background: white;*/
	@include borderRadius(7px);
	padding:1px;
	display: table;
	width: 100%;
	input[ type="submit"]{
	    width: 138px;
	    float: left;
	    height: 40px;
	    font-size: 16px;
	    background: linear-gradient(180deg, #3AADDF 0%, #1B75BC 100%);
        box-shadow: -1px 0 0 0 #065FA5;
        font-family: lato;
        line-height: 16px;
        &:hover{
        	color:white;
        }
        @media(max-width:767px){
    		float:none;
    		width:100%;
		}
	}
	span{
		float: left;
		font-family: Lato;
		font-size: 16px;
		color: #333333;
		padding-left: 35px;
       @media(min-width:768px) and (max-width:800px){
       	padding-left: 48px;
       }
    	@media(max-width:767px){
    		padding-left: 15px;
		}
    }

    @media(max-width:767px){
		[class*="col-"]{
			padding-left: 0px;
			padding-right: 0px;
			text-align: center;
    		margin-top: 14px;
		}
	}
}
.copy-right{
	font-size: 14px;
	line-height: 17px;
	color: #333333;
	margin-left:10px;
	@media(max-width:767px){
	 margin-left:0px;
	}
}
.icon-cricle{
    background: white;
    padding: 9px;
    text-align: center;
	@include borderRadius(50%);
	@include vendor(box-shadow, 1px 1px 2px rgba(150, 150, 150, 1));
	&:hover{
		background: #e4e6e6;
	}
	&:hover>i{
			color:white !important;
	    }

}
.footer{
	font-family: Roboto;
		height: 40px;
		line-height: 33px;
		padding: 11px 0px;
	i{
	    font-size: 16px;
	    margin: 6px;
	    color: #888888;
	    &:first-child{
	    	margin-left:0px;
	    }

	}
	@media(max-width:767px){
		[class*="col-"]{
			text-align: center;
            margin-bottom: 5px;
		}
	}
}
.cont-size{
	max-width:560px;
	@media(min-width:922px){
		float:left;
		margin-left: 37px;
	}
	@media(min-width:1100px){
		float:left;
		margin-left:210px;
	}

	@media(max-width:991px){
		float:none;
		margin: 0 auto;
	}
}
 .msg-box {
        display: none;
    }
.error-message {
	position: relative;
   .msg-box {

		margin-top: 1px;
		color: white;
		float: left;
		position: absolute;
		display: block;
		padding: 5px;
		bottom: -28px;
		font-size: 14px;
		left: 0px;
		background: red;

		&:before{
			bottom: 100%;
			left: 0%;
			border: solid transparent;
			content: " ";
			width: 0;
			height: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(213, 214, 215, 0);
			border-bottom-color: #ff0000;
			border-width: 7px;
			margin-left: 10px;
		}
	}
}
.player-img-prelaunch{
	position: absolute;
    transform: translate(0%, -24%);
    @media screen and (min-width:1600px) and (max-width:1919){
    	transform: translate(0%, -22%);
    }
    right: 55px;
    img{
     max-width: 100%;
	}
}


