//
// Thumbnails
// --------------------------------------------------


// Mixin and adjust the regular image class
.thumbnail {
  display: block;
  padding: $thumbnail-padding;
  margin-bottom: $line-height-computed;
  line-height: $line-height-base;
  background-color: $thumbnail-bg;
  border: 1px solid $thumbnail-border;
  border-radius: $thumbnail-border-radius;
  @include transition(border .2s ease-in-out);

  > img,
  a > img {
    @include img-responsive;
    margin-left: auto;
    margin-right: auto;
  }

  // [converter] extracted a&:hover, a&:focus, a&.active to a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active

  // Image captions
  .caption {
    padding: $thumbnail-caption-padding;
    color: $thumbnail-caption-color;
  }
}

// Add a hover state for linked versions only
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: $link-color;
}

@mixin user-thumb($value) {
    width: $value;
    height: $value;
    overflow: hidden;
    position: relative;
    -webkit-border-radius: 1000px;
    -khtml-border-radius: 1000px;
    -moz-border-radius: 1000px;
    border-radius: 1000px;
    z-index: 1;
    >img {
        min-width: 100%;
        position: absolute;
        top: 50%;
        min-height: 100%;
        left: 50%;
        border-radius: 100%;
        @include translate(-50%, -50%);
        height:100%;
    }
    background:#F6F6F6;
}

@mixin user-section-wrapper-($value) {
    .user-name-section {
        padding-left: ($value) + 10;
        margin-left: -($value) - 5;
        width: 100%;
    }
}

.user-section-60 {
    @include user-thumb(60px);
}

.user-section-40 {
    @include user-thumb(40px);
}

.user-section-44 {
    @include user-thumb(44px);
}

.user-section-50 {
    @include user-thumb(50px);
}

.user-section-30 {
    @include user-thumb(30px);
}
.user-section-34 {
    @include user-thumb(34px);
}
.user-section-132 {
    @include user-thumb(132px);
}