//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Kill the scroll on the body
.modal-open {
    overflow: hidden;
}

// Container that the modal scrolls within
.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal;
    -webkit-overflow-scrolling: touch;

    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;

    // When fading in the modal, animate it to slide down
    &.fade .modal-dialog {
        @include translate(0, -25%);
        @include transition-transform(0.3s ease-out);
    }
    &.in .modal-dialog {
        @include translate(0, 0);
    }
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
    /*border: 2px solid #67dde9; //old browsers fallback (ie8 etc)*/
}

// Actual modal
.modal-content {
    position: relative;
    border: none;
    border-radius: $border-radius-large;
    @include box-shadow(0 3px 9px rgba(0, 0, 0, 0.5));
    background-clip: padding-box;
    // Remove focus outline from opened modal
    outline: 0;
}

// Modal background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal-background;
    background-color: $modal-backdrop-bg;
    // Fade for backdrop
    &.fade {
        @include opacity(0);
    }
    &.in {
        @include opacity($modal-backdrop-opacity);
    }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    padding: $modal-title-padding;
    border-bottom: 3px solid #048579;
    @include clearfix;
    background: #48bcd1;

    @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.1));
}
// Close icon
.modal-header .close {
    margin-top: -2px;
    color: #0c171b;
    text-shadow: none;
    opacity: 1;
    outline: none;
    span {
        display: block;
        width: 24px;
        height: 24px;
        line-height: 22px;
        text-align: center;
        @include vendor(transition, all 300ms ease);
        i {
            color: #132124;
            font-size: 14px;
        }
    }
    &:hover {
        span {
            background: rgba(0, 0, 0, 0.1);
        }
    }
}

// Title text within header
.modal-title {
    margin: 0;
    line-height: $modal-title-line-height;
    font-size: $font-size-base + 2;
    color: #132124;
    text-align: center;
    @include OswaldMedium();
    padding-right: 20px;
    text-transform: uppercase;
    @media screen and (min-width: $screen-sm) {
        padding-right: 40px;
    }
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    position: relative;
    background: rgba(28, 97, 93, 0.95);
    padding: $modal-inner-padding;
    border-bottom: 3px solid #48bcd1;
    @include OswaldLight();
    font-size: 16px;
    @media screen and (max-width: 768px) {
        border-bottom: none;
    }
}

// Footer (for actions)
.modal-footer {
    padding: $modal-inner-padding;
    background: rgba(28, 97, 93, 0.95);

    text-align: right; // right align buttons
    @include clearfix; // clear it in case folks use .pull-* classes on buttons

    // Properly space out buttons
    .btn + .btn {
        margin-left: 5px;
        @media (max-width: 767px) {
            margin-left: 0px;
        }
        margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
    }
    // but override that for button groups
    .btn-group .btn + .btn {
        margin-left: -1px;
    }
    // and override it for block buttons as well
    .btn-block + .btn-block {
        margin-left: 0;
    }
}
.footer-div-text {
    color: #e5e5e5;
    display: inline-block;
    margin-right: 2%;
}
.social-button-facebook {
    width: 20%;
}
.social-button-google {
    width: 20%;
    background-color: #f14336;
}
.social-button-shape-facebook {
    background-color: #3a559f;
}
.social-button-shape-google {
    background-color: #f14336;
}
.margin-new {
    margin-top: 10px;
}
// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

// Scale up the modal
@media (min-width: $screen-sm-min) {
    // Automatically set modal's width for larger viewports
    .modal-dialog {
        width: $modal-md;
        margin: 30px auto;
    }
    .modal-content {
        @include box-shadow(0 5px 15px rgba(0, 0, 0, 0.5));
    }

    // Modal sizes
    .modal-sm {
        width: $modal-sm;
    }
    .league-detail-popup,
    .player-detail-popup {
        &.modal-dialog,
        .modal-dialog {
            width: 766px;
        }
    }
}

@media (min-width: $screen-md-min) {
    .modal-lg {
        width: $modal-lg;
    }
}
//right side modal popup
.modal.right-side-modal-window {
    left: initial;
    .modal-dialog {
        max-width: 646px;
        width: 100% !important;
        float: right;
        margin: 0;
        height: 100%;
        @include vendor(transform, translate(25%, 0));
        .modal-content {
            background-color: #282828;
            height: inherit;
            border-radius: 0;
            color: #fff;
            .modal-header {
                color: #fff;
                border-radius: 0;
                border-bottom: 0;
                background-color: #333333;
            }
        }
    }
}
.modal.in.right-side-modal-window .modal-dialog {
    @include vendor(transform, translate(0, 0));
}
