.team-tab{
    //background:url(../img/ground-checks-bg.jpg) repeat #00A900;
    border-radius:4px;
    //overflow:hidden;
    z-index: 10;
    @media screen and (max-width:$screen-xs-max) {
        border-radius:0 0 4px 4px;
    }
    &.affixed{
        z-index:1;
    }
    > .team-create {
        min-height: 515px !important;
        background-position-y: 112px !important;
        .btn-clear-team {
            @include OswaldMedium();
            position: absolute;
            box-sizing: inherit;
            background: #48bcd1;
            color: #fff;
            width: 35%;
            left: 32%;
            bottom: 3%;
            text-align: center;
            padding: 10px;
            border: 2px solid #48bcd1;
            border-radius: 20px;
            z-index: 9;
            transition: all 1s;
            &:hover{
                background: transparent;
                color: #48bcd1;
            }
        }
    }
    > .team-tab-inner{
        min-height: 456px;
        position: relative;
        background: url(/../../assets/img/ground.png) no-repeat;
        background-position: center;
        border: 1px solid #48bcd1;
        .nav-tabs {
            position: relative;
            border-bottom:none;
            /*background:rgba(0,0,0,0.4);*/
            height: 40px;
            /*border-radius: 4px;*/
            > li{
                margin-bottom:0;
                border-right:1px solid rgba(72, 188, 209, 0.2) !important;
                width: 20%;
                &:last-child{
                    border:none !important;
                }
                > a{
                    color: #fff;
                    border:none !important;
                    position: relative;
                    margin-right:0;
                    line-height:20px;
                    border-right:1px solid rgba(72, 188, 209, 0.2) !important;
                    padding:10px;
                    &:last-child{
                        border:none !important;
                    }
                    &:hover{
                        background:none;
                    }
                    &:after{
                        content: "";
                        position:absolute;
                        background:transparent;
                        height: 2px;
                        width:100%;
                        bottom:0;
                        left:0;
                        border-bottom:1px solid rgba(72, 188, 209, 0.2);
                    }
                }
                &.active, &:hover{
                    a{
                        background:none;
                        &:hover{
                            background:none;
                        }
                        &:after{
                            background:#48bcd1;
                        }
                    }
                }
            }
            > .add-team-btn{
                position: absolute;
                top: 4px;
                right: 4px;
                padding: 5px;
                background: none;
                color: #fff;
                border: none;
                font-size: 36px;
                @media screen and (max-width:360px) {
                    padding: 5px 5px;
                }
                &:hover, &:focus {
                    color: #48bcd1;
                }
            }
        }
        > .tab-content{
            .team-nav{
                @include clearfix();
                .pull-right{
                    span{
                        display:block;
                        color:#fff;
                        &:first-child{
                            font-size:22px;
                            @include Bold();
                            line-height: 16px;
                        }
                    }
                }
                .blank-team-nav {
                    text-align: center;
                }
                .filled-team-nav {
                    z-index: 1;
                }
                > button {
                    position: absolute;
                    box-sizing: inherit;
                    background: #48bcd1;
                    color: #fff;
                    width: 35%;
                    left: 32%;
                    bottom: 3%;
                    text-align: center;
                    padding: 10px;
                    border: 2px solid #48bcd1;
                    border-radius: 20px;
                    z-index: 9;
                    transition: all 1s;
                    &:hover, &:focus {
                        background: transparent;
                        color: #48bcd1;
                    }
                }
            }

        }
    }
    &.soccer-ground{
        background:#5CB561;
        > .team-tab-inner{
            background:none;
            min-height: 700px;
            .team-creation-nav{
                padding: 7px 15px 7px 15px;
                min-height: 40px;
                @media screen and (max-width:360px) {
                    padding: 7px 10px 7px 10px;
                }
            }
            .ground-wrapper{
                min-height: 547px;
                //background: url(../img/ground-soccer.svg) no-repeat #5CB561;
                background-position: center;
                & + .team-creation-nav{
                    padding: 5px 15px 15px 15px;
                }
            }
        }

    }
    &.blank_status{
        .team-tab-inner{
            .nav-tabs{
                display:none;
            }
            .ground-wrapper{
                min-height: 646px;
            }
        }
    }
}

.ground-wrapper {
  // background:url(../img/ground.png) no-repeat;
  // background-position: center 0;
  min-height: 408px;

  &.less-height {
    min-height: 360px;
    margin-bottom: 20px;
  }

  .team-formation {
    /*max-width:368px;*/
    /*margin:0 auto;*/
    position: absolute;
    /*min-height: 75px;*/
    width: 100%;
    height: 408px;

    &.blank-team-margin {
      margin-top: 20px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        text-align: center;

        .player-icon {
          display: block;
          width: 47px;
          height: 53px;
          font-size: 36px;
          position: relative;
          margin: 0 auto;
          color: #221E1F;
          text-align: center;

          i {
            position: relative;
            z-index: 1;
            width: 47px;
            height: 71px;

            &:before {
              content: "";
              width: 47px;
              height: 71px;
              display: block;
              //zoom: 0.75;
              background: url(/../../assets/img/player.png) no-repeat;

              @media (-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi) {
                background: url(/../../assets/img/player.png) no-repeat;
              }
            }
          }

          .player-remove {
            width: 20px;
            height: 20px;
            display: block;
            top: 6px;
            right: 2px;
            position: absolute;
            z-index: 10;

            &:after {
              width: 14px;
              height: 14px;
              background: #F0481A;
              color: #fff;
              border-radius: 3px;
              display: block;
              content: "\2212";
              @include vendor(box-shadow, 0px 2px 1px rgba(0,0,0,0.2));
              font-size: 14px;
              line-height: 16px;
              text-align: center;
              margin: 3px 0px 0 3px;
              cursor: pointer;
            }
          }
          // &:after{
          // 	content:"";
          //     display: block;
          //     width: 80%;
          //     max-width: 80%;
          //     background-color: transparent;
          //     height: 8px;
          //     -moz-border-radius: 30%/74%;
          //     -webkit-border-radius: 30%/74%;
          //     border-radius: 30%/74%;
          //     margin: 0 auto 0;
          //     @include vendor(box-shadow, 0 12px 7px 0px rgba(0, 0, 0, 0.3));
          //        position: absolute;
          //     bottom:14px;
          //     left: 0;
          //     right: 0
          // }
        }

        .player-name {
          width: 51px;
          background: $brand-warning;
          border-radius: 2px;
          color: #333333;
          font-size: 10px;
          text-align: center;
          display: block;
          clear: both;
          margin: 0 auto;
          padding: 0 3px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .player-point {
          width: 53px;
          background: rgba(0,0,0,0.6);
          border-radius: 2px;
          color: #fff;
          font-size: 10px;
          text-align: center;
          display: block;
          clear: both;
          margin: 2px auto 0;
        }

        &.empty {
          .player-icon {
            i {
              color: rgba(34,30,31,0.3);
            }
          }

          .player-name {
            display: none;
          }

          .player-point {
            display: none;
          }
        }

        &.captain {
          .player-icon {
            &:before {
              position: absolute;
              z-index: 2;
              top: 25px;
              left: 27px;
              content: "C";
              color: #fff;
              font-size: 10px;
              height: 11px;
              @include Medium();
              display: inline-block;
              line-height: 11px;
              text-align: center;
              border-radius: 2px;
              padding: 0 5px;
              @include vendor(box-shadow, 0px 2px 1px rgba(0,0,0,0.2));
              background: #55b8ed; /* Old browsers */
              background: -moz-linear-gradient(top, #55b8ed 0%, #3e97d0 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top, #55b8ed 0%,#3e97d0 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom, #55b8ed 0%,#3e97d0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55b8ed', endColorstr='#3e97d0',GradientType=0 ); /* IE6-9 */
            }
          }
        }

        &.vice-captain {
          .player-icon {
            &:before {
              position: absolute;
              z-index: 2;
              top: 25px;
              left: 27px;
              content: "VC";
              color: #333;
              font-size: 10px;
              height: 11px;
              @include Medium();
              display: inline-block;
              line-height: 11px;
              text-align: center;
              border-radius: 2px;
              padding: 0 3px;
              @include vendor(box-shadow, 0px 2px 1px rgba(0,0,0,0.2));
              background: #dfdfdf; /* Old browsers */
              background: -moz-linear-gradient(top, #dfdfdf 0%, #c4c4c4 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top, #dfdfdf 0%,#c4c4c4 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom, #dfdfdf 0%,#c4c4c4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfdfdf', endColorstr='#c4c4c4',GradientType=0 ); /* IE6-9 */
            }
          }
        }
      }

      &.wicketkeeper {
        height: 83px;
        /*margin-top: 10px;*/
        li {
          /*width: 38px;*/
          /*height: 36px;*/
          /*margin-left: 135px;*/
          padding-top: 0px;
          /*                    @media screen and (max-width:320px) {
                        margin-left: 125px;
                    }
                    @media screen and (min-width:321px) and (max-width:360px) {
                        margin-left: 145px;
                    }
                    @media screen and (min-width:361px) and (max-width:375px) {
                        margin-left: 130px;
                    }
                    @media screen and (min-width:376px) and (max-width:414px) {
                        margin-left: 147px;
                    }
                    @media screen and (min-width:415px) and (max-width:768px) {
                        margin-left: 90px;
                    }
                    @media screen and (min-width:769px) and (max-width:1024px) {
                        margin-left: 115px;
                    }
                    @media screen and (min-width: 1025px) and (max-width: 1199px) {
                        margin-left: 108px
                    }*/
          .player-icon {
            height: 45px;

            i {
              &:before {
                background-position: -400px 0px;
              }
            }
          }

          &.active {
            margin-top: -10px;

            .player-icon {
              i {
                &:before {
                  background-position: -468px 0px;
                }
              }
            }
          }
        }
      }

      &.bats-man {
        height: 83px;

        li {
          position: absolute;
          width: 53px;
          height: 85px;

          .player-icon {
            font-size: 60px;

            i {
              &:before {
                background-position: -270px 0;
              }
            }
          }

          &.active {
            .player-icon {
              i {
                &:before {
                  background-position: -340px 0;
                }
              }
            }
          }

          &:first-child {
            left: 47%;

            @media screen and (max-width: $screen-xs) {
              left: 41%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 40%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 40%;
            }
          }

          &:nth-child(2) {
            left: 35%;

            @media screen and (max-width: $screen-xs) {
              left: 23%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 20%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 23%;
            }
          }

          &:nth-child(3) {
            left: 59%;

            @media screen and (max-width: $screen-xs) {
              left: 60%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 60%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 57%;
            }
          }

          &:nth-child(4) {
            left: 23%;

            @media screen and (max-width: $screen-xs) {
              left: 5%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 0%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 7%;
            }
          }

          &:last-child {
            left: 71%;

            @media screen and (max-width: $screen-xs) {
              left: 80%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 80%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 75%;
            }
          }
        }
      }

      &.all-rounder {
        height: 83px;

        li {
          position: absolute;
          width: 52px;
          height: 85px;

          .player-icon {
            font-size: 60px;

            i {
              &:before {
                background-position: 0 0;
              }
            }
          }

          &.active {
            .player-icon {
              i {
                &:before {
                  background-position: -75px 0;
                }
              }
            }
          }

          &:first-child {
            left: 47%;

            @media screen and (max-width: $screen-xs) {
              left: 41%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 40%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 40%;
            }
          }

          &:nth-child(2) {
            left: 35%;

            @media screen and (max-width: $screen-xs) {
              left: 23%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 20%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 23%;
            }
          }

          &:nth-child(3) {
            left: 59%;

            @media screen and (max-width: $screen-xs) {
              left: 60%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 60%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 57%;
            }
          }
        }
      }

      &.bowler {
        height: 83px;

        li {
          position: absolute;
          width: 53px;
          height: 85px;

          .player-icon {
            i {
              &:before {
                background-position: -145px 0;
              }
            }
          }

          &.active {
            .player-icon {
              i {
                &:before {
                  background-position: -209px 0;
                }
              }
            }
          }

          &:first-child {
            left: 47%;

            @media screen and (max-width: $screen-xs) {
              left: 41%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 40%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 40%;
            }
          }

          &:nth-child(2) {
            left: 35%;

            @media screen and (max-width: $screen-xs) {
              left: 23%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 20%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 23%;
            }
          }

          &:nth-child(3) {
            left: 59%;

            @media screen and (max-width: $screen-xs) {
              left: 60%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 60%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 57%;
            }
          }

          &:nth-child(4) {
            left: 23%;

            @media screen and (max-width: $screen-xs) {
              left: 5%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 0%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 7%;
            }
          }

          &:last-child {
            left: 71%;

            @media screen and (max-width: $screen-xs) {
              left: 80%;
            }

            @media screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
              left: 80%;
            }

            @media screen and (min-width: $screen-lg) {
              left: 75%;
            }
          }
        }
      }
    }
  }

  &.turbo-linup {
    .team-formation {
      ul {
        &.bats-man {
          margin-left: 170px;

          li {
            &:first-child {
              top: 66%;
              left: 107%;

              @media screen and (max-width:320px) {
                top: 55%;
              }
            }

            &:nth-child(2) {
              top: 75%;
              left: 38%;
            }

            &:nth-child(3) {
              top: 75%;
              left: 147%;

              @media screen and (max-width:320px) {
                top: 64%;
              }

              @media screen and (min-width:376px) and (max-width:414px) {
                left: 140%;
              }

              @media screen and (min-width:321px) and (max-width:360px) {
                left: 141%;
              }

              @media screen and (min-width:415px) and (max-width:768px) {
                left: 142%;
              }

              @media screen and (min-width:769px) and (max-width:1024px) {
                left: 140%;
              }
            }

            &:last-child {
              top: 115%;

              @media screen and (min-width:321px) and (max-width:360px) {
                left: 3px;
              }

              @media screen and (min-width:376px) and (max-width:414px) {
                top: 88px;
                left: 20px;
              }

              @media screen and (min-width:769px) and (max-width:1024px) {
                left: 10px;
              }

              @media screen and (min-width:1025px) {
                left: 10px;
              }
            }
          }
        }

        &.bowler {
          margin-left: 190px;

          &:first-child {
            top: 168%;
            left: 86%;
          }

          &:nth-child(2) {
            top: 282%;
            left: 62%;

            @media screen and (max-width:320px) {
              left: 60%;
            }
          }

          &:nth-child(3) {
            top: 282%;
            left: 120%;

            @media screen and (max-width:320px) {
              left: 122%;
            }
          }

          &:last-child {
            top: 209%;
            left: 30%;
          }
        }

        &.all-rounder {
          margin-left: 150px;

          &:first-child {
            top: 358%;
            left: 88%;
          }

          &:nth-child(2) {
            top: 315%;
            left: 10%;
          }

          &:nth-child(3) {
            top: 315%;
            left: 160%;
          }

          &:last-child {
            top: 315%;
            left: 160%;
          }
        }
      }
    }
  }

  .team-formation-margin {
    margin-top: -66px;
  }
}
.league-ground-height {
    min-height: 352px !important;
}
