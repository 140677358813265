@media screen and (max-width: $screen-xs-max) {
    .player-credit-div {
        @include Regular();
        background-color: rgb(4, 113, 103);
        color: $league-span-color;
        text-align: center;
        border: 1px solid $brand-primary;
        border-bottom: none;
        height: 58px;
        padding: 5px 0;
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: 0;
        z-index: 9;
        .right-border{
            border-right: 1px solid $league-span-color;
        }
        div.col-xs-12 {
            padding: 2px;
        }
    }
}

.listing-filter {
    display: block;
    width: 100%;
    background: rgba(28, 93, 87, 0.4);
    border: 1px solid #48bcd1;
    border-bottom: 2px solid $panel-bottom-border-color;
    @include clearfix();



    .listing-filter-drop {
        display: inline-block;
        vertical-align: middle;

        @media screen and (max-width:$screen-md) {
            border-bottom: 2px solid $panel-bottom-border-color;
            padding: 2px 0;
        }

        .dropdown {
            .dropdown-toggle {
                padding: 11px;
                display: inline-block;
                font-size: 20px;
            }

            //   @media screen and (min-width:$screen-md) {
            //     .dropdown-toggle {
            //       display: none;
            //     }
            //   }

            .dropdown-menu {
                padding: 0;
                min-width: auto;
                // @media screen and (min-width:$screen-md) {
                display: block;
                margin: 0;
                padding: 0;
                position: relative;
                box-shadow: none;
                border: none;
                background: transparent;
                z-index: 1;
                opacity: 1;
                visibility: visible;

                // }
                li {
                    border-bottom: 1px solid #ddd;

                    &:last-child {
                        border-bottom: none;
                    }

                    // @media screen and (min-width:$screen-md) {
                    display:inline-block;
                    border-bottom:none;

                    // }
                    a {
                        position: relative;

                        padding: 5px 8px;
                        min-width: 47px;
                        @include vendor(transition, all 300ms ease);

                        @media screen and (min-width:$screen-lg) {
                            padding: 5px 18px;
                            min-width: 46px;
                        }

                        @media screen and (max-width:$screen-sm-max) {
                            padding: 5px 18px;
                            min-width: 46px;
                        }

                        span {
                            color: #fff;
                            @include vendor(transition, all 300ms ease);

                            &:first-child {
                                display: block;
                                width: 100%;
                                text-align: center;

                                &:after {
                                    font-family: 'goldentech' !important;
                                    content: "";
                                }
                            }

                            &:last-child {
                                @include OswaldLight();
                                font-size: 12px;
                                text-align: center;
                                margin: 0 auto;
                                display: table;

                                b {
                                    color: #fff;
                                }
                            }

                            .icon-question {
                                color: #888888 !important;
                                font-size: 16px !important;
                            }
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            background: transparent;
                            width: 100%;
                            height: 3px;
                            left: 0;
                            @include vendor(transition, all 300ms ease);
                        }
                    }

                    &.active,
                    &:hover {
                        a {
                            background: transparent;

                            span {
                                &:first-child {
                                    color: $brand-primary;
                                    display: block;
                                    width: 100%;
                                    text-align: center;
                                }

                                &:last-child {
                                    font-size: 12px;
                                    color: #fff;

                                    b {
                                        color: #fff;
                                    }
                                }
                            }

                            &:after {
                                background: $brand-primary;
                            }
                        }
                    }

                    &.full {
                        a {
                            span {
                                &:first-child {
                                    &:after {
                                        content: "\e909";
                                        color: $brand-success;
                                        font-weight: bolder;
                                        font-size: 11px;
                                        margin-left: 3px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.open {
                .dropdown-menu {
                    //   @media screen and (min-width:$screen-md) {
                    display: block;

                    //   }
                }
            }

        }
    }

    .form-elements {
        display: inline-block;
        // width: 46%;
        vertical-align: middle;

        // @media screen and (max-width:$screen-md) {
        //   width: 90%;
        //   width: calc(100% - 46px);
        // }

        .form-group {
            display: inline-block;
            width: 49%;
            vertical-align: middle;

            &:first-child {
                padding-right: 5px;
            }
        }

        .select2-container .select2-choice {
            color: #fff;
        }
    }

    @media screen and (max-width:$screen-md) {
        .new-padding-md {
            padding: 10px 0;
        }
    }
}

.download-lineup {
    .text-success {
        color: #48bcd1;
    }
}

.lineup-table {

    // @media screen and (max-width:$screen-xs-max) {
    //      	table-layout:fixed;
    //    }
    >thead {
        >tr {
            >th {}
        }
    }

    >tbody {
        >tr {
            >td {
                @include Regular();
                color: #fff;
                // .btn-primary{
                // 	@media screen and (min-width:$screen-md){
                // 		min-width:64px;
                // 	}
                // }
                vertical-align: middle;

                .winners {
                    display: block;
                    clear: both;

                    a {
                        cursor: pointer;
                        color: #999999;
                        font-size: 12px;

                        .icon-crown {
                            margin-right: 5px;
                            color: $brand-warning;
                            font-size: 16px;
                        }
                    }
                }

                .country-flag-left {
                    display: inline-block;
                    vertical-align: middle;
                    margin-top: -1px;

                    img {
                        float: left;
                    }
                }

                .country-name {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 5px;
                    color: #fff;
                    @include OswaldRegular();

                    @media screen and (max-width:991px) {
                        margin: 0 0px;
                    }
                }

                .country-flag-right {
                    display: inline-block;
                    vertical-align: middle;
                }

                &.participant-td {
                    @include Regular();

                    .remaning-progress {
                        .remaning-amount {
                            font-size: 14px;
                            color: #666666;
                            position: relative;
                            z-index: 1;
                            display: block;
                            width: 100%;
                            margin-top: 0;
                            margin-bottom: 1px;
                        }

                        .progress {
                            position: relative;
                            z-index: 1;
                            max-width: 85px;
                            height: 2px;
                            border-radius: 0;
                            margin: 0;
                            display: inline-block;
                            width: 100%;
                            float: right;

                            .progress-bar {
                                box-shadow: none;
                            }
                        }
                    }
                }

                &.brod-td {
                    max-width: 100%;

                    @media screen and (max-width:$screen-xs-max) {
                        width: 100%;
                    }

                    @media screen and (max-width:550px) {
                        max-width: 225px !important;
                    }

                    @media screen and (max-width:414px) {
                        max-width: 216px !important;
                    }

                    @media screen and (max-width:320px) {
                        max-width: 202px !important;
                    }

                    .brod-content {
                        white-space: nowrap;
                        //width: inherit;	
                        display: block;

                        @media screen and (min-width:$screen-sm) {
                            max-width: 300px;
                        }

                        @media screen and (min-width:$screen-md) {
                            max-width: 160px;
                        }

                        @media screen and (min-width:$screen-lg) {
                            max-width: 363px;
                        }

                        .other-info {
                            display: none;
                            position: relative;
                            z-index: 1;

                            @media screen and (max-width:$screen-sm-max) {
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 5px;
                            }

                            .btn {
                                &.open {
                                    .caret {
                                        @include vendor(transform, rotateX(180deg));
                                    }
                                }
                            }
                        }

                        .league-detail {
                            display: block;
                            padding-left: 27px;
                            white-space: normal;

                            @media screen and (max-width:$screen-sm-max) {
                                display: inline-block;
                                vertical-align: middle;
                                margin-left: -24px !important;
                                padding-left: 51px !important;
                                width: 100%;
                            }

                            >i {
                                display: inline-block;
                                vertical-align: middle;
                                font-size: 22px;
                                margin-left: -27px;
                                color: #48bcd1;

                                >.player-type {
                                    font-size: 10px;
                                    display: block;
                                    color: #48bcd1;
                                    text-align: center;
                                    @include Medium();
                                }
                            }

                            >span {
                                display: inline-block;
                                vertical-align: middle;
                                width: 100%;
                                padding-left: 5px;
                                @include OswaldRegular();
                                color: #fff;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                >.name {
                                    display: inline-block;
                                    vertical-align: middle;

                                    >small {
                                        display: block;
                                        clear: both;
                                        font-size: 12px;
                                        color: #878787;
                                    }
                                }

                                >i {
                                    display: inline-block;
                                    vertical-align: middle;
                                    font-size: 16px;
                                }
                            }
                        }

                        .initial-details {
                            display: none;
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            border: 1px solid #BBBBBB;
                            background-color: #fff;
                            margin-top: 10px;
                            margin-bottom: 0;
                            font-size: 12px;

                            li {
                                display: table;
                            }

                            &.open {
                                display: table;
                                width: 100%;
                                float: left;
                            }

                            ._terms,
                            ._description {
                                padding: 4px 8px;
                                display: table-cell;
                                vertical-align: middle;
                            }

                            ._description {
                                color: #333333;
                                text-align: right;
                                width: 100%;

                                .progress {
                                    position: relative;
                                    z-index: 1;
                                    max-width: 85px;
                                    height: 2px;
                                    border-radius: 0;
                                    margin: 0;
                                    display: inline-block;
                                    width: 100%;
                                    float: right;

                                    .progress-bar {
                                        box-shadow: none;
                                    }
                                }
                            }

                            ._terms {
                                color: #666666;
                                width: 90px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.team-creation-nav {
    padding: 20px 0 0 0;
    @include clearfix();
    clear: both;
    min-height: 60px;

    >button {
        @include OswaldMedium();
    }
}

.max-7p {
    color: #fff;
    font-size: 12px;
    @include OswaldMedium();

}

.pt5 {
    padding: 5px 0;
    border: 1px solid #48bcd1;
    margin-top: 5px;


}

.pr20 {
    padding-right: 20px;
}


@function stars-color($num) {
    $colors: #cc8b1f #dcb228 #f0991e #f26a2c #dd050b;
    @return if($num <=length($colors), nth($colors, $num), #333);
}

@for $i from 1 to 12 {
    @for $j from 1 through $i {
        [data-rating^='#{$i}'] .rating-#{$j} {
            &:before {
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffdd6a+0,ffd104+100 */
                background: #ffdd6a !important;
                /* Old browsers */
                background: -moz-linear-gradient(top, #ffdd6a 0%, #ffd104 100%) !important;
                /* FF3.6-15 */
                background: -webkit-linear-gradient(top, #ffdd6a 0%, #ffd104 100%) !important;
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, #ffdd6a 0%, #ffd104 100%) !important;
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdd6a', endColorstr='#ffd104', GradientType=0);
                /* IE6-9 */
            }
        }
    }

    [data-rating^='#{$i}.12'] .rating-#{$i+1} {
        &:before {
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffdd6a+0,ffd104+100 */
            background: #ffdd6a !important;
            /* Old browsers */
            background: -moz-linear-gradient(top, #ffdd6a 0%, #ffd104 100%) !important;
            /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #ffdd6a 0%, #ffd104 100%) !important;
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #ffdd6a 0%, #ffd104 100%) !important;
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdd6a', endColorstr='#ffd104', GradientType=0);
            /* IE6-9 */

        }
    }
}

.team-selection-progress {
    background: rgba(0, 0, 0, 0.6);
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    line-height: 14px;
    font-size: 0;
    float: left;
    margin: 0 0 10px 0;

    .team-selection-progress-bar {
        width: 100%;
        display: inline-block;
        vertical-align: middle;
        margin-right: -61px;
        padding-right: 63px;
        text-align: center;

        ul {
            display: table;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                display: table-cell;
                height: 14px;
                border: 1px solid rgba(28, 93, 87, 0.4);

                &:before {
                    display: block;
                    content: "";
                    height: 100%;
                    width: 100%;
                    background: #000;
                }

                // &[data-rating="11"] i {
                // 	background: $brand-warning;
                //   &:before{
                //   	background: $brand-warning;
                //   }
                // }
            }

        }
    }

    .selection-status {
        @include OswaldMedium();
        width: 57px;
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        font-size: 12px;
        text-align: center;

        b {
            color: #fff;
            @include Medium();
        }
    }
}

.credit-details {
    background: rgba(28, 93, 87, 0.4);
    color: #fff;
    float: left;
    width: 100%;
    @include clearfix;

    >.turbo-heading {
        padding: 10px;
        font-size: 16px;
    }

    >.block {
        display: table;
        width: 50%;
        float: left;
        padding: 0 10px;
        line-height: 1;
        font-size: 0;
        min-height: 40px;

        &:last-child {
            border-left: 1px solid #48bcd1;
        }

        >span {
            min-height: 40px;
            display: table-cell;
            vertical-align: middle;
            line-height: 20px;
            @include Regular();
            color: #fff;

            &.team-points {
                font-size: 22px;
                @include OswaldMedium();
                color: inherit;
            }

            &:last-child {
                text-align: right;
                font-size: 12px;
                color: inherit;
                line-height: 14px;
            }
        }
    }
}
