// Single side border-radius

@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
   border-top-left-radius: $radius;
}
@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
     border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
   border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
     border-top-left-radius: $radius;
}


@mixin verticalalign($verticalvalue) {
  vertical-align: $verticalvalue;
}

@mixin borderRadius($border-radius) {
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  -o-border-radius: $border-radius;
  border-radius: $border-radius;
}
