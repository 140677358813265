.top-banner {
    height: 93px;
    width: 100%;
    background: url(/../../assets/img/top-section.png) no-repeat;
    -ms-background-size: cover;
    background-size: cover;
    display: table;
    position: relative;
    a {
        float: right;
        margin-right: 20px;
        margin-top: -35px;
        @media screen and (max-width: $screen-sm) {
            margin-top: 0px;
        }
    }
}
.leaderboard-wrapper {
    margin-top: 10px;
    min-height: auto;
    @include clearfix();
    .form-elements {
        float: right;
        width: 100%;
        max-width: 325px;
        font-size: 0;
        .form-group {
            width: 50%;
            display: inline-block;
            padding: 0 5px;
        }
        @media screen and (max-width: 767px) {
            float: left;
            width: 100%;
            margin-bottom: 5px;
            display: block;
        }
    }
    .tab-content {
        background: none;
    }
    .kapil-leaderboard {
        background: url(/../../assets/img/kapil/Leaderboard-bg.png) no-repeat;
        background-position: top right;
    }
    .select2-container {
        width: 350px;
    }
    .select2-container .select2-choice {
        color: #fff;
    }
    .leaderboard-table {
        .table {
            > thead {
                > tr {
                    > th {
                        color: #fff;
                        @include Regular();
                        padding: 10px;
                        i {
                            line-height: 1px;
                            display: inline-block;
                            font-size: 10px;
                        }
                        &:first-child {
                            padding-left: 15px;
                        }
                        &:last-child {
                            padding-right: 25px;
                        }
                    }
                }
            }
            > tbody {
                > tr {
                    > td {
                        &.rank {
                            max-width: 65px;
                            width: 65px;
                        }
                        &:first-child {
                            padding-left: 15px;
                        }
                        &:last-child {
                            padding-right: 25px;
                        }
                        vertical-align: middle;
                        color: #fff;
                        padding: 5px 10px;
                        @include Regular();
                        .user-info-attrb {
                            .selected-thumb {
                                display: inline-block;
                                vertical-align: middle;
                            }
                            .user-name-span {
                                display: inline-block;
                                vertical-align: middle;
                                width: 100%;
                                overflow: hidden;
                                @include Medium();
                                color: #fff;
                                @media screen and (min-width: $screen-xs-max) {
                                    margin-left: -44px;
                                    padding-left: 50px;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                        &.brod-td {
                            @media screen and (max-width: $screen-xs-max) {
                                width: 100%;
                            }
                            max-width: 100%;
                            .brod-content {
                                .league-detail {
                                    display: block;
                                    padding-left: 0;
                                    white-space: normal;
                                    @media screen and (max-width: $screen-sm-max) {
                                        display: inline-block;
                                        vertical-align: middle;
                                        margin-left: -27px !important;
                                        padding-left: 30px !important;
                                        width: 100%;
                                    }
                                    @media screen and (max-width: $screen-xs-max) {
                                        margin-left: 0px !important;
                                        padding-left: 0px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
