.content-detail-tabs {
    display: block; 
    .nav-tabs> li.active >a,
    .nav-tabs> li.active >a:hover,
    .nav-tabs >li.active >a:focus {
        background-color: #282828 !important;
        border: 0px;
        @media(min-width:768px){
        	height: 60px;
           line-height: 40px;
        }
        color: white;
    }
    .nav-tabs-justified {
        >li {
            @media(min-width: 768px) {
                width: 18%;
            }
            >a {
                border: 0px;
                @media(min-width:768px){
		        	height: 60px;
		           line-height: 40px;
                }
                background-color: #333333;
                font-weight: bold;
                border-radius: 0px;
                i {
                    font-size: 21px;
                    display: inline-block;
                    vertical-align: sub;
                }
            }
        }
    }
    .nav-tabs.nav-justified>li>a {
        border: 0px;
        @media(min-width:768px){
        	height: 60px;
           line-height: 40px;
        }
        background-color: #333333;
    }
    .nav>li>a:hover,
    .nav>li>a:focus {
        background-color: none;
    }
    .tab-content {
        background-color: #282828;
    }
}

.pick-11-players {
    padding-left: 7px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    @include text-overflow();
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-left: -38px;
    padding-left: 46px;
}

.pick-11-players-from {
    color: #FFFFFF;
    font-size: 16px;
    @media(max-width:767px){
    	font-size:12px;
    }
    font-weight: bold;
    line-height: 21px;
}

.pick-11-players-from-count {
    ul {
        li {
            width: 19%;
            @media(max-width: 767px) {
                width: 32%;
            }
            text-align: left;
            list-style: none;
            display: inline-block;
        }
    }
}

.content-team-detail {
    margin: 30px 0px 20px 0px;
    text-align: center;
    .team {
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        display: inline-block;
        vertical-align: middle;
        margin: 0px 1px;
    }
    .timer {
        color: #AAAAAA;
        font-size: 12px;
        line-height: 16px;
        display: block;
        margin-top: 5px;
    }
}

.league-detail-info {
    position: relative;
    &:before {
        margin-bottom: 20px;
        position: relative;
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        background-color: $body-transparent-bg;
    }
    &:after {
        margin-top: 20px;
        position: relative;
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        background-color: $body-transparent-bg;
    }
    ul {
        li {
            display: inline-block;
            width: 32%;
            text-align: center;
        }
    }
}

.block {
    display: block;
}

.title-top-content {
    .left-box {
    	max-width: 60%;
    	@media(max-width:767px){
    		max-width: 280px;
    	    width: 280px;

    	}
        display: inline-block;
        vertical-align: middle;
        .team-pic {
            vertical-align: middle; 
            display: inline-block;
            @include user-thumb(32px);
            border-radius: 0px;
        }
    }
    .right-box {
        display: inline-block;
        float: right;
        @media(max-width:767px){
        	float:left;
        }
        margin-right: 33px;
    }
}

.salary-cap-scoring {
    ul {
    	
        li {
            list-style: none;
            color: #B5B5B5;
            font-size: 14px;
            line-height: 28px;
            float: left;
            width: 100%;

            span{
            	@include text-overflow();
    	        max-width: 230PX;
            }
        }
    }
    .title {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .salary-cap {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        text-align: center
    }
}

.white {
    color: white;
}

.salary-cap-list-title {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    display: inline-block;
    @media(max-width: 767px) {
        margin-top: 20px;
    }
}

.font-18 {
    font-size: 18px;
    font-weight: bold;
}

.invite-btn {
    @include vendor(background, linear-gradient(180deg, #B4EC51 0%, #429321 100%));
    @include vendor (box-shadow, 0 1px 0 0 rgba(0, 0, 0, 0.1));
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    &:hover {
        @include vendor(background, linear-gradient(180deg, #B4EC51 0%, #429321 100%));
        opacity: 0.8;
    }
}

.btn-90 {
    min-width: 90px !important;
    width: 90px;
    height: 36px;
}

.share-btn {
    margin-right: 10px !important;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    @include vendor(box-shadow, 0 1px 0 0 rgba(0, 0, 0, 0.1));
    color: white;
    opacity: 0.6;
    &:hover {
        color: white;
        opacity: 0.6;
    }
}

.the-contest-is-guara {
    padding-top: 70px;
    padding-bottom: 70px;
    margin-top: 20px;
    color: #999999;
    font-size: 14px;
    line-height: 26px;
    display: inline-block;
    position: relative;
    width: 100%;
    &:before {
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.1);
        position: absolute;
        top: 0px;
    }
}

.btn-join {
    @include vendor(background, linear-gradient(180deg, #54B8EC 0%, #3D96D0 100%));
    height: 56px;
    font-size: 14px;
    font-weight: 500;
    &:hover {
        @include vendor(background, linear-gradient(180deg, #54B8EC 0%, #3D96D0 100%));
        opacity: 0.8;
    }
}
.right-side-modal-window {
    .tab-content {
        height: calc(100vh - 126px);
        overflow: auto;
    }
    .modal-content {
        .modal-header {
            .modal-title {
                margin-right: -24px;
                padding-right: 0;
            }
        }
    }
}
//PARTICIPENTS TAB 
.total-participants {
    color: #8A8A8A;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    margin-left: 19px;
    font {
        color: white;
    }
}
.participants-pic {
    display: inline-block;
    vertical-align: middle;
    @include user-thumb(36px);
}

.participants-name {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
    vertical-align: middle;
    @include text-overflow();
    max-width: 200PX;
    margin-left: 20px;
}
.participants-detail-cont {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    @media(max-width: 767px) {
        text-align: left;
    }
}
.participants-detail {
    margin: 10px 0px;
}

//PRICE TAB 
.price-distribute-position-cont {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    padding: 10px;
    .price-distribute-position {
        height: 38px;
        width: 38px;
        background-color: #000000;
        display: inline-block;
        vertical-align: middle;
        color: white;
        border-radius: 100%;
        line-height: 38px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        padding: 3px;
        sup {
            color: #9C9C9C;
        }
    }
    .price-amount {
        color: #FFFFFF;
        font-size: 14px;
        line-height: 16px;
        display: inline-block;
        margin-left: 15px;
    }
}
.price-type-cont {
    position: relative;
    padding-bottom: 20px;
    &:after {
        margin-top: 20px;
        position: relative;
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.1);
    }
}
.price-type {
    max-width: 382px;
    margin: 0 auto;
    .price-box {
        display: inline-block;
        padding: 10px;
        width: 39%;
        text-align: right;
        .price {
            font-size: 22px;
            font-weight: bold;
        }
    }
}
.prize-distribution {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
}
.prize-calculatio {
    color: #999999;
    font-size: 14px;
    line-height: 26px;
    max-width: 400px;
    margin: 0 auto;
    margin-top:50px;
    margin-bottom:80px;
    display: block;
    text-align: center;
}
