.user-prifile-container {
    background: none;
    padding-top: 1px;
    padding-bottom: 60px;
    display: inline-block;
    border: 1px solid $brand-primary;
    border-top: none;
    @include vendor(box-shadow, 0px 1px 0px 0px rgba(0, 0, 0, 0.1));
    margin-bottom: -5px;
    width: 100%;
    label {
        margin-left: 13px;
        font-size: 12px;
        color: #b5b5b5;
    }
    .form-control.input-alt {
        box-shadow: none;
        border-bottom: 2px solid #dbdbdb;
    }
    .select2-container .select2-default {
        color: #000000 !important;
        font-size: 14px !important;
    }
    .form-control {
        color: #e5e5e5;
        font-size: 14px;
    }
    select,
    option {
        color: #000000;
        font-size: 14px;
    }
}

.gray {
    color: #888888;
}
.select2-container .select2-choice .select2-arrow > b {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    color: #e5e5e5;
}

.edit-user-pic {
    width: 165px;
    height: 165px;
    margin: 30px auto;
    position: relative;
    overflow: hidden;
    z-index: 1;
    > img,
    > figure > img {
        min-width: 100%;
        position: absolute;
        top: 50%;
        min-height: 100%;
        left: 50%;
        border-radius: 100%;
        @include translate(-50%, -50%);
        height: 100%;
    }
    @include vendor(border-radius, 50%);

    .edit-button {
        background: rgba(98, 202, 252, 0.93);
        width: 40px;
        height: 40px;
        position: absolute;
        text-align: center;
        left: 50%;
        color: white;
        font-size: 20px;
        line-height: 39px;
        margin-left: -20px;
        top: 50%;
        margin-top: -20px;
        cursor: pointer;
        &:hover,
            &:active {
            background: rgba(98, 202, 252, 1);
        }
        @include vendor(border-radius, 50%);
        i {
            font-size: 16px;
            color: white;
        }
    }
}
.btn-118 {
    width: 118px;
}
.font-14 {
    font-size: 14px;
    top: 36px;
}
.pT60 {
    padding-top: 60px;
}
.mobile-input {
    padding-right: 80px;
    &.verified {
        padding-right: 30px;
        & ~ .icon-checkbox-brod {
            color: $brand-success;
            position: absolute;
            right: 10px;
            bottom: 40px;
        }
    }
}
.doc-max-size {
    font-size: 12px;
    color: #b5b5b5;
}
.pan-card-img-wrap {
    width: 98px;
    height: 62px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: block;
    border: 1px solid #ddd;
    > img {
        min-width: 100%;
        position: absolute;
        top: 50%;
        min-height: 100%;
        left: 50%;
        @include translate(-50%, -50%);
        height: 100%;
    }
}
.pancard-input {
    padding-right: 55px;
    &.verified {
        padding-right: 30px;
        & ~ .icon-checkbox-brod {
            color: $brand-success;
            position: absolute;
            right: 10px;
            bottom: 40px;
        }
    }
}
.upload-pancard {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 73px;

    li {
        @include OswaldLight();
        display: table-cell;
        vertical-align: middle;
        &:first-child {
            width: 164px;
        }
        &.uploaded {
            position: relative;
            width: 105px;
            padding: 0;
            > a {
                position: absolute;
                top: 0;
                z-index: 1;
                right: 4px;
                background: #fff;
                width: 16px;
                height: 16px;
                border-radius: 100%;
                color: #888;
                font-size: 16px;
            }
        }
        @media (max-width: 414px) {
            width: 100%;
            display: block;
            margin-top: 10px;
        }
    }
}
