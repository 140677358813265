[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'goldentech' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icon-list: ( 
'soccer-ball': e945,
'group-administrator-white': e964,
'infoIco': e965,
'loaderIco': e966,
'nextArrow': e967,
'notificationIco': e968,
'previousArrow': e969,
'pound-sterling-white': e96a,
'soccerIcon': e96b,
'p': e963,
'rupee': e962,
'active': e961,
'arrow-equal': e95e,
'arrow-up': e95f,
'arrow-down': e960,
'bowler': e95d,
'fielder-left': e95a,
'fielder-right': e95b,
'wicketkeeper': e95c,
'mail-alt': e925,
'bat': e958,
'menu-vertical': e959,
'info-alt': e901,
'bats-man': e902,
'money-bag-alt': e903,
'money-bag': e938,
'glyphicon-chevron-right': e942,
'glyphicon-chevron-left': e94a,
'select-arrow': e94b,
'g': e94c,
'm': e94d,
't': e94e,
'crown': e94f,
'send': e950,
'dollar': e951,
'cricket-bat': e952,
'copy-file': e953,
'bin': e954,
'wrestling': e955,
'checkbox-brod': e956,
'minus-square': e957,
'dropped': e946,
'loader': e947,
'blocking': e948,
'dislike': e949,
'cropped': e945,
'globe': e943,
'newspaper-alt': e944,
'gender': e941,
'return-alt': e93f,
'menu-home': e940,
'return': e93c,
'check-success': e93e,
'list-alt': e93b,
'user-single': e93d,
'lock-check': e93a,
'circular-small-menu': e939,
'add-circular': e900,
'minus-circular': e906,
'plus-alt': e907,
'minus-alt': e911,
'shorting-alt': e912,
'three-way-arrow': e913,
'refresh': e914,
'link': e915,
'comment': e916,
'uni-sex': e917,
'location-pin': e918,
'mobile': e919,
'user-group': e91a,
'four-way-arrow': e91b,
'news': e91c,
'back-arrow': e91d,
'info': e91e,
'football': e91f,
'shorting-down': e920,
'shorting-up': e921,
'shorting': e922,
'star': e923,
'menu-mini': e924,
'dollar-circular': e926,
'search': e927,
'menu-small': e928,
'menu': e929,
'tick-cicular-alt': e92a,
'tick-circular': e92b,
'cross-circular': e92c,
'play': e92d,
'stop-watch': e92e,
'calendar': e92f,
'smile': e930,
'trophy': e931,
'lock-alt': e932,
'lock': e933,
'question': e934,
'mail': e935,
'file': e936,
'facebook-alt2': e937,
'sub-menu': e904,
'cross': e905,
'dot': e908,
'check': e909,
'ring': e90a,
'triangle': e90b,
'instagram': e90c,
'facebook': e90d,
'google-plus': e90e,
'twitter': e90f,
'linkdin': e910


);
@each $key, $value in $icon-list {
    .icon-#{$key}:before {
        content: #{"\"\\"}#{$value + "\""};
    }
}

.icn-caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 5px dashed;
    border-top: 5px solid \9;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    .open & {
        @include rotate(-180deg);
    }
}

.glyphicon-chevron-left{
    font-family: 'goldentech' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:before {
        content: "\e94a";
    }
}
.glyphicon-chevron-right{
    font-family: 'goldentech' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:before {
        content: "\e942";
    }
}
.icon-m{
  background:#fff;
  color:#2DADF5;
  border-radius:100%;
  overflow:hidden;
}
.icon-t{
  position:relative;
  color:$brand-warning;
  border-radius:100%;
  overflow:hidden;
  &:after{
    content:"";
    display:block;
    background:#666666;
    width: 13px;
    height: 13px;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 0;
    border-radius: 100%;
  }
  &:before{
    position:relative;
    z-index: 1;
  }
}
.icon-g{
  background:#fff;
  color:$brand-success;
  border-radius:100%;
  overflow:hidden;
}
