.badges-container {
  .badges-block {
    margin-bottom: 40px;
    padding-bottom: 15px;
    border: 1px solid $panel-default-color;

    .h3 {
      display: block;
      margin-top: 0;
      font-size: 14px;
      background: $body-transparent-bg;
      padding: 8px 10px;
      margin-bottom: 10px;
      color: #E5E5E5;
      border-bottom: 2px solid $panel-bottom-border-color;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        margin: 5px auto 0;
        width: 33%;
        min-width: 270px;
        display: inline-block;
        user-select: none;

        @media screen and (max-width:991px) {
          display: block;
        }

        @media screen and (min-width:414px) {
          min-width: 296px;
        }

        .badges-inner {
          position: relative;
          border-radius: 6px;
          min-height: 140px;
          max-width: 296px;
          margin: 0 auto;
          background-size: cover !important;
          background-repeat: no-repeat !important;

          .badge-desc-block {
            .description {
              display: block;
              width: 138px;
              margin-left: 132px;
              padding-top: 20px;
              color: #fff;

              @media screen and (min-width:414px) {
                margin-left: 146px;
                width: 150px;
              }

              > p {
                margin: 0;
                font-size: 12px;

                &.badge-title {
                  font-family: "Bebas";
                  font-size: 22px;
                  line-height: 33px;
                  text-transform: uppercase;
                  color: #fff;
                  text-shadow: 0px 0px 0 #000, 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000;
                }
              }
            }
          }

          &.user-leaderboard {
            .badge-desc-block {
              .description {
                padding-top: 12px;

                > p {
                  &.badge-title {
                    color: #C90700;
                    text-shadow: 0px 0px 0 #fff, 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff;
                  }
                }
              }
            }
          }

          &.winning-ratio {
            .badge-desc-block {
              .description {
                text-align: center;
                margin-left: 116px;
                width: 170px;

                @media screen and (min-width:414px) {
                  margin-left: 116px;
                  width: 170px;
                }

                > p {
                  &.badge-title {
                    color: #fff;
                    text-shadow: 0px 0px 0 #333, 1px 1px 0 #333, -1px -1px 0 #333, -1px 1px 0 #333, 1px -1px 0 #333;
                  }
                }
              }
            }
          }

          &.points-gold {
            //background:url(../img/points-gold.png) no-repeat;
            background-size: cover;
          }

          &.points-silver {
            background: url(/../../assets/img/points-sliver.png) no-repeat;
            background-size: cover;
          }

          &.points-bronze {
            background: url(/../../assets/img/points-bronze.png) no-repeat;
            background-size: cover;
          }

          &.winning-ratio-gold {
            background: url(/../../assets/img/winner-gold.png) no-repeat;
            background-size: cover;
          }

          &.winning-ratio-silver {
            background: url(/../../assets/img/winner-sliver.png) no-repeat;
            background-size: cover;
          }

          &.winning-ratio-bronze {
            background: url(/../../assets/img/winner-bronze.png) no-repeat;
            background-size: cover;
          }

          &.user-gold {
            background: url(/../../assets/img/user-gold.png) no-repeat;
            background-size: cover;
          }

          &.user-silver {
            background: url(/../../assets/img/user-sliver.png) no-repeat;
            background-size: cover;
          }

          &.user-bronze {
            background: url(/../../assets/img/user-bronze.png) no-repeat;
            background-size: cover;
          }

          &.global-gold {
            background: url(/../../assets/img/global-gold.png) no-repeat;
            background-size: cover;
          }

          &.global-silver {
            background: url(/../../assets/img/global-sliver.png) no-repeat;
            background-size: cover;
          }

          &.global-bronze {
            background: url(/../../assets/img/global-bronze.png) no-repeat;
            background-size: cover;
          }

          .eligible-layer {
            display: none;
            border-radius: 5px;
            position: absolute;
            background: rgba(0, 0, 0, 0.8);
            top: 0;
            left: 0px;
            right: 0px;
            bottom: 0px;
            color: #fff;
            text-align: center;
            @include vendor(transition, all 0.5s ease);

            .eligible-inner {
              display: block;
              position: absolute;
              top: 50%;
              margin-top: -40px;

              > p {
                display: inline-block;
                margin: 0px;
                padding: 0 15px;
                font-size: 14px;
                @include Regular();
              }

              i {
                font-size: 42px;
                margin-bottom: 15px;
                display: inline-block;
              }
            }
          }

          .share-layer {
            border-radius: 5px;
            display: block;
            position: absolute;
            background: rgba(0, 0, 0, 0.8);
            top: 0;
            left: 0px;
            right: 0px;
            bottom: 0px;
            color: #fff;
            opacity: 0;
            visibility: hidden;
            text-align: center;
            @include vendor(transition, all 0.5s ease);

            .share-inner {
              display: block;
              position: absolute;
              top: 50%;
              margin-top: -35px;
              text-align: center;
              left: 0;
              right: 0;

              label {
                display: block;
                color: #fff;
                @include Bold();
                font-size: 18px;
              }

              a {
                font-size: 14px;
                border-radius: 100%;
                background: #fff;
                text-align: center;
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 52px;
                margin: 0 5px;

                i {
                  color: #fff;
                  font-size: 24px;
                }

                &.facebook {
                  background: #3B5998;
                }

                &.twitter {
                  background: #55ACEE;
                }
              }
            }
          }

          &:hover {
            .share-layer {
              opacity: 1;
              visibility: visible;
            }
          }

          &.locked {
            .share-layer {
              display: none;
            }

            .eligible-layer {
              display: block;
            }
          }
        }
      }
    }
  }
}
