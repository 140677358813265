/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';

//----------------------------------*\
// SCSS MIXINS
//----------------------------------*/
@import './mixins/_alerts';
@import './mixins/_background-variant';
@import './mixins/_border-radius';
@import './mixins/_buttons';
@import './mixins/_center-block';
@import './mixins/_clearfix';
@import './mixins/_forms';
@import './mixins/_gradients';
@import './mixins/_grid';
@import './mixins/_grid-framework';
@import './mixins/_hide-text';
@import './mixins/_image';
@import './mixins/_labels';
@import './mixins/_list-group';
@import './mixins/_nav-divider';
@import './mixins/_nav-vertical-align';
@import './mixins/_opacity';
@import './mixins/_pagination';
@import './mixins/_panels';
@import './mixins/_position';
@import './mixins/_progress-bar';
@import './mixins/_reset-filter';
@import './mixins/_reset-text';
@import './mixins/_resize';
@import './mixins/_responsive-visibility';
@import './mixins/_size';
@import './mixins/_tab-focus';
@import './mixins/_table-row';
@import './mixins/_text-emphasis';
@import './mixins/_text-overflow';
@import './mixins/_vendor';
@import './mixins/_vendor-prefixes';

//----------------------------------*\
// BOOTSTRAP
//----------------------------------*/

// Core variables
@import "./bootstrap/_variables";

// Reset and dependencies
@import './bootstrap/typography';
@import "./bootstrap/normalize";
@import "./bootstrap/print";
@import "./bootstrap/icon";

// Core CSS
@import "./bootstrap/scaffolding";
@import "./bootstrap/type";
@import "./bootstrap/code";
@import "./bootstrap/grid";
@import "./bootstrap/tables";
@import "./bootstrap/forms";
@import "./bootstrap/buttons";

// Components
@import "./bootstrap/component-animations";
@import "./bootstrap/dropdowns";
@import "./bootstrap/button-groups";
@import "./bootstrap/input-groups";
@import "./bootstrap/navs";
@import "./bootstrap/navbar";
@import "./bootstrap/breadcrumbs";
@import "./bootstrap/pagination";
@import "./bootstrap/pager";
@import "./bootstrap/labels";
@import "./bootstrap/badges";
@import "./bootstrap/jumbotron";
@import "./bootstrap/thumbnails";
@import "./bootstrap/alerts";
@import "./bootstrap/progress-bars";
@import "./bootstrap/media";
@import "./bootstrap/list-group";
@import "./bootstrap/panels";
@import "./bootstrap/responsive-embed";
@import "./bootstrap/wells";
@import "./bootstrap/close";

// Components w/ JavaScript
@import "./bootstrap/modals";
@import "./bootstrap/tooltip";
@import "./bootstrap/popovers";

// Utility classes
@import "./bootstrap/utilities";
@import "./bootstrap/responsive-utilities";
@import "./vendor/external.scss";
//----------------------------------*\
// PARTIALS
//----------------------------------*/

@import './partials/_header.scss';
@import './partials/_nav.scss';
@import './partials/_sidebar.scss';
@import './partials/_main.scss';
@import './partials/_footer.scss';
@import './partials/_launchpage.scss';
@import './partials/_popup.scss';
@import './partials/_user-profile.scss';
@import './partials/_ground.scss';
@import './partials/_about-us.scss';
@import './partials/_lobby.scss';
@import './partials/my-league.scss';
@import './partials/_lineup.scss';
@import './partials/_create-league.scss';
@import './partials/_leaderboard.scss';
@import './partials/_achievement-badges.scss';
@import './partials/notification.scss';
@import './partials/mini-statement.scss';
@import './partials/my-account.scss';
@import './partials/_preloader.scss';
@import './partials/edit-profile.scss';
@import './partials/_home.scss';
@import './partials/content-detail-popup.scss';
@import './partials/app-install.scss';
@import './partials/_refer-friend.scss';
@import './partials/_custom';

//----------------------------------*\
// Plugins
//----------------------------------*/
//fullpage
@import './fullpage/jquery.fullPage';

//owl carousal
@import './owlCarousel/owl.carousel';

//Select 2
@import './select2/select2';
@import './select2/select2-bootstrap';

//date-picker
@import './ui-datepicker/date-picker';

@import './partials/match-slider';
//range-slider
@import './range-slider/rzslider.scss';

//------flexslider
@import './flexslider/flexslider.scss';

// import bootstrap 4 
// @import './bootstrap4/mixins/_breakpoints.scss';

html, body { height: 100%; }

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Black.ttf');
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url('../assets/fonts/Poppins-Bold.ttf');
}

body {
    margin: 0;
    font-family: Poppins;
    // overflow: hidden;
    width: initial;
    // background: linear-gradient(#225A6A, #0B3F5A, #0B2346);
    background: #DFDFDF;
}
@font-face {
  font-family: 'myFirstFont';
  src: url('/fonts/CORBEL.TTF');
}
@font-face {
  font-family: 'mySecondFont';
  src: url('/fonts/ARIAL.TTF');
}

.row:before, .row:after {
  display: inline-block !important;
}