.ground-view{
    /*margin-top: 10px;*/
}
@media screen and (min-width: $screen-lg-min) {
    .two-col-layout {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    .col-layout-1,
    .col-layout-2 {
        display: table-cell;
        vertical-align: top;
    }
    .ground-view {
        /*width: 388px;*/
        /*max-width: 388px;*/
        /*padding-left: 20px;*/
        /*margin-top: 0;*/
    }
}

.ground-details-team {
    background: rgba(28, 93, 87, 0.4);
    color: #fff;
    @include clearfix;
    >.block {
        float: left;
        width: 50%;
        padding: 10px;
        line-height: 1;
        &:last-child {
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            height: 45px;
        }
        >span {
            line-height: 20px;
            @include Regular();
            color: #CCDFCC;
            &.team-points {
                font-size: 22px;
                @include Bold();
                color: inherit;
            }
        }
    }
}

.prize-poolblock {
    line-height: 1;
    >span {
        display: block;
    }
    >a {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        line-height: 1;
    }
    >.small {}
}

.panel-default {
    >table {
        >tbody {
            .small {
                font-size: 10px;
                color: #E5E5E5;
                display: block;
            }
        }
    }
}

.user-block {
    @include clearfix;
    .user-thumb,
    .username {
        display: inline-block;
        vertical-align: middle;
    }
    .user-thumb {
        margin-right: 12px;
    }
    h3 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: inherit;
        @include Medium();
        color: #fff;
    }
    .small {
        font-size: 10px;
        color: #868686;
        display: block;
    }
}

.ground-team-status {
    //padding: 15px;
    padding: 9px 11px;
    color: #fff;
    margin-bottom: 40px;
    .selected-team,
    .selected-match-status {
        border-radius: 3px;
        display: inline-block;
        line-height: 1;
    }
    .selected-team {
        min-height: 25px;
        min-width: 78px;
        background-color: rgba(72, 188, 209,.4);
        padding: 7px 16px;
    }
    .selected-match-status {
        border: 1px solid rgba(255, 255, 255, 0.4);
        padding: 6px 8px;
        float: right;
        color: #999;
    }
}
.icn-20{
    font-size: 22px;
    vertical-align: middle;
}
.matchname-with-icon{
    position: relative;
    padding-left: 25px;
    color: inherit;
    display: inline-block;
    i[class^="icon-money-bag"]{
        font-size: 20px;
        position: absolute;
        left: 0;
        top: 0;
    }
    .text-success {
        color: #48bcd1;
    }
    i.icon-m, .icon-t, .icon-g{
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
    }
}
.rankarrow-cell{
    width: 20px;
    >i{
        font-size: 12px;
    }
}
