//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size(
    $padding-base-vertical,
    $padding-base-horizontal,
    $font-size-base,
    $line-height-base,
    $btn-border-radius-base
  );
  @include user-select(none);
  @include OswaldMedium();
  @include vendor(transition, 0.3s all ease);
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      //@include tab-focus;
      outline: none;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    color: #fff !important;
    background: rgb(209, 209, 209); /* Old browsers */
    background: -moz-linear-gradient(
      top,
      rgba(209, 209, 209, 1) 0%,
      rgba(180, 180, 180, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(209, 209, 209, 1) 0%,
      rgba(180, 180, 180, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(209, 209, 209, 1) 0%,
      rgba(180, 180, 180, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#b4b4b4',GradientType=0 ); /* IE6-9 */
  }

  // [converter] extracted a& to a.btn
}

a.btn {
  &.disabled,
  fieldset[disabled] & {
    pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  }
}

html {
  .btn {
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
      cursor: $cursor-disabled;
      color: #fff !important;
      background: rgb(209, 209, 209); /* Old browsers */
      background: -moz-linear-gradient(
        top,
        rgba(209, 209, 209, 1) 0%,
        rgba(180, 180, 180, 1) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(209, 209, 209, 1) 0%,
        rgba(180, 180, 180, 1) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(209, 209, 209, 1) 0%,
        rgba(180, 180, 180, 1) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#b4b4b4',GradientType=0 ); /* IE6-9 */
    }
  }
}

// Alternate buttons
// --------------------------------------------------

.btn-default {
  border: none;
  border-radius: 20px;
  background: #f8f8f8; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #f8f8f8 0%,
    #e9e9e9 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #f8f8f8 0%,
    #e9e9e9 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #f8f8f8 0%,
    #e9e9e9 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#e9e9e9',GradientType=0 ); /* IE6-9 */
  color: #888888;
  @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
  &:hover,
  &:focus {
    color: #888888;
    @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
    background: #e9e9e9; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #e9e9e9 0%,
      #dfdfdf 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #e9e9e9 0%,
      #dfdfdf 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #e9e9e9 0%,
      #dfdfdf 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9e9e9', endColorstr='#dfdfdf',GradientType=0 ); /* IE6-9 */
  }
}
.btn-primary {
  //@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
  border: none;
  background: $after-login-primary-color; /* Old browsers */
  // background: -moz-linear-gradient(
  //   top,
  //   #62c4b3 0%,
  //   #1499bb 100%
  // ); __<<ngThemingMigrationEscapedComment21>>__
  // background: -webkit-linear-gradient(
  //   top,
  //   #62c4b3 0%,
  //   #1499bb 100%
  // ); __<<ngThemingMigrationEscapedComment22>>__
  // background: linear-gradient(
  //   to bottom,
  //   #62c4b3 0%,
  //   #1499bb 100%
  // ); __<<ngThemingMigrationEscapedComment23>>__
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#62c4b3', endColorstr='#1499bb',GradientType=0 ); /* IE6-9 */
  color: $after-login-active-color;
  border: none;
  border-radius: 20px;
  // @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.19));
  // @include vendor(transition, background 0.3s ease);
  &:hover,
  &:focus,
  &:active {
    color: $after-login-active-color;
    // background-color: $after-login-secondary-color;
    // @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.19));
    // background: #48bdd1; __<<ngThemingMigrationEscapedComment25>>__
    // background: -moz-linear-gradient(
    //   top,
    //   #1499bb 0%,
    //   #62c4b3 100%
    // ); __<<ngThemingMigrationEscapedComment26>>__
    // background: -webkit-linear-gradient(
    //   top,
    //   #1499bb 0%,
    //   #62c4b3 100%
    // ); __<<ngThemingMigrationEscapedComment27>>__
    // background: linear-gradient(
    //   to bottom,
    //   #1499bb 0%,
    //   #62c4b3 100%
    // ); __<<ngThemingMigrationEscapedComment28>>__
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1499bb', endColorstr='#62c4b3',GradientType=0 ); __<<ngThemingMigrationEscapedComment29>>__
  }
}

.deposit-btn-hover {
  &:hover,
  &:focus,
  &:active {
    color: $after-login-active-color;
    background-color: $after-login-secondary-color;
  }
}
// Success appears as green
.btn-success {
  //@include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
  background: $after-login-primary-color; /* Old browsers */
  border: none;
  color: $after-login-active-color;
  // @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
  &:hover,
  &:focus,
  &:active {
    color: $after-login-active-color;
    background-color: $after-login-secondary-color;
  }
}
// Info appears as blue-green
.btn-info {
  //@include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
  background: #7cc3e9; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #7cc3e9 0%,
    #41b6cf 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #7cc3e9 0%,
    #41b6cf 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #7cc3e9 0%,
    #41b6cf 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7cc3e9', endColorstr='#41b6cf',GradientType=0 ); /* IE6-9 */
  color: #fff;
  border: none;
  @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
  &:hover,
  &:focus {
    color: #fff;
    @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
    background: #77bbdd; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #77bbdd 0%,
      #3eb0c4 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #77bbdd 0%,
      #3eb0c4 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #77bbdd 0%,
      #3eb0c4 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#77bbdd', endColorstr='#3eb0c4',GradientType=0 ); /* IE6-9 */
  }
}
// Warning appears as orange
.btn-warning {
  //@include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
  background: #ffdd69; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #ffdd69 0%,
    #ffd205 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #ffdd69 0%,
    #ffd205 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffdd69 0%,
    #ffd205 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffdd69', endColorstr='#ffd205',GradientType=0 ); /* IE6-9 */
  border: none;
  color: #333333;
  @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
  &:hover,
  &:focus,
  &:active {
    color: #333333;
    @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
    background: #ffda2a; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #ffda2a 0%,
      #eec302 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #ffda2a 0%,
      #eec302 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #ffda2a 0%,
      #eec302 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffda2a', endColorstr='#eec302',GradientType=0 ); /* IE6-9 */
  }
}
// Danger and error appear as red
.btn-danger {
  //@include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
  background: #48bdd1; /* Old browsers */
  border: none;
  color: $after-login-active-color;
  border-radius: 10px;
  // @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
  &:hover,
  &:focus,
  &:active {
    color: $after-login-active-color;
  }
}

.btn-primary-hover {
  &:hover {
    background-color: $after-login-primary-color;
  }
}

.btn-secondary-hover {
  &:hover {
    background-color: $after-login-secondary-color;
  }
}
// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: $link-color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: none;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size(
    $padding-large-vertical,
    $padding-large-horizontal,
    $font-size-large,
    $line-height-large,
    $btn-border-radius-large
  );
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size(
    $padding-small-vertical + 1,
    $padding-small-horizontal,
    $font-size-small,
    $line-height-small,
    $btn-border-radius-small
  );
}
.btn-xs {
  @include button-size(
    $padding-xs-vertical + 2,
    $padding-xs-horizontal,
    $font-size-small,
    $line-height-small,
    $btn-border-radius-small
  );
}
.fund-btn-padding {
  padding: 3px 12px;
}
.btn-grp-block {
  background: #fff;
  border: 1px solid $brand-primary;
  color: #888888;
  font-size: 12px;
  line-height: 17px;
  &.active {
    color: #fff;
    background: $brand-primary; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #55b8ed 0%,
      #3d96cf 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #55b8ed 0%,
      #3d96cf 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #55b8ed 0%,
      #3d96cf 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55b8ed', endColorstr='#3d96cf',GradientType=0 ); /* IE6-9 */
    box-shadow: none;
  }
}

// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

// Custom button
// --------------------------------------------------
.btn-facebook {
  background: #4267b2; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #4267b2 0%,
    #4267b2 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #4267b2 0%,
    #4267b2 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #4267b2 0%,
    #4267b2 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4267B2', endColorstr='#4267B2', GradientType=0 ); /* IE6-9 */
  border: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #fff;
  // @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
  @include vendor(transition, all 300ms ease-in);
  &:hover,
  &:focus {
    color: #fff;
    box-shadow: none;
    // @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
    background: #4267b2; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #4267b2 0%,
      #4267b2 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #4267b2 0%,
      #4267b2 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #4267b2 0%,
      #4267b2 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4267B2', endColorstr='#4267B2',GradientType=0 ); /* IE6-9 */
  }
  i {
    @include vendor(transform, translateY(2px) scale(1.4));
    margin-right: 5px;
    font-size: 14px;
    width: 20px;
    height: 20px;
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
.facebook_login {
  width: 22px;
}
.google_login {
  width: 22px;
}
.btn-google {
  background: #d1442e; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #d1442e 0%,
    #d1442e 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #d1442e 0%,
    #d1442e 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #d1442e 0%,
    #d1442e 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#D1442E', endColorstr='#D1442E',GradientType=0 ); /* IE6-9 */
  border: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #fff;
  // @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
  @include vendor(transition, all 300ms ease-in);
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    // @include vendor(box-shadow, 0px 2px 1px rgba(0, 0, 0, 0.2));
    background: #d1442e; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #d1442e 0%,
      #d1442e 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #d1442e 0%,
      #d1442e 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #d1442e 0%,
      #d1442e 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#D1442E', endColorstr='#D1442E',GradientType=0 ); /* IE6-9 */
  }
  i {
    margin-right: 5px;
    font-size: 14px;
    @include vendor(transform, translateY(2px) scale(1.4));
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
.btn-icon-square {
  font-size: 22px;
  @include Medium();
  line-height: 20px;
}
.no-btn-input {
  position: relative;
  .on-input-btn {
    @include OswaldMedium();
    position: absolute;
    right: 10px;
    top: calc(($input-height-base - 19px) / 2);
    z-index: 2;
  }
  .form-control {
    padding-right: 85px;
  }
}

.file-btn {
  input {
    display: none;
  }
}

.button-hover-effect {
  &:hover,
  &:focus,
  &:active {
    color: $after-login-active-color;
    background-color: $after-login-secondary-color;
  }
}