.banner-position {
 position: absolute;
 right: 0px;
 bottom: 0px;
 top: 0px;
 left: 0px;
 margin-top: 15%;
 @media screen and (max-width: 600px){
     margin-top: 0%;
 }
 @media screen and (max-width: 767px){
     margin-top: 3%;
 }
 @media screen and (max-width: 991px) {
   padding-left: 12%;
 }
 .download-section{
     margin-top: 2%;
    @media screen and (max-width: 767px) {
    padding-left: 0px;
  }
 }
}
.world-cup-heading {
  @include OswaldLight();
  color: #fff;
  text-transform: uppercase;
  font-size: 5.5vw;
}
.download-button {
  @include OswaldLight;
  color: #fff;
  text-transform: uppercase;
  font-size: 2vw;
  @media screen and (max-width: 767px) {
      font-size: 2.6vw;
    }
}
.app-download-btn {
  margin-left: 20%;
  @media screen and (max-width: 767px) {
    margin-left: 0px;
    margin-right: 26%;
    padding-left: 0px;
    }
}

.androidPB {
  padding-bottom: 5px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    padding-bottom: 10px;
    padding-top: 5px;
    padding-left: 0px;
  }
}
.android-mobileP {
  @media screen and (max-width: 767px) {
    padding-left: 0px;
  }
}


.download-section {
  .download-section-text {
    padding: 10px 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .download-app-heading {
    @include OswaldSemiBold;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 2vw;
    span {
      font-size: 2.5vw;
    }
  }
  .download-app-subheading {
    @include OswaldLight;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5vw;
  }
  .button-demo {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: 30%;
    @media screen and (min-width: 1200px) {
      margin-top: 35%;
    }
  }
}

.title-subheading {
  color: $auth-h3-color;
  margin: 0;
  font-size: 3vw;
  text-shadow: 0 1px 5px #333;
  @include OswaldSemiBold;
  font-style: italic;
}
.title-heading {
  @include OswaldBold;
  font-size: 5vw;
  color: $auth-h1-color;
  margin: 0;
  font-style: italic;
  text-shadow: 0 3px 10px #333;
}
.subheading-liner {
  color: $auth-h4-color;
  font-size: 2vw;
  font-style: italic;
  margin: 0;
  text-shadow: 0 1px 2px #333;
  @include OswaldMedium;
}
.play-btn {
 width: 50%;
 margin-right: 0;

 @media screen and (max-width: 768px) {
   margin-top: 2px;
 }
}

.create-team {
  position: absolute;
  top: 21%;
  right: 0;
  bottom: 0;
  left: 0;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    top: 14%;
  }
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    top: 525px;
  }
  @media screen and (min-width: 1535px) {
    top: 21%;
  }
  @media screen and (min-width: 1918px) {
    top: 24%;
  }
  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    top: 665px;
  }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    top: 760px;
  }
}

.league-btn {
  border: 1px solid $auth-button-border-color;
  background: transparent;
  color: $auth-button-text-color;
  font-size: 18px;
  margin: 20px 0;
  border-radius: 0;

  @media screen and (max-width: 768px) {
    margin-top: 12px;
    font-size: 14px;
  }
  @media screen and (max-width: 576px) {
    margin-top: 12px;
    font-size: 12px;
  }

  @media screen and (max-width: 576px) {

  }
  @media screen and (max-width: 394px) {
      font-size: 8px;
      margin-top: 14px;
  }

}

.league-btn:hover {
  border: 2px solid $auth-button-after-hover-border;
  background: $auth-button-background-color;
  color: $auth-button-after-hover-text-color;
}
.create-team-text {
  position: relative;
  bottom: 160px;

  @media screen and (max-width: 767px) {
    z-index: -1;
  }
  @media screen and (min-width: 321px) and (max-width: 360px) {
    bottom: 120px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    bottom: 150px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    bottom: 160px;
  }
  @media screen and (min-width: 1366px) and (max-width: 1536px) {
    bottom: 200px;
  }
  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    bottom: 190px;
  }
}
.divider {
  margin: 0 auto;
  width: 50%;
  border: 0;
  height: 3px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(103, 221, 233, 1),
    rgba(0, 0, 0, 0)
  );
  clear: both;
  @media screen and (max-width: 767px) {
    height: 1px;
  }
}
.text-placement-new {
  font-size: 32px;
  margin-top: 100px;
  text-align: center;
  color: $auth-play-game-color;

  @media screen and (max-width: 320px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin-top: 30px;
  }
  @media screen and (min-width: 360px) and (max-width: 375px) {
    font-size: 16px;
    margin-top: 12px;
  }
  @media screen and (min-width: 376px) and (max-width: 480px) {
    font-size: 16px;
    margin-top: 10px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    font-size: 26px;
    margin-top: 30px;
  }
}

.banner-player {
  position: absolute;
  top: 245px;
  width: 50%;
  left: 16%;
  animation: playerMove 1.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  visibility: hidden;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    top: 99px;
    width: 50%;
  }
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    top: 112px;
    width: 50%;
  }
  @media screen and (min-width: 1200px) and (max-width: 1536px) {
    top: 198px;
  }
  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    top: 155px;
  }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    top: 181px;
  }
  @media screen and (min-width: 1441px) and (max-width: 1536px) {
    top: 196px;
  }
}
@keyframes playerMove {
  from {
    opacity: 0;
    left: -50%;
  }
  to {
    opacity: 1;
    visibility: visible;
    left: 16%;
  }
}
.winners-section {
  position: absolute;
  left: 0;
  bottom: 300px;

  @media screen and (min-width: 1200px) and (max-width: 1535px) {
    bottom: 0;
    top: 41%;
  }
  @media screen and (min-width: 1536px) and (max-width: 1918px) {
    top: 41%;
  }
  @media screen and (min-width: 1919px) {
    top: 43%;
  }
  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    top: 1620px;
  }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    top: 45%;
  }
}
.text-placement-winners {
  color: $auth-winners-text-color;
  font-size: 46px;
  font-weight: 900;
  text-align: center;
  margin-top: 140px;
  @media screen and (max-width: 768px) {
    color: $auth-winners-text-color-02;
    font-size: 30px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    margin-top: 40px;
  }
  @media screen and (min-width: 361px) and (max-width: 414px) {
    color: $auth-winners-text-color-02;
    margin-top: 40px;
  }
  @media screen and (max-width: 320px) {
    color: $auth-winners-text-color-02;
    margin-top: 0;
  }
  @media screen and (max-width: 375px) {
    color: $auth-winners-text-color-02;
    font-size: 22px;
    margin-top: 45px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    color: $auth-winners-text-color-02;
    font-size: 30px;
    margin-top: 40px;
  }
  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    color: $auth-winners-text-color;
  }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    margin-top: 90px;
  }
}
.text-placement-winners span {
  color: $auth-winners-highlight-text-color;
}
.phone-image {
  position: absolute;
  margin: 0 auto;
  z-index: 2;
  width: 70%;
  bottom: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: 1200px) {
    bottom: -194px;
  }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    bottom: -165px;
  }
}
.new-position {
  position: relative;

  @media screen and (max-width: 320px) {
    bottom: 230px;
  }
  @media screen and (min-width: 321px) and (max-width: 767px) {
    bottom: 110px;
  }
  @media screen and (min-width: 321px) and (max-width: 360px) {
    bottom: 170px;
  }
  @media screen and (min-width: 361px) and (max-width: 375px) {
    bottom: 200px;
  }
  @media screen and (min-width: 376px) and (max-width: 411px) {
    bottom: 205px;
  }
  @media screen and (min-width: 412px) and (max-width: 414px) {
    bottom: 200px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    bottom: 220px;
  }
  @media screen and (min-width: 1200px) {
    bottom: 150px;
  }
  @media screen and (min-width: 1535px) {
    bottom: 210px;
  }
  @media screen and (min-width: 1918px) {
    bottom: 160px;
  }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    bottom: 200px;
  }
  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    bottom: 175px;
  }
}

.new-position-02 {
  @media screen and (max-width: 320px) {
    position: relative;
    bottom: 300px;
  }
  @media screen and (min-width: 321px) and (max-width: 360px) {
    position: relative;
    bottom: 240px;
  }
  @media screen and (min-width: 361px) and (max-width: 767px) {
    position: relative;
    bottom: 160px;
  }
  @media screen and (min-width: 361px) and (max-width: 414px) {
    position: relative;
    bottom: 270px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    position: relative;
    // background-color: #1c615d;
    bottom: 250px;
  }
}

.new-position-03 {
  @media screen and (min-width: 1200px) {
    position: relative;
    bottom: 265px;
  }
  @media screen and (min-width: 1535px) {
    bottom: 335px;
  }
  @media screen and (min-width: 1918px) {
    bottom: 260px;
  }
  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    bottom: 175px;
  }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    bottom: 255px;
  }
}

.link-alignment-gPlay {
  text-align: center;
}
.link-alignment-iStore {
  text-align: center;
}
.sub-heading-position {
  @media screen and (max-width: 375px) {
    position: relative;
    bottom: 11px;
  }
  @media screen and (min-width: 376px) and (max-width: 414px) {
    position: relative;
    bottom: 10px;
  }
  @media screen and (min-width: 415px) and (max-width: 768px) {
    position: relative;
    bottom: 10px;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    position: relative;
    bottom: 10px;
  }
}
.home main .space-create {
  clear: both;
  display: block;
  padding: 0px;
}
.home main .home-section-common {
  padding: 20px;
}
.home main .home-section-common .feature-name {
  color: #333333;
  font-family: "RobotoBold";
  font-size: 22px;
  font-weight: bold;
  line-height: 25px;
  margin-top: 10px;
  text-align: center;
}
.home main .play-anywhere-anytime .feature-name {
  text-align: left;
}
.home main .play-with-friends .feature-name {
  text-align: left;
}
.home main .recent-articles {
  background-color: rgba(218, 218, 218, 0.8);
  margin: 0;
}
.home main .recent-articles .recent-art-head a h4,
.home main .recent-articles .recent-art-head a h2 {
  color: #4a4a4a;
  line-height: 0px;
}
.navbar-brand span {
  color: #1f1f1f;
  font-size: 22px;
  margin-top: 5px;
  text-align: left;
  font-family: "Himalaya";
}
.home .navbar .pull-right {
  margin-top: 17px;
}
.sports-logo {
  z-index: 99999;
}
.navbar-default .navbar-brand {
  color: #000;
  font-size: 12px;
}
.slider-part {
  position: relative;
  .image-content {
    position: relative;
    @media (min-width: 992px) {
      .col-md-offset-5 {
        margin-left: 43.666667%;
      }
    }
    .step-container {
      position: absolute;
      top: 40%;
      right: 22%;
      z-index: 1;
      text-align: right;
    }

    .play-now {
      margin-top: 5px;
      @media screen and (max-width: $screen-md) {
        margin-top: 0px;
      }
    }
  }
}
.new-background-image {
  @media screen and (max-width: 767px) {
    background-color: #1c615d;
  }
}
.new-padding {
  padding-left: 20px;
  @media screen and (max-width: 320px) {
    padding-left: 10px;
  }
  @media screen and (min-width: 321px) and (max-width: 360px) {
    padding-left: 10px;
  }
  @media screen and (min-width: 361px) and (max-width: 767px) {
    padding-left: 10px;
  }
}
.slides-custom {
  margin-top: -80px;
  // background-color: #0c171b;

  @media screen and (max-width: 320px) {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  @media (min-width: 360px) and (max-width: 375px) {
    margin-top: 90px;
  }
  @media (min-width: 376px) and (max-width: 480px) {
    margin-top: 90px;
  }
  @media (min-width: 481px) and (max-width: 991px) {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .slide-container {
    border: 2px #67dde9 solid;
    // padding: 20px;
    position: relative;
    background-color: #0c171b;

    .border-container {
      padding: 10px;
      // position: relative;
      @media screen and (min-width: 1024px) and (max-width: 1200px) {
        padding: 22px;
      }
      .gravatar {
        .profile-image-02 {
          margin-top: -125px;
          margin-right: 140px;
          position: relative;
          height: 100px;
          transform: skewX(10deg);

          @media screen and (max-width: 1024px) {
            transform: skewX(0);
          }
          @media screen and (max-width: 320px) {
            margin-right: 95px;
          }
          @media screen and (min-width: 361px) and (max-width: 375px) {
            margin-right: 150px;
          }
          @media screen and (min-width: 376px) and (max-width: 767px) {
            margin-right: 170px;
          }
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            margin-left: 25px;
          }
        }
      }
      .top-margin {
        margin-top: 50px;
      }
      .inner-line {
        position: absolute;
        border: 2px solid #67dde9;
      }
      .line-left-top {
        left: 0px;
        top: 0px;
        height: 50%;
      }
      .line-top-left {
        top: 0px;
        left: 0px;
        width: 50%;
      }
      .line-right-bottom {
        bottom: 0px;
        right: 0px;
        height: 50%;
      }
      .line-bottom-right {
        bottom: 0px;
        right: 0px;
        width: 50%;
      }
      .cardAlign {
        margin-bottom: 35px;
      }
      .text-placement-05 {
        color: $auth-review-text-color;
        font-size: 14px;
        line-height: 2;
        font-weight: 500;
        text-align: left;
        padding-top: 10px;
        @media screen and (min-width: 768px) {
          font-size: 12px;
          font-weight: 400;
        }
        @media screen and (min-width: 1200px) {
          font-size: 16px;
        }
      }
      .team-name {
        text-align: right;
        color: $auth-review-text-color;
        font-size: 18px;
        font-weight: 700;
        @media screen and (min-width: 768px) {
          font-size: 12px;
        }
      }
      .team-name span {
        color: $auth-review-highlight-text-color;
      }
    }
  }
}
.testimonials-background {
  position: absolute;
  width: 28%;
  right: 0px;
  bottom: 460px;
  @media screen and (min-width: 1535px) {
    position: absolute;
    width: 518px;
    right: 0px;
    bottom: -100px;
  }
  @media screen and (min-width: 1918px) {
    width: 485px;
  }
  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    width: 40%;
    bottom: 0;
    top: -205px;
  }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    width: 35%;
    bottom: -121px;
  }
}
.testimonial-margin {
  position: relative;

  @media screen and (max-width: 320px) {
    bottom: 280px;
  }
  @media screen and (min-width: 321px) and (max-width: 360px) {
    bottom: 240px;
  }
  @media screen and (min-width: 361px) and (max-width: 767px) {
    bottom: 120px;
  }
  @media screen and (min-width: 361px) and (max-width: 375px) {
    bottom: 260px;
  }
  @media screen and (min-width: 376px) and (max-width: 411px) {
    bottom: 250px;
  }
  @media screen and (min-width: 412px) and (max-width: 414px) {
    bottom: 280px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    bottom: 360px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    bottom: 275px;
  }
  @media screen and (min-width: 1535px) {
    bottom: 500px;
  }
  @media screen and (min-width: 1918px) {
    bottom: 370px;
  }
  @media screen and (min-width: 1201px) and (max-width: 1280px) {
    bottom: 240px;
  }
  @media screen and (min-width: 1366px) and (max-width: 1440px) {
    bottom: 240px;
  }
}
.slider-container {
  .slider-part {
    position: absolute;
    z-index: -99;
  }
  .auth-form-part {
    z-index: 9;
    margin-top: 150px;
    @media screen and (max-width: 667px) {
      margin-top: 20px;
    }
    width: 100%;
  }
  position: relative;
}
.home main .upper .left-part .prim-logo {
  margin-top: 0px;
}

.sign-form-part {
  z-index: 9;
  width: 100%;
  margin-bottom: 20px;
}

.home main .upper .right-part {
  float: right;
  display: inline-block;
  vertical-align: top;
  width: 45%;
  position: relative;
  z-index: 100;
  margin-top: -1194px;
  @media screen and (min-width: 1201px) {
    margin-top: -1100px;
  }
  margin-left: 530px;
}

.slider-container {
  .upper {
    margin-top: -100px;
  }
}

.login-form {
  margin-bottom: 0px;
  position: relative;
  float: left;
  margin-right: 21px;
}

.home main .how-to-play .feature-img {
  margin-top: 0;
}
.img-3 {
  position: absolute;
  left: 308px;
  right: 0;
  top: 748px;
  > img {
    max-width: 100%;
    margin: 0 auto;
    display: table;
  }
  @media screen and (max-width: 767px) {
    left: 0;
  }
}

.home main .play-with-friends {
  z-index: 1;
  margin-top: -8%;
  font-size: 20px;
  position: relative;
  @media screen and (max-width: 1024px) {
    margin-top: -28%;
  }
  @media screen and (max-width: 920px) {
    margin-top: -36%;
  }
  @media screen and (max-width: 768px) {
    margin-top: -43%;
  }
  @media screen and (max-width: 667px) {
    margin-top: 67%;
  }
  @media screen and (max-width: 414px) {
    margin-top: 63%;
  }
}
.home main .play-with-friends .feature-img {
  margin-top: 0px;
  @media screen and (max-width: 1201px) {
    margin-top: -146px;
  }
  @media screen and (max-width: 414px) {
    margin-top: 60px;
  }
}

.refer-a-friend {
  margin-top: -24%;
  @media screen and (min-width: 1024px) {
    margin-top: -23%;
  }
  @media screen and (max-width: 920px) {
    margin-top: -23%;
  }
  @media screen and (max-width: 768px) {
    margin-top: -24%;
  }
  @media screen and (max-width: 667px) {
    margin-top: -30%;
  }
  @media screen and (max-width: 414px) {
    margin-top: -52%;
    font-size: 12px;
  }
  @media screen and (max-width: 375px) {
    margin-top: -55%;
    top: 0px !important;
  }
  //left: 14%;
  .refer-a-friend-text {
    margin-top: 5px;
  }
}

.home main .play-anywhere-anytime .feature-img {
  margin-top: 0;
}

.home main .home-testimonials {
  position: relative;
  margin-top: -370px;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
}

.home main .home-testimonials .feature-img {
  color: #307adf;
  margin-top: 0;
}
.fotter-bottom .footer-feature-img {
  margin-top: 0;
  position: absolute;
}

div.fotter-bottom {
  //background-image: url(../../assets/img/how-to-play/strip.jpg);
  background-color: #000;
  background-position: center;
  padding: 10px 0;
}
​ .home main .how-to-play {
  padding-bottom: 40px;
  //margin-top: -451px;
  @media screen and (max-width: 414px) {
    margin-top: 10px;
  }
}

.parent-view {
  height: auto;
}

.deposit-tab-container {
  ul {
    float: left;
    width: 20%;
    @media screen and (max-width: 898px) {
      width: 25%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    /*margin-right :*/
    li {
      color: #fff;
    }
    li.active {
      background: $after-login-secondary-color;
    }
  }
  div.tab-content {
    padding: 0px 50px;
    float: left;
    width: 80%;
    @media screen and (max-width: 898px) {
      width: 75%;
    }
    @media screen and (max-width: 768px) {
      margin-top: 30px;
      width: 90%;
    }
    div.tab-pane {
      padding: 0px;
      .fix-value-container {
        clear: both;
        float: none;
        width: 100%;
        margin: 0 0 45px;
      }
      .btn-primary {
        @include OswaldLight();
        font-size: 20px;
        @media screen and (max-width: 1200px) {
          font-size: 14px;
        }
      }
    }
  }
}
.tab-steps.deposit-tab-container {
  .nav-tabs > li {
    display: table;
    @media screen and (max-width: 768px) {
      display: inline-table;
      width: initial;
    }
    padding: 0px;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      width: 100%;
    }
    border-radius: 20px;
    margin-bottom: 2px;
    margin-right: 2px;
    a {
      padding: 0px;
      display: table;
      padding: 10px;
      width: 100%;
      text-align: left;
      padding-left: 20px;
    }
    img {
      float: left;
      margin-right: 4px;
    }
  }
  .nav-tabs > li > a {
    color: #fff;
    font-size: 16px;
    @media screen and (max-width: 1200px) {
      font-size: 12px;
    }
    line-height: 32px;
    text-align: left;
  }
  .nav-tabs {
    border: none;
  }
  .hideLongText {
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
  .showSmallText {
    display: none;
    @media screen and (max-width: 480px) {
      display: inline;
    }
  }
}

#header-login {
  float: right;
  /*    margin-top: -3px;*/
}

#header-login #user-login-form {
  float: left;
  width: 100%;
  position: relative;
  font-family: "RobotoMedium";
}
#header-login #user-login-form {
  float: left;
  width: 100%;
  position: relative;
  font-family: "RobotoMedium";
}
.mdl-textfield {
  position: relative;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
}
#header-login #user-login-form #user-login-block-form-fields .form-text {
  width: 216px;
  height: 50px;
  background: white;
  outline: none;
  text-indent: 0.5em;
  padding: 11px 4px 0;
  font-size: 16px;
  border: 1px solid #000;
  border-radius: 4px;
  font-family: "RobotoMedium";
  color: #666;
  //background-color: lightgrey;
}
.mdl-textfield__input {
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  display: block;
  font-size: 14px;
  margin: 0;
  padding: 6px 0px 0;
  width: 100%;
  background: none;
  text-align: left;
}
#header-login #user-login-form #user-login-block-form-fields .option {
  font-size: 13px;
  left: 14px;
}
label.option {
  display: inline;
  font-weight: normal;
}
.mdl-textfield__label {
  color: #2cacf3;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: 2px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
#header-login #user-login-form #user-login-block-form-fields .form-submit {
  margin-left: 8px;
  width: 100px;
  height: 38px;
  line-height: 35px;
  float: right;
  padding: 2px 5px;
  border: none;
  background: #55b8ed;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  outline: none;
  margin-right: 0px;
  border-radius: 4px;
  font-family: "RobotoMedium";
}
#header-login #user-login-form #login-links {
  position: absolute;
  top: 9px;
  //right: 143px;
  left: 395px;
  @media screen and (max-width: 768px) {
    left: 375px;
  }
  @media screen and (max-width: 492px) {
    left: 170px;
  }
}
#header-login #user-login-form #login-links .forgot a {
  color: black;
  font-size: 11px;
  //text-transform: uppercase;
  margin-top: 10px;
  @media screen and (max-width: 491px) {
    margin-top: 60px;
  }
  display: inline-block;
  color: #2cacf3;
}
.mdl-textfield__label:after {
  background-color: #3f51b5;
  bottom: 0px;
  content: "";
  height: 14px;
  left: 45%;
  position: absolute;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  width: 10px;
}
.home .navbar .pull-right {
  margin-top: 4px;
}
label.option {
  display: inline;
  font-weight: normal;
}

/*input:focus ~ .mdl-textfield__label ,
input:not(:focus):valid ~ .mdl-textfield__label{
    color: #2cacf3;
    font-size: 10px;
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    visibility: visible !important;

}*/
.login-button {
  float: right;
  margin-top: -9px;
  padding: 9px;
}
.login-button button {
  height: 49px;
  font-size: 16px;
}

.login-btn-sm {
  float: right;
  padding: 9px;
}

.login-btn-sm button {
  height: 35px;
  font-size: 14px;
  width: 80px;
}

.pull-right .help-block {
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: right;
  min-height: 0px;
  float: right;
  width: 100%;
  color: #737373;
}

.form-text.error,
.form-text.ng-touched.ng-invalid {
  border: solid 2px #ec0000 !important;
}
.form-item.has-error,
.form-item.ng-touched.ng-invalid {
  border: 2px solid red !important;
}

//Cheeat
.cheer-leader {
  width: 70%;
  position: absolute;
  left: 135px;
  top: -40px;
}

.cheer-leader-shadow {
  position: absolute;
  left: 115px;
  right: 0;
  top: 764px;
}

.flex-direction-nav a {
  height: 43px !important;
}

@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .home main .upper .left-part {
    min-height: 1143px;
  }
  .img-2 img {
    min-height: 757px;
  }
  .cheer-leader img {
    min-height: 838px;
  }
  .refer-a-friend {
    top: 52%;
  }
}

@media screen and (max-width: 1024px) {
  .home main .upper .left-part {
    min-height: 1184px;
  }
  .img-2 img {
    min-height: 757px;
  }
  .cheer-leader img {
    min-height: 838px;
  }
  .refer-a-friend {
    left: 6% !important;
    //margin-top: -20%;
    font-size: 12px;
  }
  .img-3 {
    position: absolute;
    left: 234px;
    right: 0;
    top: 740px;
  }
  .cheer-leader-shadow {
    position: absolute;
    left: 147px;
    right: 0;
    top: 777px;
  }
}

@media screen and (max-width: 768px) {
  .home main .upper .left-part {
    min-height: none;
    width: 100%;
  }
  .img-3 {
    position: absolute;
    left: 0;
    top: 63%;
    width: 100%;
    margin-left: -75px;
  }
  .cheer-leader {
    width: 93%;
    left: -117px;
  }
  .cheer-leader img {
    width: 100%;
    min-height: 745px;
    max-width: 84%;
    margin-top: 56px;
    margin-left: -100px;
  }
  .cheer-leader-shadow {
    left: -140px;
    top: 63%;
    width: 96%;
  }
}

.static_banner img {
  width: 100%;
  height: auto;
}
ul.static_left_menu {
  float: left;
  list-style: none;
  display: block;
  border-top: 1px solid $panel-default-color;
  padding: 0;
  margin: 0;
  padding-left: 0;
  @media screen and (max-width: 1200px) {
    margin-left: 30px;
  }
  margin-bottom: 30px;
}
.static_left_menu li {
  width: 205px;
  position: relative;
  list-style: none;
}
.static_left_menu a {
  color: $static-page-para-color;
  display: block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  border: 1px solid $panel-default-color;
  border-top: none;
}
.static_left_menu a:hover {
  color: $static-page-highlight;
  background: $table-bg-hover;
  text-decoration: none;
}
.static_left_menu .active a {
  background: $table-bg-active;
  color: $static-page-sub-heading;
  border-right: 2px solid $panel-bottom-border-color;
}
.static_right_content {
  max-width: 700px;
  margin-left: 30px;
  float: left !important;
}
h3 {
  color: $static-page-sub-heading;
}
.static_page_container {
  float: left;
  width: 100%;
}
.static_pages_top_section {
  display: none;
}
.support_facebook {
  width: 42px;
}
.support_instagram {
  width: 42px;
  background-color: white;
}
.footer-circle.icon-google-plus:hover {
  background: #d34836;
  color: #fff;
}

.static-refer-a-friend .col-sm-3 {
  margin-top: 170px;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

.static-join-freeroll .col-sm-3 {
  margin-top: 55px;
}
.static-promotions img {
  width: 100%;
}
.notification-menu::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  border-radius: 4px;
  background: #048579;
  transition: all 0.3s ease;
}
.notification-menu::-webkit-scrollbar:hover {
  background: #e5e5e5;
}
.notification-menu::-webkit-scrollbar-thumb {
  background: #48bcd1;
  border-radius: 2px;
}
.notification-menu li:last-child {
  border-bottom: 0;
  font-size: 14px;
  background: $after-login-secondary-color;
  text-align: center;
  padding: 6px 20px;
  position: fixed;
  top: 432px;
  width: 405px;
  height: 35px;
}

.notification-menu li:last-child a {
  width: 100%;
  color: $dropdown-highlight-text;
}

.requiredField:after {
  content: " *";
  color: red;
}

.add-league-link {
  display: inline;
  float: left;
  .small-btn {
    min-height: 35px;
    margin-top: 13px;
    font-size: 14px;
  }
}
.add-league-link-indv {
  margin-right: 20px;
  display: block;

  @media screen and (max-width: 1200px) {
    margin-top: 12px;
  }
}
.btn-league {
  background: linear-gradient(to bottom, #ff5051 0%, #fa000f 100%);
}
.btn-league:hover,
.btn-league:focus,
.btn-league:active {
  background: linear-gradient(to bottom, #ff0001 0%, #ad0000 100%);
}

.btn-get-app {
  height: 55px;
  width: 191px;
  border: 2px solid #fff;
  background: transparent;
  font-size: 22px;
}
.btn-get-app:hover,
.btn-get-app:focus {
  background: #fff;
  color: #307adf;
}

.make-your-team {
  position: absolute;
  left: 45%;
  width: 100%;
  z-index: 1;
  > ul {
    list-style-type: none;
    font-weight: 600;
    line-height: 28px;
    font-size: 18px;
    text-transform: capitalize;
    > li > i {
      color: #307adf;
    }
  }
}

.dwnld-app {
  position: absolute;
  left: 55%;
  width: 100%;
  z-index: 1;
  color: #fff;
  text-align: center;
  margin-top: 10%;
  @media screen and (max-width: 1390px) {
    margin-top: 0;
  }
  @media screen and (max-width: 1202px) {
    margin-top: -10%;
  }
}

.client-review {
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 22px;
  }
}
.client-sub-review {
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}
.avatar-circle {
  float: left;
  margin: 45px 25px 0 0;
  @media only screen and (max-width: 768px) {
    margin: 10px 0 0 0;
  }
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  border: 1px solid #307adf;
}

.testimonial-text {
  text-align: justify;
  font-size: 25px;
  @media only screen and (max-width: 768px) {
    font-size: 15px;
  }
}

.testimonial-by {
  float: right;
  font-weight: 600;
  text-align: right;
}

.btn-edit-lineup {
  position: relative;
  z-index: 9;
}

ul.unstyled,
ol.unstyled {
  margin-left: 0;
  list-style: none;
}

.social-share {
  z-index: 105;
  position: fixed;
  top: 25%;
  right: 0%;
  color: #fff;
  //width: 30px;
  width: 57px;
  max-width: 57px;
  @media only screen and (max-width: 1280px) {
    max-width: 45px;
  }
  @media only screen and (max-width: 1051px) {
    max-width: 35px;
    top: 32%;
  }
  font-size: 20px;
  //background-color: #fff;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  .app-store {
    //border-right: 1px solid #999;
    //padding-left: 5px;
    //margin-left: 18px;
    //margin-top: 25px;
    //width: 90px;
    /*transform-origin: 0 85%;
            -webkit-transform: rotate(90deg) translate(-50%,50%);
            -moz-transform: rotate(90deg) translate(-50%,50%);
            -o-transform: rotate(90deg) translate(-50%,50%);
            -ms-transform: rotate(90deg) translate(-50%,50%);
            transform: rotate(90deg) translate(-50%,50%);*/
    > a > h5 {
      //margin-top: -12px;
      //font-weight: bold;
    }
  }
  .play-store {
    //border-right: 1px solid #999;
    //padding-left: 5px;
    //width: 90px;
    //margin-left: 18px;
    //margin-top: 65px;
    /*transform-origin: 0 85%;
            -webkit-transform: rotate(90deg) translate(-50%,50%);
            -moz-transform: rotate(90deg) translate(-50%,50%);
            -o-transform: rotate(90deg) translate(-50%,50%);
            -ms-transform: rotate(90deg) translate(-50%,50%);
            transform: rotate(90deg) translate(-50%,50%);*/
    > a > h5 {
      //margin-top: -12px;
      //font-weight: bold;
    }
  }
  .mail-us {
    //padding-left: 5px;
    //margin-top: 40px;
  }
  > li > a {
    color: $gray-base;
    &:hover,
    &:focus {
      color: $navbar-default-link-hover-color;
      background-color: transparent;
      &:before {
        background: $brand-primary;
      }
    }
  }
}

.m-social-share {
  z-index: 105;
  position: fixed;
  bottom: -14px;
  left: 0%;
  width: 100%;
  color: #fff;
  height: 8%;
  list-style: none;
  font-size: 20px;
  background-color: $app-link-background-image;
  margin-bottom: 0;

  @media screen and (min-width: 384px) {
    bottom: -10px;
  }
  .app-base {
    float: left;
    width: 40%;
  }
  .android-base {
    display: inline-block;
    width: 40%;
  }
  .mail-base {
    display: inline-block;
    width: 14.5%;
  }
  > li > a {
    color: $gray-base;
    &:hover,
    &:focus {
      color: $navbar-default-link-hover-color;
      background-color: transparent;
      &:before {
        background: $brand-primary;
      }
    }
  }
}

.form-container.social-login .btn-118 {
  width: 25%;
  margin-left: 0px;
}

.borderRadius {
  border-radius: 20px;
}
.download-section-bg {
  background: url(/../../assets/img/download/android-ios-background.png) no-repeat;
  background-size: cover;
  background-position: center;

}
/*.heading{
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  padding-bottom: 2%;
}*/

.positioning{
  top: 70%;
  left: 25%;
  width: 50%;
  position: absolute;
}

.download-section-text{
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 3%;
  padding-top: 2%;
}
.download-app-heading{
  font-size:2vw;
  font-weight: bolder;
}
.download-app-subheading{
  font-size: 1.8vw;
}
.download-app-subheading{
  font-size: 1.8vw;
}
.img-container{
  position: relative;
}
.kapil-image {
    margin-top: 170px;
    @media screen and (max-width: 768px) {
        margin-top: 20px;
  }
}
