.uib-datepicker-popup.dropdown-menu {
  display: block;
  float: none;
  margin: 0;
  visibility: visible;
  opacity:1;
  li{
	&:first-child{
		padding: 0 5px;
		@media screen and (max-width:320px){
			padding:0;
			outline:none;
		}
	}
	div{
		&:focus, &:hover, &:active{
			outline:none;
			}	
		}
	}
	.uib-datepicker{
		&:focus, &:hover, &:active{
			outline:none;
		}	
	}
}

.uib-button-bar {
  padding: 10px 9px 2px;
}

.uib-day button{
	&.btn-default{
	    color: #666;
		background-color: #fff;
		border-color: #ccc;
		&.active{
			background:$brand-primary;
			color:#fff;
		}
	}
	&.btn-info{
	    color: #fff;
		background-color: #5bc0de;
		border-color: #46b8da;
		&.active{
			color: #fff;
    		background-color: #31b0d5;
    		border-color: #269abc;
		}
	}
}

.uib-datepicker  {
	.uib-title{
	    color: #666;
		background-color: #fff;
		border-color: #ccc;
	}
	button{
		&.btn-default{
		    color: #333;
    		background-color: #fff;
    		border-color: #ccc;
    		&.active{
				background:$brand-primary;
				color:#fff;
				.text-info {
				    color: #fff;
				}
			}
		}
	}
}

//datepicker input
.datepicker {
  background: url(/../../assets/img/date-picker.png);
  background-repeat: no-repeat;
  background-position: 90% center; // fallback
  background-position: calc(100% - 15px) center;

  &.ng-not-empty {
    background: url(/../../assets/img/date-picker.png);
    background-repeat: no-repeat;
    background-position: 90% center; // fallback
    background-position: calc(100% - 15px) center;
  }

  @media screen and (max-width:414px) {
    background-position: calc(100% - 10px) center !important;
  }

  &[disabled] {
    background-color: #eeeeee;
  }
}
.uib-day{
	padding:2px;
}
.uib-day, .uib-datepicker-popup{
	.btn{
		box-shadow:none;
	}
}
.uib-left, .uib-right{
	i.glyphicon{
		font-size:14px;
	}
}
.uib-month, .uib-year{
	padding:2px;
}
.uib-daypicker, .uib-monthpicker,.uib-yearpicker{
	table{
		thead{
			tr{
				th{
					padding:2px;
				}
			}
		}
	}
}
