$match-type-padding: 10px !default;
//***********variable ends***********//

.filter-bar {
    display: table;
    width: 100%;
    margin-bottom: 10px;
    background: rgba(28, 93, 87, 0.3);
    position: relative;
    @include vendor(box-shadow, 0px 1px 0px rgba(0, 0, 0, 0.3));
    @media screen and (max-width: $screen-xs-max) {
        border-radius: 0 0 5px 5px;
    }
    .filter-cell {
        display: table-cell;
        vertical-align: middle;
        border-right: 1px solid $after-login-secondary-color;
        height: 100%;
        &:first-child {
            color: $after-login-active-color;
            .filter-inner {
                @include OswaldMedium();
                padding:12px 10px;
                background: none;
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f4f4f4',GradientType=0 ); /* IE6-9 */
                border-radius:5px 0 0 5px;
                height: 100%;
                > i{
                    &.icon-menu-small{
                        color: $brand-primary;
                        font-size: 19px;
                        display: inline-block;
                        vertical-align: middle;
                        line-height: 14px;
                        & + span {
                            display: inline-block;
                            vertical-align: middle;
                            padding-left: 5px;
                        }
                    }
                }
            }
        }
        &:last-child{
            top: 10px;
            text-align:center;
            font-size: 19px;
            border-right:none;
            @media screen and (max-width:$screen-xs-max) {
                height: 45px;
            }
            a{
                line-height: 10px;
                cursor: pointer;
                display: block;
                width: 19px;
                margin: 0 auto;
            }
        }
        @media screen and (max-width:$screen-xs-max) {
            display:block;
            &:first-child{
                width:100%;
                .filter-inner{
                    border-radius:5px 5px 0 0;
                }
            }
            &:nth-child(4){
                border-bottom:0px;
            }
            &:last-child{
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0 5px 0 0;
                @media screen and (max-width:$screen-xs-max) {
                    border-radius: 0;
                }
                .filter-inner{
                    a{
                        display: block;
                        line-height: 44px;
                    }
                }
            }
        }
        .dropdown{
            > a{
                display:block;
                position:relative;
                // padding: 4px 30px 4px 12px;
                &:after{
                    content: "\e94b";
                    position:absolute;
                    right:-2px;
                    font-size: 12px;
                    top:14px;
                    font-family: 'goldentech' !important;
                }
                .dropdown-heading{
                    @include OswaldMedium();
                    color: $after-login-active-color;
                    font-size:12px;
                    display: block;
                }
                .dropdown-value{
                    @include OswaldLight();
                    color: $after-login-secondary-color;
                }
            }
            &.open {
                > a{
                    background-color: inherit;
                }
            }
            > .filter-dropdown-menu{
                width:100%;
                border-radius:0 0 4px 4px;
                @include vendor(box-shadow, 0px 0px 4px rgba(0,0,0,0.25));
            }
            .dropdown-menu{
                margin-top:0;
            }
        }
        .range-filter{
            padding:10px;
            display: block;
        }
    }
}
.search-game-bar{
    margin-bottom:10px;
    min-height: 44px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: rgba(28, 93, 87, 0.4);
    .turbo-check{
        min-width: 150px;
        margin-top:4px;
        i{
            background: $after-login-active-color;
            border-radius: 100%;
        }
    }
    .form-inline .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }
    .create-a-league {
        margin: 7px -3px 5px 0px;
        .turbo-font {
            color: $after-login-active-color;
            font-weight: bold;
            font-size: 14px;
        }
    }
    .search-game-bar-from{
        @media screen and (min-width: $screen-sm) and (max-width:910px){
            padding-left: 0px !important;
        }
    }
    .create-league-btn{
        @media screen and (max-width:910px){
            padding-left: 0px !important;
            margin-left: -5px;
        }
        .create-a-league-btn {
            border: 2px solid $after-login-secondary-color;
            background: transparent;
            color: $after-login-secondary-color;
            height: 32px;
            transition: all 1s;
            &:hover, &:focus {
                color: $after-login-active-color;
                background: $after-login-secondary-color;
            }
        }
    }
}

.kapil-league {
    border: 1px solid $kapil-league-brand;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 0;
    /*    background: url(../img/kapil/Kapil_League03.png) no-repeat;
        background-color: rgba(28, 93, 87, 0.4);
        position: relative;
        width: 100%;
        padding-bottom: 8px;
        text-align: center;
        height: 223px;*/
    .icon-star-kapil {
        background: url(/../../assets/img/Star.png) no-repeat;
        background-size: contain;
        float: left;
        width: 25px;
        height: 25px;
    }
    .match-type {
        background-color: $kapil-league-brand !important;
        &:hover {
            background: $kapil-league-alt-brand !important;
        }
        .name {
            font-size: 22px;
        }
    }
    .lobby-join-btn {
        border: 2px solid $kapil-league-brand !important;
        &:hover, &:focus {
            background: $kapil-league-brand !important;
        }
    }
    .hide-content {
        display: none;
    }
    .kapil-league-info {
        display: block !important;
        float: right;
        margin-top: -12px;
        font-size: 21px;
        color: #fff;
        right: 22px;
        margin-right: 10px;
        cursor: pointer;
        &:hover {
            color: $brand-primary;
        }
    }
    .winners{
        a{
            color:#fff !important;
            &:hover{
                color: $kapil-league-brand !important;
            }
        }
        .dropdown-menu{
            .ul-heading{
                border-bottom: 1px solid $kapil-league-brand !important;
            }
        }
    }
    ._description {
        color: #fff !important;
    }
    ._terms {
        color: #fff !important;
    }
}

.lobby-data {
    position: relative;
    width: 100%;
    padding-bottom: 6px;
    text-align: center;
    /*height: 163px;*/
    .match-type {
        @include OswaldRegular();
        font-size: 18px;
        min-height: 44px;
        padding-top: 9px;
        font-weight: bold;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        color: $after-login-active-color;
        text-transform: capitalize;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $match-header;
        line-height: 25px;
    }
    .match-type:hover{
        background-color: $match-header-hover;
    }
    .icon-m{
        background: $after-login-primary-color;
        color: $filter-text-color;

        border-radius: 100%;
        overflow: hidden;
        float: right;
        margin-right: 10px;
    }
    .icon-t{
        position: relative;
        color: $icon-t;
        border-radius: 100%;
        overflow: hidden;
        float: right;
        margin-right: 10px;
        &:after{
            content: "";
            display: block;
            background: $icon-t-text;
            width: 13px;
            height: 13px;
            position: absolute;
            top: 2px;
            left: 2px;
            z-index: 0;
            border-radius: 100%;
        }
        &:before{
            position: relative;
            z-index: 1;
        }
    }
    .icon-g{
        background:$after-login-active-color;
        color: $after-login-primary-color;

        border-radius:100%;
        overflow:hidden;
        float: right;
        margin-right: 10px;
    }
    .lobby-data-wrapper {
        @media screen and (max-width:$screen-xs-max) {
            z-index: 1;
        }
    }

    .lobby-data-wrapper > [class*="col-"],
    .lobby-data-wrapper > [class*="col-sm-12"] {
        position: initial;
    }
    .lobby-data-details {
        @include Regular();
        font-size: 14px;
        padding: 20px 0;
        @media screen and (min-width:$screen-sm) and (max-width:$screen-sm-max) {
            padding: 20px 10px;
        }
        .entry-fee {
            float: left;
            min-width: 100px;
            text-align: -webkit-left;
            text-align: left;
        }
        .prize-money{
            float: right;
        }
        .participants {
            float: left;
            text-align: left;
            @media screen and (min-width:$screen-sm-min)  and (max-width:788px){
                font-size : 11px;
            }
        }
        .kapil-league-info {
            display: none;
        }
        .total-winners {
            float: right;
            @media screen and (min-width:$screen-sm) and (max-width:$screen-md-max) {
                /*float: left;*/
                margin-bottom: 5px;
            }
            .winners{
                display:block;
                clear:both;
                line-height: 10px;
                a{
                    cursor: pointer;
                    color:$after-login-secondary-color;
                    font-size:13px;
                    .icon-crown{
                        margin-right: 5px;
                        color: $brand-warning;
                        font-size: 16px;
                    }
                    .icon-shorting-down{
                        line-height:1px;
                        @include vendor(transform, translateY(-2px));
                        display: inline-block;
                        vertical-align: 1px;
                    }
                    &:hover{
                        color:$brand-primary;
                    }
                }
                .dropdown-menu{
                    padding:0;
                    ul{
                        padding:0;
                        margin:0;
                        list-style:none;
                        li{
                            display:table;
                            width:100%;
                            span{
                                display:table-cell;
                                width:50%;
                                vertical-align:middle;
                                padding: 5px 10px;
                                color: $lobby-span-color;
                            }
                        }
                    }
                    .ul-heading{
                        display:table;
                        width:100%;
                        border-bottom: 1px solid $brand-primary;
                        span{
                            display:table-cell;
                            width:50%;
                            vertical-align:middle;
                            padding:5px 10px;
                            color: $after-login-active-color;
                        }
                    }
                }
            }
        }
        ._description {
            color: $after-login-secondary-color;
        }
        ._terms {
            color: $after-login-secondary-color;
        }
    }

    .league-hr {
        border-color: #8080808c;
        margin-top: 38px;
        margin-bottom: 11px;
        @media screen and (min-width:$screen-sm) and (max-width:968px) {
            margin-top: 46px;
            margin-bottom: 5px;
        }
    }
    .btn-primary{
        @media screen and (min-width:$screen-lg){
            min-width:64px;
        }
    }
    .btn {
        padding: 9px 22px;
        font-size: 16px;
        margin-top: 30px;
        @media screen and (min-width:$screen-md-min) and (max-width:$screen-md-max) {
            padding: 9px 17px;
        }
        @media screen and (min-width:$screen-sm) and (max-width:$screen-sm-max) {
            padding: 9px 40px;
            margin-top: 0px;
        }
    }
    .lobby-join-btn {
        border: 2px solid $after-login-secondary-color;
        background: none;
        color: $after-login-active-color;
        line-height: 1.5;
        border-radius: 8px;
        transition: all 1s;
        &:hover, &:focus {
            background: $after-login-secondary-color;
        }
    }
}

.lobby-ground {
    float: right;
    height: 520px;
}

.lobby-table{
    @media screen and (max-width:$screen-xs-max) {
        table-layout:fixed;
    }
    > thead{
        > tr{
            > th{
                &.action-th {
                    width: 64px;
                    max-width: 64px;
                }
            }
        }
    }
    > tbody{
        > tr{
            > td{
                @include Regular();
                color: $filter-text-color;
                .btn-primary{
                    @media screen and (min-width:$screen-lg){
                        min-width:64px;
                    }
                }
                vertical-align: middle;
                .winners{
                    display:block;
                    clear:both;
                    line-height: 10px;
                    a{
                        cursor: pointer;
                        // color:$dropdown-text-hover-color;
                        color: #fff;
                        font-size:12px;
                        .icon-crown{
                            margin-right: 5px;
                            color: $brand-warning;
                            font-size: 16px;
                        }
                        .icon-shorting-down{
                            line-height:1px;
                            @include vendor(transform, translateY(-2px));
                            display: inline-block;
                        }
                        &:hover{
                            color:$brand-primary;
                        }
                    }
                    .dropdown-menu{
                        padding:0;
                        ul{
                            padding:0;
                            margin:0;
                            list-style:none;
                            li{
                                display:table;
                                width:100%;
                                span{
                                    display:table-cell;
                                    width:50%;
                                    vertical-align:middle;
                                    padding:5px 10px;
                                    color:$dropdown-semi-highlight-text;
                                }
                            }
                        }
                        .ul-heading{
                            background:#F4F4F4;
                            display:table;
                            width:100%;
                            border-radius:5px 5px 0 0;
                            span{
                                display:table-cell;
                                width:50%;
                                vertical-align:middle;
                                padding:5px 10px;
                                color: $dropdown-semi-highlight-text;
                            }
                        }
                    }
                }

                &.participant-td{
                    @include Regular();
                    .remaning-progress{
                        .remaning-amount{
                            font-size:14px;
                            color:$balance-text-color;
                            position:relative;
                            z-index: 1;
                            display: block;
                            width: 100%;
                            margin-top: 0;
                            margin-bottom: 1px;
                        }
                        .progress{
                            position:relative;
                            z-index: 1;
                            max-width:85px;
                            height: 2px;
                            border-radius:0;
                            margin: 0;
                            display: inline-block;
                            width: 100%;
                            float: right;
                            .progress-bar{
                                box-shadow:none;
                            }
                        }
                    }
                }
                &.brod-td{
                    @media screen and (max-width:$screen-xs-max) {
                        width:100%;
                    }
                    max-width: 100%;
                    .brod-content{
                        white-space:nowrap;
                        width: inherit;
                        @media screen and (min-width:$screen-sm) {
                            max-width: 300px;
                        }
                        @media screen and (min-width:$screen-md) {
                            max-width: 160px;
                        }
                        @media screen and (min-width:$screen-lg) {
                            max-width: 363px;
                        }
                        .other-info{
                            display:none;
                            position:relative;
                            z-index:1;
                            @media screen and (max-width:$screen-sm-max) {
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 5px;
                            }
                            .btn{
                                &.open{
                                    .caret{
                                        @include vendor(transform, rotateX(180deg));
                                    }
                                }
                            }
                        }
                        .league-detail{
                            display:block;
                            padding-left: 27px;
                            white-space:normal;
                            &.mail-detail{
                                padding:0;
                                @media screen and (max-width:$screen-sm-max) {
                                    margin-left: -24px !important;
                                    padding-left: 21px !important;
                                }
                            }
                            @media screen and (max-width:$screen-sm-max) {
                                display: inline-block;
                                vertical-align: middle;
                                margin-left: -24px !important;
                                padding-left: 51px !important;
                                width:100%;
                            }
                            > i{
                                display:inline-block;
                                vertical-align:middle;
                                font-size:22px;
                                margin-left: -27px;
                            }
                            > span{
                                display:inline-block;
                                vertical-align:middle;
                                width:100%;
                                padding-left:5px;
                                @include Medium();
                                color: $league-span-color;
                                white-space:nowrap;
                                overflow:hidden;
                                text-overflow:ellipsis;
                                > .name{
                                    display:inline-block;
                                    vertical-align:middle;
                                    >small{
                                        display:block;
                                        clear:both;
                                        font-size:12px;
                                        color:#878787;
                                    }
                                }
                                >i {
                                    display:inline-block;
                                    vertical-align:middle;
                                    font-size:16px;
                                }
                            }
                        }
                        .initial-details{
                            display:none;
                            padding:0;
                            margin:0;
                            list-style:none;
                            border: 1px solid #BBBBBB;
                            background-color: $after-login-active-color;
                            margin-top: 10px;
                            margin-bottom: 0;
                            font-size: 12px;
                            li{
                                display: table;
                            }
                            &.open {
                                display: table;
                                width: 100%;
                                float: left;
                            }
                            ._terms, ._description {
                                padding: 4px 8px;
                                display:table-cell;
                                vertical-align: middle;
                            }
                            ._description {
                                color: $balance-text-color;
                                text-align: right;
                                width:100%;

                            }
                            ._terms {
                                color: $balance-text-color;
                                width:90px;
                            }
                        }
                    }
                }
            }
        }
    }
    > tfoot > tr:hover {
        background-color: $table-bg-hover;
    }
}

.progress{
    position:relative;
    z-index: 0;
    height: 3px;
    border-radius:0;
    margin: 13px 0;
    display: inline-block;
    width: 100%;
    .progress-bar{
        box-shadow:none;
    }
}

.country-flag-left{
    display:inline-block;
    vertical-align:middle;
}
.country-name{
    display:inline-block;
    vertical-align:middle;
    margin:0 5px;
    color:#878787;
    b{
        @include Medium();
        text-transform:none;
    }
}
.country-flag-right{
    display:inline-block;
    vertical-align:middle;
}
.table-wrapper {
    border: 1px solid $after-login-secondary-color;
    border-top: none;
    height: auto;
    &.soccer-table{
        min-height: 658px;
    }
    > .table {
        margin-bottom: 0;
    }
}
.input-with-refresh{
    .form-control{
        padding-right: 45px;
    }
    .refresh{
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 1;
    }
}

.span-text {
    color: $lobby-span-color;
}

/* The Modal (background) */
.kapil-modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;

    /* Modal Content (image) */
    .modal-content {
        margin: auto;
        display: block;
        width: 100%;
        max-width: 700px;
        /* Add Animation */
        -webkit-animation-name: zoom;
        -webkit-animation-duration: 0.6s;
        animation-name: zoom;
        animation-duration: 0.6s;
    }
    @-webkit-keyframes zoom {
        from {-webkit-transform:scale(0)} 
        to {-webkit-transform:scale(1)}
    }

    @keyframes zoom {
        from {transform:scale(0)} 
        to {transform:scale(1)}
    }
    .kapil-modal-close {
        position: absolute;
        top: 0px;
        right: 10px;
        color: #000;
        font-size: 40px;
        font-weight: bold;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        z-index: 9;
        &:hover, &:focus {
            color: #bbb;
            text-decoration: none;
            cursor: pointer;
        }
    }
    .kapil-join-now {
        position: absolute;
        left: 6%;
        bottom: 35%;
        /*        height: 46px;
                width: 174px;*/
        height: 12%;
        width: 29%;
        font-size: 20px;
        background-color: #39C2D2;
        border: 2px solid #39C2D2;
        /* Add Animation */
        -webkit-animation-name: zoom;
        -webkit-animation-duration: 0.6s;
        animation-name: zoom;
        animation-duration: 0.6s;
        &:hover {
            background-color: transparent;
            color: #39C2D2;
        }

        @media screen and (min-width: 320px) and (max-width: 375px) {
            font-size: 10px;
        }
        @media screen and (min-width: 376px) and (max-width: 460px) {
            font-size: 14px;
        }
    }
    .tnc {
        position: absolute;
        bottom: 1%;
        left: 3%;
        color: #000;
        font-weight: 600;
        cursor: pointer;
        z-index: 9;
        /* Add Animation */
        -webkit-animation-name: zoom;
        -webkit-animation-duration: 0.6s;
        animation-name: zoom;
        animation-duration: 0.6s;
        >sup {
            color: #ff0000;
        }
    }
}
