.create-league-wrapper{
    padding:20px 40px;
    @media screen and (max-width:480px) {
        padding:15px 0;
    }
    .icon-question{
        font-size: 16px;
        color: #888888;
        @include vendor(transform, translateY(2px));
        display: inline-block;
        margin-left: 3px;
    }
    .alert {
        border-radius: unset;
    }
    .alert-default{
        @include clearfix();
        background-color: rgba(28,93,87,0.6);
        color: #fff;
        .pull-left{
            @include OswaldLight();
            line-height:12px;
            margin-top: 3px;
            i{
                font-size: 16px;
                color: #e5e5e5;
                @include vendor(transform, translateY(2px));
                display: inline-block;
                margin-left: 3px;
            }
        }
        .h3{
            margin:0;
        }
        .text-entry-fee {
            @include OswaldMedium();
            color: #32CD32;
        }
    }
    .info-block{
        @include Regular();
        font-size:12px;
        font-style: italic;
        color:#E5E5E5;
        position: absolute;
    }
    .winning_amount{
        padding-right: 136px;
        & ~ .info-block{
            top:15px;
            right:16px;
        }
    }
    .league_size{
        padding-right: 106px;
        & ~ .info-block{
            top:15px;
            right:16px;
        }
    }
    .ranking-table{
        background-color: transparent;
        min-height: 460px;
        border: 1px solid #48bcd1;
        padding:10px 30px;
        color: #fff;
        @include OswaldRegular();
        @media screen and (max-width:991px) {
            padding:10px;
        }
        .table{
            > thead{
                > tr{
                    background-color: rgba(28, 93, 87, 0.4);
                    > th{
                        @include Regular();
                    }
                }
            }
            >tbody{
                >tr{
                    >td{
                        vertical-align:middle;
                        .input-alt{
                            max-width:104px;
                            background-color: rgba(28, 93, 87, 0.5);
                        }
                        border-color:#48bcd1;
                    }
                    &:last-child{
                        > td{
                            border-bottom:1px solid #48bcd1;
                        }
                    }
                }
            }
        }
    }
    .btn{
        & + .btn{
            @media screen and (max-width:320px) {
                margin-top:10px;
            }
        }
    }
}