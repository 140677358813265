.control-group.warning .select2-container .select2-choice,
.control-group.warning .select2-container .select2-choices,
.control-group.warning .select2-container-active .select2-choice,
.control-group.warning .select2-container-active .select2-choices,
.control-group.warning .select2-dropdown-open.select2-drop-above .select2-choice,
.control-group.warning .select2-dropdown-open.select2-drop-above .select2-choices,
.control-group.warning .select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #C09853 !important;
}

.control-group.warning .select2-container .select2-choice div {
    border-left: 1px solid #C09853 !important;
    background: #FCF8E3 !important;
}

.control-group.error .select2-container .select2-choice,
.control-group.error .select2-container .select2-choices,
.control-group.error .select2-container-active .select2-choice,
.control-group.error .select2-container-active .select2-choices,
.control-group.error .select2-dropdown-open.select2-drop-above .select2-choice,
.control-group.error .select2-dropdown-open.select2-drop-above .select2-choices,
.control-group.error .select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #B94A48 !important;
}

.control-group.error .select2-container .select2-choice div {
    border-left: 1px solid #B94A48 !important;
    background: #F2DEDE !important;
}

.control-group.info .select2-container .select2-choice,
.control-group.info .select2-container .select2-choices,
.control-group.info .select2-container-active .select2-choice,
.control-group.info .select2-container-active .select2-choices,
.control-group.info .select2-dropdown-open.select2-drop-above .select2-choice,
.control-group.info .select2-dropdown-open.select2-drop-above .select2-choices,
.control-group.info .select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #3A87AD !important;
}

.control-group.info .select2-container .select2-choice div {
    border-left: 1px solid #3A87AD !important;
    background: #D9EDF7 !important;
}

.control-group.success .select2-container .select2-choice,
.control-group.success .select2-container .select2-choices,
.control-group.success .select2-container-active .select2-choice,
.control-group.success .select2-container-active .select2-choices,
.control-group.success .select2-dropdown-open.select2-drop-above .select2-choice,
.control-group.success .select2-dropdown-open.select2-drop-above .select2-choices,
.control-group.success .select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #468847 !important;
}

.control-group.success .select2-container .select2-choice div {
    border-left: 1px solid #468847 !important;
    background: #DFF0D8 !important;
}
