.mini-statement {
    @include list-unstyled;
    @include clearfix;
    @include OswaldRegular();
    margin-bottom: 0;
    >li {
        float: left;
        border-left: 1px solid #DDDDDD;
        padding: 8px 10px;
        .amt {
            font-size: 16px;
        }
        &:last-child {
            padding-right: 0;
        }
        .icn-info {
            font-size: 14px;
            cursor: pointer;
        }
        a {
            @include OswaldMedium();
        }
    }
    //my account in panel header
    .panel-heading & {
        float: right;
        @media (max-width: 767px) {
        	float: none; 
            >li {
                float: none;
                border-left: none;
                border-top: 1px solid #DDDDDD;
            }
        }
    }
    .mini-statement-block {
        display: inline-block;
        vertical-align: middle;
        text-transform: capitalize;
        +.btn {
            margin-left: 10px;
        }
    }
}
