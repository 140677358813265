.form-modal{
    @media screen and (min-width:$screen-sm) {
        max-width:556px;
    }
}
.form-container{
    max-width:436px;
    margin-left:auto;
    margin-right:auto;
    /*text-align: center;*/
    @include clearfix();
    form{
        @include clearfix();
    }
    &.mT0{
        margin-top:0;
    }
    .forgot-password{
        position:relative;
        .form-control{
            //padding-right:120px;
        }
        .forgot-password-link{
            // right:0;
            // top:12px;
            // position: absolute;
            // z-index:2;
            float:right;
            color: #e5e5e5;
        }
        .forgot-password-link:hover {
            color: #67dde9;
        }
    }
    .btn-118{
        width:118px;
        @media(max-width:767px){
            width:100%;
            margin-top: 10px;
        }
    }
}
.connect-width{
    float:left;
    @media(max-width:767px){
        width:100%;
        float:left;
        margin-bottom:10px;
    }
}
.social-login{
    @media(max-width:767px){
        text-align:center;

    }
}
.modal-title{
    > span{
        font-size: 14px;
        color:#e5e5e5;
        font-weight: normal;
        &.pull-right{
            text-transform: none;
        }
        @media(max-width:374px){
            float:left;
            width: 100%;
            display: block;
        }
    }
    .sub-heading {
        color: #038579;
    }
}
.new-modal-position {
    padding-left: 25%;
}
//SIGNUP POPUP

.min-charector{
    color: #e5e5e5;
    right:12px;
    top:12px;
    font-size: 12px;
    position: absolute;
    z-index:2;

}
//Fotgot password
.forgot-password-para{
    color: #e5e5e5;
    padding: 20px 14px;
    @media(max-width:767px){
        padding: 20px 0px;
    }
}

// forgot thank you message

.thank-you-email{
    font-size: 94px;
    color: $brand-primary;
}
.forgot-thank-you-para{
    color: #E5E5E5;
    font-size: 16px;
    >a {
        color: #f5f5f5;
        text-decoration: underline;
        font-style: italic;
    }
}
.how-varify-para{
    padding: 7px 0px;
    color: #e5e5e5;
}
.not-enough-cash{
    @include Medium();
    border: 1px solid $panel-default-color;
    background: rgba(72, 188, 209, 0.5);
    padding: 10px;
    line-height: 26px;
    .span-first{
        display: block;
        width: 100%;
        font-size: 14px;
        padding: 5px;
        @include Regular();
        color:#D5D5D5;
        &.pull-left{
            width:auto;
        }
    }
    .span-last{
        @include Medium();
        display: block;
        width: 100%;
        font-size:30px;
        max-width: 215px;
        padding: 5px;
        @extend .nowrap;
        @media(max-width:500px){
            max-width: 154px;
        }
        color:#fff;
    }
    &.active{
        @include Medium();
        border-color:#FE2B32;
        .span-last{
            font-size: 32px;
            color:#FE2B32;
        }
    }
    .label-text{
        font-size: 16px !important;
        margin-top: 2px;
        font-weight: normal !important;
        @include Medium();

    }
    + .help-label {
        min-height: 12px;
        font-size: 12px;
        color: #78B648;
        line-height: 1;
    }
}

.copy-box{
    display: inline-block;
    float: right;
    padding: 0px 23px;
    cursor: pointer;
    background: $after-login-secondary-color;
    height: 50px;
    margin-top: -11px;
    margin-right: 10px;
    margin-right: -10px;
    line-height: 50px;
    @include Medium();
    @include vendor(border-top-right-radius,4px);
    @include vendor(border-bottom-right-radius,4px);
    color:white;
}
.invite-friend{
    .span-last-invite{
        font-size: 18px;
        color:#666666;
    }
    .span-last{
        margin-right: 10px;
        @media(max-width:500px){
            float:left;
            width: 100%;
        }
    }

}
.btn-100{
    width:100%;
    @include Medium();
    font-size: 14px;
    height:48px;
}
.team-box{
    span{
        font-size: 12px;
        color: #e5e5e5;
        margin: 0px 0px 6px 0px;
        @media(max-width:414px){
            margin: 0px 0px 0px 0px
        }
        display: inline-block;
    }
    p{
        color:#000;
        font-size:14px;
    }
    .team-img{
        width:98px;
        height:98px;
        @media(max-width:414px){
            width:50px;
            height:50px;
        }
        overflow: hidden;
        img{
            //max-width: 100%;
            width:auto;
            //height:100%;
            margin: 30% 0;
            @media(max-width:414px){
                margin: 0;
            }
        }
    }
    h2{
        color: #d5d5d5;
        margin: 10px 0px 0px 0px;
        @media(max-width:414px){
            margin: 0px 0px 3px 0px;
            margin-top: -14px;
        }
        span{
            font-size: 22px;
            @media(max-width:414px){
                font-size: 18px;

            }
            color:#e5e5e5;
        }
        font{
            font-size: 18px;
            @media(max-width:414px){
                font-size: 14px;

            }
            margin: 0px 3px 0px 8px;
        }
    }
}

.league-detail-popup{
    .modal-content{
        .modal-header{
            .close{
                position:relative;
                z-index:1;
            }
            .modal-title{
                float: left;
                width: 100%;
                margin-right: -24px;
                padding-right: 30px;
                @media screen and (max-width:767px) {
                    margin-right: 0;
                    padding-right: 0;
                }
                .title-part-text{
                    display:inline-block;
                    vertical-align:middle;
                    width:100%;
                    margin-right: -122px;
                    padding-right: 128px;
                    @media screen and (max-width:767px) {
                        margin-right: 0;
                        padding-right: 0;
                    }
                    .league-name-info{
                        float: left;
                        display:inline-block;
                        vertical-align: top;
                        width:220px;
                        max-width:220px;
                        margin-bottom: 10px;
                        @media screen and (max-width:767px) {
                            margin-bottom:10px;
                        }
                        @media screen and (max-width:550px) {
                            max-width:100%;
                            width:100%;
                        }
                        .league-name{
                            display:inline-block;
                            width:100%;
                            line-height: 18px;
                            vertical-align: top;
                            @include clearfix();
                            .icon-money-bag-alt{
                                display:inline-block;
                                vertical-align: middle;
                                font-size:22px;
                                color: #048579;
                            }
                            > span{
                                display:inline-block;
                                vertical-align: middle;
                                font-size:22px;
                                text-transform:capitalize;
                                color:#333333;
                                width:100%;
                                white-space:nowrap;
                                overflow:hidden;
                                text-overflow: ellipsis;
                                margin-left: -22px;
                                padding-left: 30px;
                                padding: 5px;
                            }
                        }
                        .team-name{
                            padding-left:31px;
                            @include Regular();
                            font-size: 14px;
                            .country-name {
                                color: #FFFFFF;
                            }
                        }
                    }
                    .league-detail-info{
                        display:inline-block;
                        vertical-align: top;
                        @media screen and (max-width:550px) {
                            width:100%;
                        }
                        ul{
                            display:table;
                            width:100%;
                            margin-bottom:0;
                            @media screen and (max-width:767px) {
                                margin-left:-10px;
                                margin-right:-10px;
                                width:auto;
                            }
                            li{
                                display:table-cell;
                                text-align:right;
                                padding:0 10px;
                                @include Regular();
                                text-transform:none;
                                font-size:14px;
                                ._head{
                                    @include clearfix();
                                    color:#fff;
                                    display: block;
                                    line-height: 14px;
                                    white-space: nowrap;
                                }
                                ._value{
                                    @include clearfix();
                                    color:#333;
                                    .remaning-progress .progress {
                                        margin-top:5px;
                                    }
                                }
                            }
                        }
                    }
                }
                .title-part-btn{
                    display:inline-block;
                    vertical-align:middle;
                    @media screen and (max-width:767px) {
                        text-align:center;
                        display:table;
                        margin:10px auto 0;
                        .btn{
                            display:inline-block;
                        }
                    }
                    .btn{
                        min-width:108px;
                        margin:0 auto;
                    }
                }
            }
        }
        .lucky-winner {
            color:#fff;
            padding: 5px 10px 0 5px;
        }
        .price-pool-list{
            .winner-heading{
                color:#fff;
                text-transform: uppercase;
                padding: 0 0 10px 0;
            }
            .price-pool-list-head{
                background: rgba(72, 188, 209, 0.5);
                display:table;
                width:100%;
                border: 1px solid $panel-default-color;
                > span{
                    display:table-cell;
                    width:50%;
                    padding:10px 15px;
                    color: #fff;
                    &:last-child{
                        text-align:right;
                    }
                }
            }
            .list-group{
                margin:0;
                max-height: 257px;
                overflow: auto;
                .list-group-item{
                    background:rgba(72, 188, 209, 0.5);
                    display:table;
                    width:100%;
                    border: 1px solid $panel-default-color;
                    > span{
                        display:table-cell;
                        b{
                            background:$brand-primary;
                            padding:3px 10px;
                            color:#fff;
                            min-width:40px;
                            text-align:center;
                            border-radius:3px;
                            display:inline-block;
                            @include Medium();
                            line-height: 14px;
                        }
                        &:last-child {
                            text-align:right;
                            color: #E5E5E5;
                        }
                    }
                    &:first-child{
                        border-radius:0;
                        border-top:none;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .winner-announcement {
            text-align: center;
            color: #fff;
            padding-bottom: 10px;
            text-decoration: underline;
        }
        .participants-list{
            position:relative;
            @media screen and (max-width:$screen-xs-max) {
                margin-bottom:10px;
            }
            //  	&:after{
            // display:block;
            // height:30px;
            // content:"";
            // width:90%;
            // width:calc(100% - 17px);
            // position:absolute;
            // bottom:0;
            // left:0;
            //    z-index: 1;
            // background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%); __<<ngThemingMigrationEscapedComment1>>__
            // background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); __<<ngThemingMigrationEscapedComment2>>__
            // background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%); __<<ngThemingMigrationEscapedComment3>>__
            // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); __<<ngThemingMigrationEscapedComment4>>__
            //  	}
            .participants-list-head{
                color:#fff;
                text-transform:uppercase;
                padding: 0 0 10px 0;
                border-bottom:1px solid $panel-default-color;
            }
            .list-group{
                overflow: auto;
                max-height: 267px;
                margin-bottom:0;
                .list-group-item{
                    border-radius:0;
                    padding:5px 15px;
                    background: rgba(72, 188, 209, 0.5);
                    border: 1px solid $panel-default-color;
                    border-left:none;
                    border-right:none;
                    &:first-child{
                        border-top:none;
                    }
                    .user-info-attrb{
                        .selected-thumb{
                            display:inline-block;
                            vertical-align:middle;
                        }
                        .user-name-span{
                            display:inline-block;
                            vertical-align:middle;
                            width: 100%;
                            margin-left: -44px;
                            padding-left: 50px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: #E5E5E5;
                        }
                    }
                }
            }
        }
    }
}

.first-thing{
    padding: 0px 20px;
    @media screen and (max-width:767px) {
        padding: 0px;
    }
    @media screen and (max-width:360px) {
        padding: 0px;
        .selectlineuptype{
            .row{
                .col-xs-6{
                    width:100%;
                    &:last-child{
                        margin-top:10px;
                    }
                }
            }
        }
    }
}
.first-thing-first{
    display: table;
    width:100%;
    table-layout: fixed;
    margin-bottom: 30px;

    .table-cell{
        vertical-align: middle;
        display: table-cell;

        &:nth-of-type(1){
            width: 50%;
            @media(max-width:767px){
                width:100%;
            }
        }
        &:nth-of-type(2){
            width: 90px;
            @media(max-width:767px){
                width:100%;
                margin: 16px 0px 43px;
            }
        }
        span{
            display: block;
            font-size:20px;
            color: #E5E5E5;
            &:nth-of-type(1){
                font-size:14px;
                color:#B5B5B5;
            }
        }

        @media(max-width:767px){
            display:block;
            text-align:center;

        }
    }
}
.arrow-right{
    display: inline-block;
    font-style: normal;
    position: relative;
}
.arrow-right-i{
    width: 40px;
    height: 40px;
    border-right: 1px solid #C5C5C5;
    border-top: 1px solid #C5C5C5;
    @include rotate(45deg);
    @media(max-width:767px){
        @include rotate(135deg);
    }
    margin-top: 11px;
    display: inline-block;
    vertical-align: middle;
}
.selectlineuptype{
    .radio-inline{
        width:100%;
    }
}
.invite-friend-sapn{
    color:#878787
}

.font-12{
    color:#e5e5e5;
    font-size:12px;
}
.team-box{
    @include vendor(box-shadow, 0px 0px 4px rgba(0,0,0,0.2));
    padding: 10px;
    text-align: center;
    display: table;
    table-layout: fixed;
    width:100%;

    .table-cell{
        &:first-child{
            width:98px;
            @media(max-width:767px){
                width:50px;
            }
        }
        &:last-child{
            width:98px;
            @media(max-width:767px){
                width:50px;
            }
        }
        display: table-cell;
        vertical-align: middle;
    }
}
.scroll-div{
    max-height: 350px;
    overflow-y:auto;
}
.recent-contact{

    margin: 0px 0px 29px;
    display: table;
    table-layout: fixed;
    width:100%;
    .table-cell{
        display: table-cell;
        vertical-align: middle;
        text-align:  center;
        width:97px;
        display: inline-block;
        @media(max-width:768px){
            width: 50%;
        }
        .contact-outer{
            padding: 0px 12px;
            @media(max-width:767px){
                padding: 0px 18px 14px 0px;

            }

        }
        .circle-box{
            width:48px;
            height: 48px;
            background: #666666;
            vertical-align: middle;
            font-size: 16px;
            color:#FFFFFF;
            line-height: 45px;
            &.selected {
                box-shadow: 0 0 0 2px #3FAFEF;
            }
            @include Medium();
            @include vendor(border-radius,50%);
            &:after {
                content: attr(label-abbr);
                font-size: 18px;
                line-height: 48px;
                color: #fff;
            }
            @media(min-width:767px){
                margin:2px auto 0;
            }

        }
        .contact-email{
            color:#666666;
            font-size: 12px;
            max-width: 200px;
            @extend .nowrap;
            margin-top:8px;
            text-align: left;
        }
    }
}
.recent-contact-countainer{
    padding: 0px 20px;
    @media(max-width:767px){
        padding: 0px 0px;
    }
    .h3{
        font-size: 22px;
        color: #E5E5E5;
    }
}
.top-5{top:-5px;}
.font-size-14{
    font-size:14px;
}
.box-shadow{
    &:after{
        @include vendor(box-shadow, 0px 5px 8px 0px rgba(0, 0, 0, 0.4));
        height: 94px;
        width: 100%;
        content: '';
        display: block;
        position: absolute;
        left: 0px;
        bottom: 148px;
    }
    &:before{

        width: 100%;
        content: '';
        display: block;
        height: 150px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        background: #F4F4F4;

    }
    .pading-right-106{
        padding-right: 106px;
    }

}
.player-detail-popup{
    .modal-content{
        background:#EEEEEE;
        .modal-header{
            background: $match-header;
            .close{
                position: absolute;
                right: 15px;
                z-index:1;
                i{
                    color:#fff;
                }
                &:hover{
                    i{
                        color:#fff;
                    }
                }
            }
            .modal-title{
                color:#fff;
                padding-right: 0;
                @media screen and (max-width:480px) {
                    padding:0;
                }
                .player-detail{
                    .player-img{
                        width:140px;
                        display:inline-block;
                        vertical-align:top;
                        .selected-thumb{
                            margin:-30px auto 0;
                            border:4px solid $panel-bottom-border-color;
                        }
                        @media screen and (max-width:480px) {
                            margin: 0 auto;
                            display: block;
                        }
                    }
                    .player-info{
                        width:100%;
                        display:inline-block;
                        vertical-align:top;
                        margin-left: -148px;
                        padding-left: 155px;
                        @media screen and (max-width:480px) {
                            margin-left: 0;
                            padding-left: 0;
                            margin-top:10px;
                        }
                        .detail-tab{
                            display:block;
                            .name-team{
                                display:block;
                                width:100%;
                                font-size:0;
                                .player-name{
                                    display:inline-block;
                                    vertical-align:middle;
                                    text-transform: capitalize;
                                    font-size:22px;
                                    line-height: 18px;
                                    margin-bottom: 5px;
                                    padding-right: 24px;
                                }
                                .player-flag{
                                    display:inline-block;
                                    vertical-align:middle;
                                    margin-right:10px;
                                    margin-top: -3px;
                                }
                                .player-country{
                                    display:inline-block;
                                    vertical-align:middle;
                                    text-transform: uppercase;
                                    font-size:14px;
                                    margin-bottom: 4px;
                                    line-height: 18px;
                                    margin-top: 1px;
                                }
                                @media screen and (max-width:480px) {
                                    margin: 0 auto;
                                    display: block;
                                    .player-name, .player-country{
                                        text-align:center;
                                        width:100%;
                                        display:block;
                                        padding:0;
                                    }
                                }
                            }
                            .hand-style{
                                display:block;
                                width:100%;
                                @include Regular();
                                font-size:14px;
                                margin-bottom: 5px;
                                text-transform: none;
                                padding-right: 24px;
                                @media screen and (max-width:480px) {
                                    text-align:center;
                                    width:100%;
                                    display:block;
                                    padding:0;
                                }
                            }
                        }
                        .stats-tab{
                            font-size:0;
                            .stats{
                                background:$body-transparent-bg;
                                padding:13px 13px 12px;
                                display:inline-block;
                                vertical-align:middle;
                                width:60%;
                                @media screen and (max-width:767px) {
                                    width:100%;
                                }
                                ul{
                                    display:table;
                                    width:100%;
                                    margin:0;
                                    li{
                                        padding-right:5px;
                                        display:table-cell;
                                        width:33%;
                                        &:last-child{
                                            padding-right:0;
                                        }
                                        span{
                                            font-size:12px;
                                            text-transform: capitalize;
                                            @include Regular();
                                            color:#222;
                                            display:block;
                                            line-height:12px;
                                            &:last-child{
                                                margin-top:5px;
                                                font-size:16px;
                                                @include Bold();
                                                line-height:14px;
                                            }
                                        }
                                    }
                                }
                            }
                            .stats-nav{
                                display:inline-block;
                                vertical-align:middle;
                                width:40%;
                                padding-left:10px;
                                @media screen and (max-width:767px) {
                                    width:100%;
                                    padding-left:0;
                                    margin-top:10px;
                                }
                                .date-block{
                                    background:rgba(28, 93, 87, 0.2);
                                    border-radius:3px;
                                    display:block;
                                    text-align:center;
                                    @include Regular();
                                    font-size:12px;
                                    padding: 9px 10px 7px;
                                    line-height: 10px;
                                    @include clearfix();
                                    text-transform: none;
                                }
                            }
                        }
                    }
                }
            }
            .major-team{
                margin: 20px -15px -15px;
                padding: 0;
                border-top: 1px solid $panel-bottom-border-color;
                .major-team-head{
                    @include OswaldBold();
                    width:116px;
                    text-align:center;
                    display:inline-block;
                    vertical-align:middle;
                    padding:15px;
                    color:#000;
                    @media screen and (max-width:767px) {
                        width:100%;
                        border-bottom:1px solid #ddd;
                    }
                }
                .major-team-list{
                    @include OswaldRegular();
                    display:inline-block;
                    vertical-align:middle;
                    position:relative;
                    width:100%;
                    margin-left: -120px;
                    padding-left: 134px;
                    &:before{
                        background:$panel-bottom-border-color;
                        content:"";
                        display:block;
                        height:100%;
                        width:1px;
                        position:absolute;
                        top:0;left:116px;
                    }
                    ul{
                        margin:0;
                        display: block;
                        width:100%;
                        padding:10px 0;
                        li{
                            position:relative;
                            vertical-align: top;
                            display:inline-block;
                            color:#222;
                            &:after{
                                content:"|";
                                display:block;
                                float:right;
                                margin-left: 9px;
                                color:$panel-bottom-border-color;
                                margin-right: 4px;

                            }
                            &:last-child{
                                &:after{
                                    display:none;
                                }
                            }
                        }
                    }
                    @media screen and (max-width:767px) {
                        margin-left: 0;
                        padding:0 15px;
                        &:before{
                            display:none;
                        }
                    }
                }
            }
        }
        .modal-body{
            >div {
                color: #fff;
            }
            .table-wrapper{
                min-height:auto;
            }
            .h5{
                margin-top:0;
            }
            .player-table{
                margin:0;
                border-top: 1px solid $brand-primary;
                thead{
                    tr{
                        th{
                            @include Regular();
                            padding:10px 5px;
                            &:first-child{
                                padding-left:15px;
                            }
                            &:last-child{
                                padding-right:15px;
                            }
                        }
                    }
                    @media screen and (max-width:767px) {
                        display:none;
                    }
                }
                tbody{
                    tr{
                        td{
                            color:#C5C5C5;
                            b{
                                color:#fff;
                            }
                            .mobile-th{
                                display:none;
                            }
                            @include Regular();
                            @media screen and (max-width:767px) {
                                width:50%;
                                display:inline-block;
                                text-align:left;
                                padding:5px 15px;
                                &:first-child{
                                    width:100%;
                                }
                                .mobile-th{
                                    display:block;
                                    color:#333;
                                    margin: -5px -15px 0;
                                    padding: 1px 15px 2px;
                                    background: #ddd;
                                    line-height:22px;
                                    margin-bottom: 6px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-sub-text {
    color: $popup-sub-text-color;
}
