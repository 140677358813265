.main-nav {
  > li {
    @media screen and (min-width: 1685px) {
      margin-left: 10px;
    }
    > a {
      @media screen and (max-width: $screen-sm-max) {
        padding-left: 15px;
      }
      @media screen and (min-width: $screen-md-min) {
        padding-left: 7px;
      }
      @media screen and (max-width: 1482px) {
        /*padding-left: 7px;*/
        padding-right: 7px;
        font-size: 12px;
      }
      @media screen and (min-width: 1200px) and (max-width: 1400px) {
        padding-left: 7px;
        padding-right: 7px;
        font-size: 12px;
      }
      &,
      &:before {
        @include vendor(transition, 0.3s all ease);
      }
    }
  }
}

.navbar-right {
  .user-option-bar {
    padding: 8px 0 !important;
    border: none !important;
    background: none !important;
    &:before {
      display: none;
    }
    .user-info-attrb {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
      .user-name-span {
        @include Medium();
        font-size: 14px;
        color: $after-login-active-color;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        margin-right: -48px;
        padding-right: 60px;
        min-width: 150px;
        text-align: right;
      }
      [class^="user-section-"] {
        display: inline-block;
        vertical-align: middle;
      }
      .user-balance-span {
        font-size: 14px;
        display: block;
        width: 100%;
        text-align: right;
        color: $after-login-active-color;
      }
    }
  }
  .badge-section {
    padding: 16px 10px !important;
    img {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      display: block;
      /*color: #333;*/
      border: 1px solid #dddddd;
      @include Medium();
      display: inline-block;
      vertical-align: middle;
      min-width: 26px;
      border-radius: 2px;
      text-align: center;
      line-height: 18px;
      margin-left: 3px;
    }
    &:hover,
    &:focus {
      &:before {
        display: none;
      }
    }
  }
  .notification {
    position: relative;
    padding: 18px !important;
    line-height: 12px;
    font-size: 24px;
    margin-left: 0;
    &:hover,
    &:focus {
      &:before {
        display: none;
      }
    }
    span.badge {
      background: $badge-bg;
      color: $badge-color;
      border-radius: 100%;
      @include Medium;
      font-size: 10px;
      min-width: 18px;
      line-height: 18px;
      text-align: center;
      display: block;
      position: absolute;
      top: 9px;
      right: 3px;
      border: 1px solid $badge-bg;
      padding: 0px 3px;
      &:hover,
      &:focus {
        color: $badge-link-hover-color;
      }
    }
  }
  .notification-icon {
    font-size: 24px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .navbar-form {
    position: absolute;
    top: 0;
    right: 54px;
    border: none;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1349px) {
  .navbar-form.navbar-right .input-group .form-control {
    width: 110px;
    padding-left: 2px;
    padding-top: 8px;
    padding-right: 2px;
  }
  .navbar-form {
    width: 162px;
  }
  .navbar-nav > .dropdown > a {
    min-width: 115px;
  }
  .navbar-right .user-option-bar .user-info-attrb .user-name-span {
    min-width: auto;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1242px) {
  .navbar-brand {
    padding-left: 0;
    padding-right: 0;
  }
  .main-nav > li > a {
    padding-left: 5px;
    padding-right: 5px;
  }
  .navbar-right .user-option-bar .user-info-attrb .user-name-span {
    padding-right: 53px;
  }
}
