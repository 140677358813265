.footer-section {
  clear: both;
  border-top: 1px solid #00ffff;
  .row {
    margin-left: 30px;
    margin-right: 30px;
    @media screen and (max-width: $screen-xs-max) {
      margin: 10px;
    }
    @media screen and (min-width: 320px) and (max-width: 667px) {
      margin: 10px 10px 0 10px;
    }
  }
  ul {
    /*margin-bottom: 25px;*/
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
    &.mB10 {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 767px) {
    [class*="col-"] {
      text-align: left;
    }
  }
  //@include vendor(box-shadow, 0px -2px 0px 0px rgba(0, 0, 0, 0.1));
  width: 100%;
  h2 {
    @include OswaldSemiBold;
    color: $footer-heading-color;
    font-size: 24px;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      font-size: 20px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 24px;
    }
  } 

  li {
    list-style: none;
    padding-right: 0px;
    line-height: 2.9em;
    letter-spacing: 2px;
    a {
      @include OswaldLight;
      color: $footer-tab-color;
      line-height: 2;
      display: block;
      font-size: 16px;
      @media screen and (min-width: 320px) and (max-width: 667px) {
        font-size: 10px;
        line-height: 1.7;
      }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        font-size: 12px;
      }
      @media screen and (min-width: 1200px) {
        font-size: 18px;
      }
    }
  }

  p {
    text-align: center;
    font-size: 14px;
  }
  @media screen and (max-width: 320px) {
    p {
      font-size: 10px;
    }
  }
  @media screen and (min-width: 321px) and (max-width: 414px) {
    p {
      font-size: 12px;
    }
  }

  .center-align {
    text-align: center;
  }

  .below-footer {
    padding-bottom: 10px;
    width: 100%;
    overflow: hidden;
    text-align: center;
    font-weight: bold;
    color: $footer-text;
    font-size: 24px;
    text-transform: uppercase;
  }
  @media screen and (max-width: 320px) {
    .below-footer {
      font-size: 8px;
    }
  }
  @media screen and (min-width: 321px) and (max-width: 768px) {
    .below-footer {
      font-size: 12px;
    }
  }

  .below-footer:before {
    content: "";
    display: inline-block;
    width: 50%;
    margin: 0 0.5em 0 -55%;
    vertical-align: middle;
    border-bottom: 1px solid #09171a;
  }
  .below-footer:after {
    content: "";
    display: inline-block;
    width: 50%;
    margin: 0 0.5em 0 -55%;
    vertical-align: middle;
    border-bottom: 1px solid #68dce9;
  }
  .below-footer:after {
    margin: 0 -55% 0 0.5em;
  }

  .fa:hover {
    opacity: 0.7;
  }
  
  .fa-facebook {
  background: #3B5998;
  color: white;
  }
  
  .fa-twitter {
  background: #55ACEE;
  color: white;
  }
}

.footer-text {
  padding-top: 10px;
}
.footer-text-2 {
  margin: 0;
  padding-bottom: 10px;
}

.footer-facebook {
  height: 42px;
  width: 42px;
  background: #3B5998;
  border-radius: 5px;
  font-size: 26px;
  display: inline-block;
  text-align: center;
  color: #fff;  
  line-height: 45px;
  &:hover {
    opacity: 0.7;
  }
}

.footer-twitter {
  height: 42px;
  width: 42px;
  background: #55acee;
  border-radius: 5px;
  font-size: 26px;
  display: inline-block;
  text-align: center;
  color: #fff;  
  line-height: 45px;
  &:hover {
    opacity: 0.7;
  }
}

.parent-footer-bottom {
  background-color: #000;
  padding: 10px 0;
  @media screen and (max-width: $screen-xs-max) {
    [class*="col-"] {
      text-align: center;
    }
  }
  line-height: 26px;
  .copy-right-text {
    color: $copyright-text-color;
    font-size: 14px;

    @media screen and (max-width: 320px) {
      font-size: 12px;
    }
  }
  img {
    max-height: 26px;
  }
}

.fotter-bottom {
  background-color: #000;
  @media screen and (max-width: $screen-xs-max) {
    // margin-bottom: 70px;
    [class*="col-"] {
      text-align: center;
    }
  }
  line-height: 26px;
  .copy-right-text {
    color: #fff;
    font-size: 14px;
  }
  img {
    max-height: 26px;
  }
}

.langauge-drp {
  @media screen and (max-width: 767px) {
    max-width: 150px;
    width: 126px;
  }
  .dropdown-toggle {
    margin-left: 12px;
    max-width: 150px;
    width: 126px;
    text-align: left;
    border: 1px solid #e8e8e8;
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #ffffff 0%,
      #f4f4f4 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #ffffff 0%,
      #f4f4f4 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      #f4f4f4 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f4f4f4',GradientType=0 ); /* IE6-9 */
    float: left;
    padding: 3px 28px 3px 12px;
    color: #307adf;
    &:after {
      content: "\e94b";
      color: #888888;
      position: relative;
      left: 45px;
      font-size: 12px;
      top: 0px;
      font-family: "goldentech" !important;
      @media (min-width: 360px) and (max-width: 375px) {
        position: relative;
        left: 42px;
        top: 1px;
      }
    }
    @media screen and (min-width: 568px) and (max-width: 667px) {
      margin-left: 14px;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 767px) {
      float: left;
      margin-bottom: 20px;
    }
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      margin-left: 0px;
    }
  }
  .dropdown-menu {
    margin-top: 0;
    margin-bottom: 0;
    li {
      a {
        padding: 5px 10px;
        color: #000;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .big {
    display: block;
  }
  .footer-one {
    display: inline-block;
    width: 49%;
  }
  .footer-two {
    display: inline-block;
    width: 49%;
  }
  .footer-three {
    display: inline-block;
  }
  .footer-section li a {
    display: block;
    width: 100%;
  }
  .translator-width {
    width: 100% !important;
  }
  .footer-section .border-header {
    //  margin: 0px 0 23px 0;
    padding: 12px 20px;
  }
  .footer-section h2 {
    font-size: 20px;
  }
}
// @media screen and (max-width: 320px) {
//   .new-position-footer {
//     position: relative;
//     bottom: 20px;
//   }
//   .small-alignment {
//     position: relative;
//     bottom: 22px;
//   }
// }
@media screen and (max-width: 414px) {
  .footer-section h2 {
    font-size: 16px;
  } 
}
@media screen and (min-width: 360px) and (max-width: 375px) {
  .big {
    display: block;
  }
  .footer-one {
    display: inline-block;
    width: 49%;
  }
  .footer-two {
    display: inline-block;
    width: 49%;
  }
  .footer-three {
    display: inline-block;
  }
  .footer-section li a {
    display: block;
    width: 105%;
  }
  .translator-width {
    width: 100% !important;
  }
  .footer-section .border-header {
    //  margin: 0px 0 29px 0;
    padding: 10px 20px;
  }
}

@media screen and (min-width: 376px) and (max-width: 480px) {
  .big {
    display: block;
  }
  .footer-one {
    display: inline-block;
    width: 49%;
  }
  .footer-two {
    display: inline-block;
    width: 49%;
  }
  .footer-three {
    display: inline-block;
  }
  .footer-section li a {
    display: block;
    width: 100%;
  }
  .translator-width {
    width: 100% !important;
  }
  .footer-section .border-header {
    //  margin: 0px 0 29px 0;
    padding: 10px 20px;
  }
}

@media screen and (min-width: 568px) and (max-width: 667px) {
  .big {
    display: block;
  }
  .footer-one {
    display: inline-block;
    width: 49%;
  }
  .footer-two {
    display: inline-block;
    width: 49%;
  }
  .footer-three {
    display: inline-block;
  }
  .footer-section li a {
    display: block;
    width: 78%;
  }
  .translator-width {
    width: 100% !important;
  }
  .footer-section .border-header {
    margin: 0px 0 34px 0;
  }
}